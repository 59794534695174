/*ReactJS*/
import React from 'react';

/*Material UI Components*/
import { Typography, Breadcrumbs, Box } from '@material-ui/core';

/*Styles*/
import useStyles from '../../styles';

/*Table Components*/
import Form1099IntTable from './components/Form1099IntTable';

export default function Form1099Int(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.pageContainer}>
        <Box>
          <Typography
            variant="h2"
            className={classes.textBold}
            align="left"
            gutterBottom={true}
          >
            Form 1099 INT
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <span color="inherit">Tax Filing</span>
            <Typography color="primary">Form 1099 INT</Typography>
          </Breadcrumbs>
        </Box>
        <Box mt={2}>
          <Form1099IntTable params={props} />
        </Box>
      </div>
    </React.Fragment>
  );
}
