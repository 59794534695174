import React from 'react';
import { TextField, FormControl, InputLabel } from '@material-ui/core';
import NumberFormat from 'react-number-format';

const MaskedField = (props) => {
  return (
    <div>
      <NumberFormat
        placeholder={props.placeholder}
        value={props.value}
        onValueChange={(v) => {
          console.log(v);
          props.onChange({
            currentTarget: { name: props.name, value: v.formattedValue },
          });
        }}
        displayType="input"
        type="text"
        format={props.format}
        mask="_"
        fullWidth
        required={props.required}
        name={props.name}
        label={props.label}
        InputLabelProps={{ shrink: true }}
        customInput={TextField}
      />
    </div>
  );
};
export default MaskedField;
