/**
 * @fileoverview gRPC-Web generated client stub for taxfilingpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.taxfilingpb = require('./tax_treaty_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.TaxTreatyServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.TaxTreatyServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.TaxTreaty,
 *   !proto.taxfilingpb.TaxTreatyDetailsResponse>}
 */
const methodDescriptor_TaxTreatyService_CreateTaxTreaty = new grpc.web.MethodDescriptor(
  '/taxfilingpb.TaxTreatyService/CreateTaxTreaty',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.TaxTreaty,
  proto.taxfilingpb.TaxTreatyDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.TaxTreaty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.TaxTreatyDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.TaxTreaty,
 *   !proto.taxfilingpb.TaxTreatyDetailsResponse>}
 */
const methodInfo_TaxTreatyService_CreateTaxTreaty = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.TaxTreatyDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.TaxTreaty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.TaxTreatyDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.TaxTreaty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.TaxTreatyDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.TaxTreatyDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.TaxTreatyServiceClient.prototype.createTaxTreaty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.TaxTreatyService/CreateTaxTreaty',
      request,
      metadata || {},
      methodDescriptor_TaxTreatyService_CreateTaxTreaty,
      callback);
};


/**
 * @param {!proto.taxfilingpb.TaxTreaty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.TaxTreatyDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.TaxTreatyServicePromiseClient.prototype.createTaxTreaty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.TaxTreatyService/CreateTaxTreaty',
      request,
      metadata || {},
      methodDescriptor_TaxTreatyService_CreateTaxTreaty);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.TaxTreaty,
 *   !proto.taxfilingpb.TaxTreatyDetailsResponse>}
 */
const methodDescriptor_TaxTreatyService_UpdateTaxTreaty = new grpc.web.MethodDescriptor(
  '/taxfilingpb.TaxTreatyService/UpdateTaxTreaty',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.TaxTreaty,
  proto.taxfilingpb.TaxTreatyDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.TaxTreaty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.TaxTreatyDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.TaxTreaty,
 *   !proto.taxfilingpb.TaxTreatyDetailsResponse>}
 */
const methodInfo_TaxTreatyService_UpdateTaxTreaty = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.TaxTreatyDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.TaxTreaty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.TaxTreatyDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.TaxTreaty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.TaxTreatyDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.TaxTreatyDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.TaxTreatyServiceClient.prototype.updateTaxTreaty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.TaxTreatyService/UpdateTaxTreaty',
      request,
      metadata || {},
      methodDescriptor_TaxTreatyService_UpdateTaxTreaty,
      callback);
};


/**
 * @param {!proto.taxfilingpb.TaxTreaty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.TaxTreatyDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.TaxTreatyServicePromiseClient.prototype.updateTaxTreaty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.TaxTreatyService/UpdateTaxTreaty',
      request,
      metadata || {},
      methodDescriptor_TaxTreatyService_UpdateTaxTreaty);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.ReadTaxTreatyRequest,
 *   !proto.taxfilingpb.TaxTreatyDetailsResponse>}
 */
const methodDescriptor_TaxTreatyService_ReadTaxTreaty = new grpc.web.MethodDescriptor(
  '/taxfilingpb.TaxTreatyService/ReadTaxTreaty',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.ReadTaxTreatyRequest,
  proto.taxfilingpb.TaxTreatyDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.ReadTaxTreatyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.TaxTreatyDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.ReadTaxTreatyRequest,
 *   !proto.taxfilingpb.TaxTreatyDetailsResponse>}
 */
const methodInfo_TaxTreatyService_ReadTaxTreaty = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.TaxTreatyDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.ReadTaxTreatyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.TaxTreatyDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.ReadTaxTreatyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.TaxTreatyDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.TaxTreatyDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.TaxTreatyServiceClient.prototype.readTaxTreaty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.TaxTreatyService/ReadTaxTreaty',
      request,
      metadata || {},
      methodDescriptor_TaxTreatyService_ReadTaxTreaty,
      callback);
};


/**
 * @param {!proto.taxfilingpb.ReadTaxTreatyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.TaxTreatyDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.TaxTreatyServicePromiseClient.prototype.readTaxTreaty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.TaxTreatyService/ReadTaxTreaty',
      request,
      metadata || {},
      methodDescriptor_TaxTreatyService_ReadTaxTreaty);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.ReadTaxTreatyRequest,
 *   !proto.taxfilingpb.TaxTreatyDetailsResponse>}
 */
const methodDescriptor_TaxTreatyService_DeleteTaxTreaty = new grpc.web.MethodDescriptor(
  '/taxfilingpb.TaxTreatyService/DeleteTaxTreaty',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.ReadTaxTreatyRequest,
  proto.taxfilingpb.TaxTreatyDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.ReadTaxTreatyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.TaxTreatyDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.ReadTaxTreatyRequest,
 *   !proto.taxfilingpb.TaxTreatyDetailsResponse>}
 */
const methodInfo_TaxTreatyService_DeleteTaxTreaty = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.TaxTreatyDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.ReadTaxTreatyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.TaxTreatyDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.ReadTaxTreatyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.TaxTreatyDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.TaxTreatyDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.TaxTreatyServiceClient.prototype.deleteTaxTreaty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.TaxTreatyService/DeleteTaxTreaty',
      request,
      metadata || {},
      methodDescriptor_TaxTreatyService_DeleteTaxTreaty,
      callback);
};


/**
 * @param {!proto.taxfilingpb.ReadTaxTreatyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.TaxTreatyDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.TaxTreatyServicePromiseClient.prototype.deleteTaxTreaty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.TaxTreatyService/DeleteTaxTreaty',
      request,
      metadata || {},
      methodDescriptor_TaxTreatyService_DeleteTaxTreaty);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.TaxTreaty,
 *   !proto.taxfilingpb.ListTaxTreatyResponse>}
 */
const methodDescriptor_TaxTreatyService_ListTaxTreaty = new grpc.web.MethodDescriptor(
  '/taxfilingpb.TaxTreatyService/ListTaxTreaty',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.TaxTreaty,
  proto.taxfilingpb.ListTaxTreatyResponse,
  /**
   * @param {!proto.taxfilingpb.TaxTreaty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListTaxTreatyResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.TaxTreaty,
 *   !proto.taxfilingpb.ListTaxTreatyResponse>}
 */
const methodInfo_TaxTreatyService_ListTaxTreaty = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.ListTaxTreatyResponse,
  /**
   * @param {!proto.taxfilingpb.TaxTreaty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListTaxTreatyResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.TaxTreaty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.ListTaxTreatyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.ListTaxTreatyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.TaxTreatyServiceClient.prototype.listTaxTreaty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.TaxTreatyService/ListTaxTreaty',
      request,
      metadata || {},
      methodDescriptor_TaxTreatyService_ListTaxTreaty,
      callback);
};


/**
 * @param {!proto.taxfilingpb.TaxTreaty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.ListTaxTreatyResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.TaxTreatyServicePromiseClient.prototype.listTaxTreaty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.TaxTreatyService/ListTaxTreaty',
      request,
      metadata || {},
      methodDescriptor_TaxTreatyService_ListTaxTreaty);
};


module.exports = proto.taxfilingpb;

