/*eslint eqeqeq: "off"*/
/*ReactJS*/
import React, { useState } from 'react';
import TaxTreatyModal from './TaxTreatyModal';

import CountriesSelect from '../../../components/AutoComplete/Countries';
/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

import {
  createTaxTreaty,
  updateTaxTreaty,
  deleteTaxTreaty,
  listTaxTreaty,
} from '../../../services/TaxTreatyService';

/*Material UI Components*/
import {
  Button,
  Box,
  TextField,
  IconButton,
  InputLabel,
} from '@material-ui/core';

/*Material UI Icons*/
import {
  Add as AddIcon,
  Create as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import Table, { columnType } from 'components/Table/Table';

/*Styles*/
import useStyles from '../../../styles';

/*Custom components*/
import SearchButton from '../../../components/Button/Search';
import QueryParam from '../../../services/QueryParamService';

import { useConfirm } from 'material-ui-confirm';

export default function TaxTreatyTable({ params }) {
  const classes = useStyles();
  const confirm = useConfirm();
  const options = {
    isRowSelectable: (dataIndex) => isDeleteEnalbed(dataIndex),
    onRowsDelete: (selectedItems) => {
      handleDelete(selectedItems.data);
      return false;
    },
  };

  const columns = [
    {
      name: '',
      type: columnType.buttons,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div
              align={'left'}
              className={classes.grdRow}
              style={{ margin: 0, padding: 0 }}
            >
              <div className={classes.grdCellNone}>
                <IconButton
                  aria-label="delete"
                  //disabled={!isDeleteEnabled(dataIndex)}
                  onClick={() => {
                    handleDelete([
                      {
                        dataIndex: dataIndex,
                      },
                    ]);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              <div align={'left'} className={classes.grdCellNone}>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleOpen(rows[dataIndex])}
                >
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          );
        },
        setCellProps: () => ({
          style: { padding: '4px 16px' },
        }),
      },
    },
    {
      name: 'year',
      label: 'Year',
    },
    {
      name: 'taxCountry',
      label: 'Tax Country',
    },
    {
      name: 'taxRate',
      label: 'Tax Rate',
    },
  ];

  const [rowData, setRowData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [rows, setRows] = useState([]);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      taxCountry: '',
      taxRate: '',
    })
  );

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({
      ...searchData,
      [input.name]: input.value,
    });
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      QueryParam.set(searchData);
      const data = await listTaxTreaty(searchData);

      setRows(data.taxTreatiesList);
      notifyInfo(data.taxTreatiesList.length + ' search results.');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (rowsToDelete) => {
    let messageKey = '';
    if (rowsToDelete.length === 1) {
      messageKey = rows[rowsToDelete[0].dataIndex].taxCountry;
    } else {
      messageKey = rowsToDelete.length + ' items';
    }

    let idsToDelete = [];
    confirm({
      description:
        "You are about to delete '" +
        messageKey +
        "'. Please confirm your action.",
      confirmationText: 'Yes, delete',
    }).then(async () => {
      const rowsCopy = [...rows];
      try {
        for (const r of rowsToDelete) {
          let index = r.dataIndex;
          await deleteTaxTreaty(rows[index].taxTreatyId);
          idsToDelete.push(rows[index].taxTreatyId);
        }
      } catch (error) {
        console.error(error);
      } finally {
        const filtered = rowsCopy.filter(
          (r) => !idsToDelete.includes(r.taxTreatyId)
        );
        setRows(filtered);
        notifySuccess(messageKey + 'Tax treaty has been deleted');
      }
    });
  };

  const isDeleteEnalbed = (dataIndex) => {
    return rows[dataIndex].status !== 'disabled';
  };

  const handleOpen = (data) => {
    if (!data) {
      //ADD default values
      data = {
        year: '',
        taxCountry: '',
        taxRate: '',
      };
    }

    setRowData(data);
    setOpen(true);
  };

  const handleClose = async (data, isEdit) => {
    if (!data) {
      setOpen(false);
      return;
    }

    let valid = true;

    if (!data.year) {
      notifyError('Year is required.');
      valid = false;
    }

    if (!data.taxCountry) {
      notifyError('Tax Country is required.');
      valid = false;
    }

    if (!data.taxRate) {
      notifyError('Tax Rate is required.');
      valid = false;
    }

    if (!valid) {
      return;
    }

    try {
      if (isEdit) {
        //EDIT
        const resp = await updateTaxTreaty(data);
        const rowsCopy = [...rows];
        const index = rows.indexOf(rowData);
        rowsCopy[index] = resp.taxTreaty;
        setRows(rowsCopy);
        notifySuccess('Tax Treaty has been updated.');
      } else {
        //ADD
        const resp = await createTaxTreaty(data);
        setRows([resp.taxTreaty, ...rows]);
        notifySuccess('New Tax Treaty has been added.');
      }

      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="year"
                  label="Year"
                  type="number"
                  inputProps={{
                    min: 2000,
                    max: 3000,
                  }}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={searchData.year}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel id="taxCountry" shrink>
                  Tax Country
                </InputLabel>
                <CountriesSelect
                  labelid="taxCountry"
                  fullWidth
                  required={false}
                  name="taxCountry"
                  value={searchData.taxCountry}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  name="taxRate"
                  label="Tax Rate"
                  type="number"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={searchData.taxRate}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen();
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={handleSearch}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        <Table
          title={'Tax Treaty'}
          data={rows}
          columns={columns}
          options={options}
          additionalCell={true}
        />
      </Box>
      {open && (
        <TaxTreatyModal
          onClose={handleClose}
          open={open}
          value={rowData}
        ></TaxTreatyModal>
      )}
    </div>
  );
}
