import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  TextField,
  Fade,
  IconButton,
  Radio,
  FormControlLabel,
  RadioGroup,
  InputLabel,
} from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { validatePassword } from '../../lib/validate/validate';
import PasswordField from '../../components/TextField/PasswordField';
import CodeField from '../../components/TextField/CodeField';
import authSvc from '../../services/AuthService';
import QueryParam from '../../services/QueryParamService';

import Logo from '../../images/Sas-logo.png';
import googleGraphics from '../../images/taxsample.jpg';
import useStyles from './styles';

function Login(props) {
  const classes = useStyles();

  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isAuthentication, setIsAuthentication] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [startTimer, setStartTimer] = React.useState(false);
  const [loading, setLoading] = React.useState({ timer: false });
  const [data, setData] = useState({
    email: '',
    password: '',
    usrId: '',
    code: '',
    mode: '',
    authenticationMode: 'Email',
    key: '',
    authentications: 'Email, Text, Authenticator',
  });

  useEffect(() => {
    if (window.env.ACTIVATE_SINGLE_SIGNIN) {
      const encryptedKey = window.location.href.split('?')[1];

      if (['', undefined, null].includes(encryptedKey)) {
        const loginUrl = window.env.COMMON_LOGIN_URL + '/login?';

        const urlParams =
          'app_id=' +
          window.env.APP_ID +
          '&app_secret=' +
          window.env.APP_SECRET +
          '&redirect_url=' +
          window.env.REDIRECT_URL;

        const url = loginUrl + urlParams;

        window.location.replace(url);
      } else {
        var params = QueryParam.getDecryptedUrlParameter(
          {
            usr_id: '',
            email: '',
            access_token: '',
          },
          encryptedKey,
          'SAS220304-QJRT'
        );

        const loginViaToken = async () => {
          try {
            const loginParams = {
              ...params,
              mode: 'Log In Via Token',
            };

            const res = await authSvc.login(loginParams);

            if (res !== 0) {
              window.location = '/';
            }
          } catch (error) {
            console.log(error.message);
          }
        };

        if (params.access_token) {
          localStorage.setItem('access_token', params.access_token);
          loginViaToken();
        }
      }
    }
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    const copy = { ...data };
    copy[input.name] = input.value;
    setData(copy);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data.authenticationMode == '') {
      e.preventDefault();
      setErrorMsg('Please select authentication mode');
      return;
    }

    if (data.authenticationMode === 'Authenticator') {
      try {
        const auth = await authSvc.getAuthMode(data);
        if (
          auth.authenticationMode.includes(data.authenticationMode) === false
        ) {
          setErrorMsg('Not Allowed. Please use other authentication mode');
          setLoading({ ...loading, login: false });
          e.preventDefault();
          return;
        }
      } catch (error) {
        setErrorMsg(error.message);
        setLoading({ ...loading, login: false });
        e.preventDefault();
        return;
      }
    }

    try {
      e.preventDefault();
      setErrorMsg('');

      data.mode = 'Log In';
      data.usrId = await authSvc.login(data);

      if (data.authenticationMode === 'Authenticator') {
        const key = await authSvc.getKey(data);
        if (key.secretKey == '') {
          setErrorMsg(
            '2FA have not been set-up. please choose a different authentication method'
          );
          setLoading({ ...loading, login: false });
          e.preventDefault();
          return;
        }
        data.key = key.secretKey;
      }

      setIsLogin(false);
      setIsAuthentication(true);
    } catch (error) {
      if (error.message) {
        setErrorMsg(error.message);
      }
      console.error(error);
    }
  };

  const handleValidateCode = async (e) => {
    if (data.code === '') {
      setErrorMsg('Authentication Code is required.');
      return;
    }

    try {
      e.preventDefault();
      setErrorMsg('');
      if (data.authenticationMode == 'Authenticator') {
        const key = await authSvc.validateAuthenticatorCode(data);
        if (key != 'Success') {
          setLoading({ ...loading, sendCode: false });
          setErrorMsg(key);
          return;
        }
      } else {
        const res = await authSvc.validateAuthCode(data);

        if (res.status !== 'Success') {
          setErrorMsg(res.msg);

          if (res.msg === 'Code expired') {
            // return screen to email and password input
            setIsAuthentication(false);
            setIsLogin(true);

            // remove values of email and password fields
            const copy = {
              email: '',
              password: '',
              usrId: '',
              code: '',
              lastPassword: '',
              newPassword: '',
              confirmPassword: '',
              authenticationMode: 'Email',
              key: '',
              authentications: 'Email, Text',
            };
            setData(copy);
          }

          return;
        }
      }
      window.location = '/';
    } catch (error) {
      if (error.message) {
        setErrorMsg(error.message);
      } else {
        setErrorMsg(error);
      }
    }
  };

  const handleForgotPassword = async (e) => {
    // Get email for display
    const copyData = { ...data, email: data.email };
    setData(copyData);

    // Show Forgot Password View
    setIsLogin(false);
    setIsForgotPassword(true);
    setIsCodeSent(false);
    setIsPasswordChanged(false);
  };

  const validate = () => {
    return false;
  };

  const handleSendCode = async (e) => {
    // Check if email is not empty
    if (data.email === '') {
      setErrorMsg('Username is required before sending code');
      return;
    }

    // Send Forget Password Code
    try {
      e.preventDefault();
      setErrorMsg('');
      setStartTimer(false);

      data.mode = 'Forgot Password';
      const userId = await authSvc.login(data);

      setData({ ...data, usrId: userId });

      setLoading({ ...loading, timer: true });
      setStartTimer(true);
    } catch (error) {
      if (error.message) {
        setErrorMsg(error.message);
        return;
      }
    }

    // Enable code field
    // Disable "Send Code" button
    setIsCodeSent(true);
  };

  const handleChangePassword = async (e) => {
    try {
      e.preventDefault();
      setErrorMsg('');

      // Check if authentication code is provided
      if (!data.code || data.code === '' || !isCodeSent) {
        setErrorMsg('Authentication Code is required.');
        return;
      }

      if (validatePassword(data.newPassword).err) {
        setErrorMsg('New Password does not meet the requirement.');
        return;
      }

      if (validatePassword(data.confirmPassword).err) {
        setErrorMsg('Confirm Password does not meet the requirement.');
        return;
      }

      // Check if confirm password is correct
      if (data.confirmPassword !== data.newPassword) {
        setErrorMsg('Confirm Password is incorrect.');
        return;
      }

      // Validate Code
      var res = await authSvc.validateAuthCode(data);

      // Check if code validation is successful
      if (res.status !== 'Success') {
        setErrorMsg(res.msg);

        // Check if code expired
        // Re-enable send code button
        if (res.msg === 'Code expired') {
          setIsCodeSent(false);
        }

        return;
      }

      // Change Password
      res = await authSvc.changePassword(data);

      //Delete Access Token
      authSvc.logout();

      // Check if change password is successful
      if (res.status !== 'Success') {
        setErrorMsg(res.msg);
        return;
      } else {
        // Disable Submit Button
        setErrorMsg('');
        setSuccessMsg(res.msg);
        setStartTimer(false);
        setIsPasswordChanged(true);
      }
    } catch (error) {
      if (error.message) {
        setErrorMsg(error.message);
      }
    }
  };

  const handleBackToLogin = async (e) => {
    // Get email for display
    //const copyData = { email: data.email, authenticationMode: 'Email' };
    const copyData = {
      email: data.email,
      authenticationMode: 'Email',
      authentications: data.authentications,
    };
    setData(copyData);

    // Remove Error / Success Msg
    setErrorMsg('');
    setSuccessMsg('');

    //Reset timer
    setStartTimer(false);
    setLoading({ ...loading, timer: false });

    // Delete Access token
    authSvc.logout();

    // Show Log in View
    setIsForgotPassword(false);
    setIsAuthentication(false);
    setIsLogin(true);
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img
          alt="sign-in-image"
          className={classes.signinVecotr}
          src={googleGraphics}
        ></img>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <Grid container>
              <Grid item>
                <img
                  style={{
                    width: 85,
                    height: 'auto',
                    display: 'block',
                    marginBottom: 55,
                  }}
                  alt="sas"
                  src={Logo}
                ></img>
              </Grid>
            </Grid>
            {isAuthentication && (
              <form onSubmit={handleValidateCode}>
                <div>
                  <div className={classes.grdRow}>
                    <div className={classes.grdCell}>
                      <Typography variant="h4" className={classes.header4}>
                        Authentication
                      </Typography>
                    </div>
                    <div className={classes.grdCellNone}>
                      <IconButton
                        color="secondary"
                        className={classes.backButton}
                        aria-label="back"
                        onClick={handleBackToLogin}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </div>
                  </div>
                  <div>
                    <CodeField
                      autoFocus={true}
                      name="code"
                      label="Authentication Code"
                      type="text"
                      max={6}
                      startTimer={data.authenticationMode !== 'Authenticator'}
                      setLoading={setLoading}
                      value={data.code ? data.code : ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            )}
            {isLogin && (
              <form onSubmit={handleSubmit}>
                <Typography variant="h4" className={classes.header4}>
                  Login Account
                </Typography>
                <div>
                  <div>
                    <TextField
                      fullWidth
                      name="email"
                      label="Email"
                      value={data.email ? data.email : ''}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <PasswordField
                      name="password"
                      label="Password"
                      value={data.password ? data.password : ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={classes.grdRow} style={{ marginTop: 10 }}>
                    <div className={classes.grdCell}>
                      <InputLabel style={{ fontSize: 14 }}>
                        Send code authentication via:
                      </InputLabel>
                    </div>
                    <div className={classes.grdCellNone}>
                      <Link
                        className={classes.forgotPassword}
                        to="#"
                        onClick={handleForgotPassword}
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <div className={classes.grdRow}>
                    <div className={classes.grdCell}>
                      <RadioGroup
                        style={{ flexDirection: 'row' }}
                        name="authenticationMode"
                        value={data.authenticationMode}
                        onChange={handleChange}
                      >
                        {data.authentications.includes('Text') && (
                          <FormControlLabel
                            name="authenticationMode"
                            label={
                              <Typography style={{ fontSize: 12 }}>
                                Text
                              </Typography>
                            }
                            value="Text"
                            control={<Radio />}
                          />
                        )}
                        {(data.authentications.includes('Email') ||
                          data.authentications == '') && (
                          <FormControlLabel
                            name="authenticationMode"
                            label={
                              <Typography style={{ fontSize: 12 }}>
                                Email
                              </Typography>
                            }
                            value="Email"
                            control={<Radio />}
                          />
                        )}
                        {(data.authentications.includes('Authenticator') ||
                          data.authentications == '') && (
                          <FormControlLabel
                            name="authenticationMode"
                            label={
                              <Typography style={{ fontSize: 12 }}>
                                Authenticator
                              </Typography>
                            }
                            value="Authenticator"
                            control={<Radio />}
                          />
                        )}
                      </RadioGroup>
                    </div>
                  </div>
                  <div className={classes.grdCell1} style={{ marginTop: 25 }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      disabled={validate()}
                      type="submit"
                    >
                      Login
                    </Button>
                  </div>
                </div>
              </form>
            )}
            {isForgotPassword && (
              <form onSubmit={handleChangePassword}>
                <div>
                  <div className={classes.grdRow}>
                    <div className={classes.grdCell}>
                      <Typography variant="h4" className={classes.header4}>
                        Forgot Password
                      </Typography>
                    </div>
                    <div className={classes.grdCellNone}>
                      <IconButton
                        color="secondary"
                        className={classes.backButton}
                        aria-label="back"
                        onClick={handleBackToLogin}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </div>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <TextField
                      fullWidth
                      required
                      name="email"
                      label="Email"
                      value={data.email ? data.email : ''}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <PasswordField
                      required
                      showPasswordRequirement={true}
                      name="newPassword"
                      label="New Password"
                      value={data.newPassword ? data.newPassword : ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <PasswordField
                      required
                      showPasswordRequirement={true}
                      name="confirmPassword"
                      label="Confirm New Password"
                      value={data.confirmPassword ? data.confirmPassword : ''}
                      onChange={handleChange}
                    />
                  </div>
                  <CodeField
                    mt={true}
                    max={36}
                    startTimer={startTimer}
                    setLoading={setLoading}
                    required
                    name="code"
                    label="Authentication Code"
                    type="text"
                    value={data.code ? data.code : ''}
                    onChange={handleChange}
                  />
                  <div className={classes.grdRow} style={{ marginTop: 40 }}>
                    <div
                      className={classes.grdCellNone}
                      style={{ marginRight: 10 }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleSendCode}
                        disabled={loading.timer}
                      >
                        Send Code
                      </Button>
                    </div>
                    <div className={classes.grdCell}>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        type="submit"
                        disabled={isPasswordChanged}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            )}
            <Fade in={errorMsg !== ''} style={{ marginTop: 20 }}>
              <Typography color="error" className={classes.errorMessage}>
                {errorMsg}
              </Typography>
            </Fade>
            <Fade in={successMsg !== ''} style={{ marginTop: 20 }}>
              <Typography
                style={{ color: 'green' }}
                className={classes.errorMessage}
              >
                <b>{successMsg}</b>
              </Typography>
            </Fade>
          </React.Fragment>
        </div>
        <Typography color="primary" className={classes.copyright}>
          <b>Powered By</b>: Software Algo Solutions
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
