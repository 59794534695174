/*eslint eqeqeq: "off"*/
import {
  AccountServiceClient,
  Account,
  ReadAccountRequest,
  DeleteAccountRequest,
} from '../proto/taxfilibpb/account_grpc_web_pb';
import { notifyError } from 'components/Notification/Notification';
import { auth } from '../lib/auth/Auth';
import { legalEntities } from '../components/AutoComplete/AutoCompleteData';

const service = new AccountServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

const requestAccountParameter = (param, search) => {
  if (!search) {
    const le = legalEntities.find((item) => item.codeDescription === param.legalEntity);

    try {
      if (le.note === 'SSN') {
        param.accountName = param.fullName;
      } else {
        param.accountName = param.companyName;
      }
      param.governmentIdType = le.note;
    } catch (error) {
      notifyError(error)
    }
  }

  let req = new Account();
  req.setAccountId(param.accountId);
  req.setCorrespondent(param.correspondent);
  req.setOffice(param.office);
  req.setAccountNo(param.accountNo);
  req.setAddress1(param.address1);
  req.setAddress2(param.address2);
  req.setAddress3(param.address3);
  req.setCity(param.city);
  req.setState(param.state);
  req.setCountry(param.country);
  req.setTaxCountry(param.taxCountry);
  req.setGovernmentId(param.governmentId);
  req.setLegalEntity(param.legalEntity);
  req.setYear(param.year);
  req.setExternalId(param.externalId);
  req.setW8w9(param.w8w9);
  req.setAccountName(param.accountName);
  req.setCompanyName(param.companyName);
  req.setFullName(param.fullName);
  req.setGovernmentIdType(param.governmentIdType);
  req.setZip(param.zip);
  req.setCalcType(param.calcType);
  return req;
};

// ,[year]
// ,[external_id]
// ,[w8/w9]
// ,[account_name]
// ,[company_name]
// ,[full_name]
// ,[government_id_type]

export async function createAccount(param) {
  return new Promise((resolve, reject) => {
    const req = requestAccountParameter(param, false);
    service.createAccount(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateAccount(param) {
  return new Promise((resolve, reject) => {
    const req = requestAccountParameter(param, false);
    service.updateAccount(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function readAccount(param) {
  return new Promise((resolve, reject) => {
    const req = new ReadAccountRequest();
    req.setAccountId(param.accountId);

    service.readAccount(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteAccount(param) {
  return new Promise((resolve, reject) => {
    const req = new DeleteAccountRequest();
    req.setAccountId(param.accountId);

    service.deleteAccount(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listAccount(param) {
  return new Promise((resolve, reject) => {
    const req = requestAccountParameter(param, true);
    service.listAccount(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function importAccount(param) {
  return new Promise((resolve, reject) => {
    let req = new Account();
    req.setCorrespondent(param.correspondent);
    req.setOffice(param.office);
    req.setAccountNo(param.accountNo);
    req.setAddress1(param.address1);
    req.setAddress2(param.address2);
    req.setAddress3(param.address3);
    req.setCity(param.city);
    req.setState(param.state);
    req.setCountry(param.country);
    req.setTaxCountry(param.taxCountry);
    req.setGovernmentId(param.governmentId);
    req.setLegalEntity(param.legalEntity);
    req.setYear(param.year);
    req.setExternalId(param.externalId);
    req.setW8w9(param.w8w9);
    req.setCompanyName(param.companyName);
    req.setFullName(param.fullName);
    req.setZip(param.zip);
    req.setCalcType(param.calcType);
    req.setCountryCode(param.countryCode);
    service.importAccount(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export const convertDataToAccountModel = (d) => {
  return {
    accountId: d.AccountId,
    externalId: GetValueByOjbectName('ID', d),
    correspondent: d.Correspondent,
    office: GetValueByOjbectName('Office', d),
    accountNo: d.AccountNo,
    address1: d.Address1,
    address2: GetValueByOjbectName('Address2', d),
    address3: GetValueByOjbectName('Address3', d),
    city: d.City,
    state: d.State,
    country: GetValueByOjbectName('Country', d),
    taxCountry: d.TaxCountry,
    governmentId: GetValueByOjbectName('GovernmentID', d),
    legalEntity: d.LegalEntity,
    year: GetValueByOjbectName('Year', d),
    w8w9: GetValueByOjbectName('W8W9', d),
    companyName: GetValueByOjbectName('CompanyName', d),
    fullName: GetValueByOjbectName('FullName', d),
    zip: GetValueByOjbectName('Zip', d),
    calcType: GetValueByOjbectName('CalcType', d),
    countryCode: GetValueByOjbectName('CountryCode', d),
  };
};

const GetValueByOjbectName = (name, d) => {
  if (d.hasOwnProperty(name)) {
    return d[name];
  }
  return '';
};

export const isValidData = (d) => {
  let error = '';
  if (!d.hasOwnProperty('Correspondent')) {
    error = error.concat('Correspondent field missing ');
  } else {
    if (d.Correspondent.length > 4) {
      error = error.concat('Correspondent value exceed to 4 characters ');
    }
  }

  if (!d.hasOwnProperty('AccountNo')) {
    error = error.concat('AccountNo field missing  ');
  } else {
    if (d.Correspondent.length > 50) {
      error = error.concat('Account No value exceed to 50 characters ');
    }
  }

  if (!d.hasOwnProperty('Address1')) {
    error = error.concat('Address1 field missing  ');
  } else {
    if (d.Correspondent.length > 150) {
      error = error.concat('Address1 No value exceed to 150 characters ');
    }
  }

  if (GetValueByOjbectName('Address2', d).length > 150) {
    error = error.concat('Address2 No value exceed to 150 characters ');
  }

  if (GetValueByOjbectName('Address3', d).length > 150) {
    error = error.concat('Address3 No value exceed to 150 characters ');
  }

  if (!d.hasOwnProperty('City')) {
    error = error.concat('City field missing  ');
  } else {
    if (d.City.length > 150) {
      error = error.concat('City No value exceed to 150 characters ');
    }
  }

  if (!d.hasOwnProperty('State')) {
    error = error.concat(', State field missing  ');
  } else {
    if (d.State.length > 150) {
      error = error.concat('State value exceed to 150 characters ');
    }
  }

  if (!d.hasOwnProperty('TaxCountry')) {
    return error.concat('Country field missing  ');
  } else {
    if (d.TaxCountry == 'US') {
      if (!d.hasOwnProperty('GovernmentID')) {
        return error.concat('GovernmentID field missing  ');
      } else {
        if (d.GovernmentID.length > 150) {
          error = error.concat('GovernmentID value exceed to 150 characters ');
        }
      }
    }
  }

  if (!d.hasOwnProperty('LegalEntity')) {
    return error.concat('LegalEntity field missing  ');
  } else {
    if (d.LegalEntity.length > 150) {
      error = error.concat('LegalEntity value exceed to 150 characters ');
    }
  }

  if (!d.hasOwnProperty('Year')) {
    error = error.concat('Year field missing  ');
  } else {
    if (d.Year.length > 4) {
      error = error.concat('Year value exceed to 4 characters ');
    }
  }

  if (!d.hasOwnProperty('ID')) {
    error = error.concat('ID missing  ');
  } else {
    if (d.ID.length > 50) {
      error = error.concat('ID value exceed to 50 characters ');
    }
  }

  if (!d.hasOwnProperty('W8W9')) {
    error = error.concat('W8/W9 field missing  ');
  } else {
    if (d.W8W9.length > 20) {
      error = error.concat('W8/W9 value exceed to 20 characters ');
    }
  }

  if (!d.hasOwnProperty('CompanyName')) {
    error = error.concat('CompanyName field missing  ');
  } else {
    if (d.Year.length > 300) {
      error = error.concat('CompanyName value exceed to 300 characters ');
    }
  }

  if (!d.hasOwnProperty('FullName')) {
    error = error.concat('FullName field missing  ');
  } else {
    if (d.Year.length > 300) {
      error = error.concat('FullName value exceed to 300 characters ');
    }
  }

  if (!d.hasOwnProperty('Zip')) {
    error = error.concat('Zip field missing  ');
  } else {
    if (d.Year.length > 30) {
      error = error.concat('Zip value exceed to 30 characters ');
    }
  }

  if (!d.hasOwnProperty('CountryCode')) {
    error = error.concat('CountryCode field missing  ');
  } else {
    if (d.Year.length > 300) {
      error = error.concat('CountryCode value exceed to 300 characters ');
    }
  }

  return error;
};

export const stringToAccount = (str) => {
  // console.log(str);
  var res = str.split(',');
  console.log(res);
  // return {
  //   accountId: res[0].trim(),
  //   externalId: res[1].trim(),
  //   correspondent: res[2].trim(),
  //   office: res[3].trim(),
  //   accountNo: res[4].trim(),
  //   address1:res[5].trim(),
  //   address2: res[6].trim(),
  //   address3: res[7].trim(),
  //   city: res[8].trim(),
  //   state: res[9].trim(),
  //   country: res[10].trim(),
  //   taxCountry: res[11].trim(),
  //   governmentId: res[12].trim(),
  //   legalEntity: res[13].trim(),
  //   year: res[14].trim(),
  //   w8w9:res[15].trim(),
  //   accountName: res[16].trim(),
  //   companyName: res[17].trim(),
  //   fullName: res[18].trim(),
  //   governmentIdType: res[19].trim(),
  //   zip: res[20].trim(),
  //   calcType: res[21].trim(),
  //   countryCode: res[22].trim(),
  // };
};
