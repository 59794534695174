/*ReactJS*/
import React, { useState } from 'react';
import Form1099DivReclassModal from './Form1099DivReclassModal';
 import Form1099DivReclassUpload from './Form1099DivReclassUpload';
import {
  notifySuccess,
  notifyInfo,
} from 'components/Notification/Notification';
import {
  createForm1099DivReClass,
  updateForm1099DivReClass,
  deleteForm1099DivReClass,
  listForm1099DivReClass,
} from '../../../services/Form1099DivReClassService';
import { Button, Box, TextField, IconButton } from '@material-ui/core';
import {
  Add as AddIcon,
  Create as EditIcon,
  Delete as DeleteIcon,
  AssignmentReturn as ImportIcon,
} from '@material-ui/icons';
import Table, { columnType } from 'components/Table/Table';
import useStyles from '../../../styles';
import SearchButton from '../../../components/Button/Search';
import QueryParam from '../../../services/QueryParamService';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment-timezone';

export default function AccountTable() {
  const year = new Date().getFullYear();
  const prevYear = year - 1;

  const classes = useStyles();
  const confirm = useConfirm();
  const options = {
    isRowSelectable: (dataIndex) => isDeleteEnalbed(dataIndex),
    onRowsDelete: (selectedItems) => {
      handleDelete(selectedItems.data);
      return false;
    },
  };

  const columns = [
    {
      name: '',
      type: columnType.buttons,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div
              align={'left'}
              className={classes.grdRow}
              style={{ margin: 0, padding: 0 }}
            >
              <div className={classes.grdCellNone}>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    handleDelete([
                      {
                        dataIndex: dataIndex,
                      },
                    ]);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              <div align={'left'} className={classes.grdCellNone}>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleOpen(rows[dataIndex])}
                >
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          );
        },
        setCellProps: () => ({
          style: { padding: '4px 16px' },
        }),
      },
    },
    {
      name: 'year',
      label: 'Year',
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'symbolId',
      label: 'Symbol ID',
    },
    {
      name: 'recordDate',
      label: 'Record Date',
      type: columnType.date,
    },
    {
      name: 'paymentDate',
      label: 'Payment Date',
      type: columnType.date,
    },
    {
      name: 'divQualified',
      label: 'Div Qualified',
      type: columnType.amount,
    },
    {
      name: 'divNonQualified',
      label: 'Div Non Qualified',
      type: columnType.amount,
    },
    {
      name: 'foreignTaxQualified',
      label: 'Foreign Tax Qualified',
      type: columnType.amount,
    },
    {
      name: 'foreignTaxNonQualified',
      label: 'Foreign Tax Non Qualified',
      type: columnType.amount,
    },
    {
      name: 'stGainQualified',
      label: 'Short Term Gain Qualified',
      type: columnType.amount,
    },
    {
      name: 'stGainNonQualified',
      label: 'Short Term Gain Non Qualified',
      type: columnType.amount,
    },
    {
      name: 'ltCapitalGain',
      label: 'Long Term Capital Gain',
      type: columnType.amount,
    },
    {
      name: 'rcap',
      label: 'RCAP',
      type: columnType.amount,
    },
    {
      name: 'unrecapturedTwelvefiftyGain',
      label: 'Unrecaptured 1250 Gain',
      type: columnType.amount,
    },
    {
      name: 'defPymtAmount',
      label: 'Def Pymt Amount',
      type: columnType.amount,
    },
    {
      name: 'cashLiquidation',
      label: 'Cash Liquidation',
      type: columnType.amount,
    },
    {
      name: 'nonCashLiquidation',
      label: 'Non Cash Liquidation',
      type: columnType.amount,
      options: {
        display: false,
      },
    },
    {
      name: 'sectionTwelvezerotwoGain',
      label: 'Section 1202 Gain',
      type: columnType.amount,
      options: {
        display: false,
      },
    },
    {
      name: 'collectibleTwentyeightGain',
      label: 'Collectible 28 Gain',
      type: columnType.amount,
      options: {
        display: false,
      },
    },
    {
      name: 'intt',
      label: 'Int',
      type: columnType.amount,
      options: {
        display: false,
      },
    },
    {
      name: 'adrFee',
      label: 'Adr Fee',
      type: columnType.amount,
      options: {
        display: false,
      },
    },
    {
      name: 'exemptInterestDiv',
      label: 'Exempt Interest Div',
      type: columnType.amount,
    },
    {
      name: 'altMinTax',
      label: 'Alt Min Tax',
      type: columnType.amount,
    },
    {
      name: 'divNineteenninea',
      label: 'Div 99a',
      type: columnType.amount,
    },
    {
      name: 'grossProceeds',
      label: 'Gross Proceeds',
      type: columnType.amount,
    },
    {
      name: 'total',
      label: 'Total',
      type: columnType.amount,
    },
  ];

  const [rowData, setRowData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [rows, setRows] = useState([]);
  const [openImport, setOpenImport] = React.useState(false);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      symbol: '',
    })
  );

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({
      ...searchData,
      [input.name]: input.value,
    });
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      QueryParam.set(searchData);
      const data = await listForm1099DivReClass(searchData);
      console.log(data);
      setRows(data.divReclassesList);
      notifyInfo(data.divReclassesList.length + ' search results.');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (rowsToDelete) => {
    let messageKey = '';
    if (rowsToDelete.length === 1) {
      messageKey = rows[rowsToDelete[0].dataIndex].symbol;
    } else {
      messageKey = rowsToDelete.length + ' items';
    }

    let idsToDelete = [];
    confirm({
      description:
        "You are about to delete '" +
        messageKey +
        "'. Please confirm your action.",
      confirmationText: 'Yes, delete',
    }).then(async () => {
      const rowsCopy = [...rows];
      try {
        for (const r of rowsToDelete) {
          let index = r.dataIndex;
          await deleteForm1099DivReClass(rows[index].id);
          idsToDelete.push(rows[index].id);
        }
      } catch (error) {
        console.error(error);
      } finally {
        const filtered = rowsCopy.filter((r) => !idsToDelete.includes(r.id));
        console.log(filtered);
        setRows(filtered);
        notifySuccess(messageKey + ' Form1099Div Reclass has been deleted');
      }
    });
  };

  const handleOpen = (data) => {
    if (!data) {
      data = {
        year: prevYear.toString(),
        cusip: '',
        symbol: '',
        symbolId: '',
        recordDate: moment(new Date()).format('yyyy-MM-DD'),
        paymentDate: moment(new Date()).format('yyyy-MM-DD'),
        divQualified: '',
        divNonQualified: '',
        foreignTaxQualified: '',
        foreignTaxNonQualified: '',
        stGainQualified: '',
        stGainNonQualified: '',
        ltCapitalGain: '',
        RCAP: '',
        unrecapturedTwelvefiftyGain: '',
        defPymtAmount: '',
        cashLiquidation: '',
        nonCashLiquidation: '',
        sectionTwelvezerotwoGain: '',
        collectibleTwentyeightGain: '',
        intt: '',
        adrFee: '',
        exemptInterestDiv: '',
        altMinTax: '',
        divNinteenninea: '',
        total: '',
      };
    }
    setRowData(data);
    setOpen(true);
  };

  const handleClose = async (data, isEdit) => {
    if (!data) {
      setOpen(false);
      return;
    }

    try {
      if (isEdit) {
        //EDIT
        const resp = await updateForm1099DivReClass(data);
        const rowsCopy = [...rows];
        const index = rows.indexOf(rowData);
        rowsCopy[index] = resp.divReclass;
        setRows(rowsCopy);
        notifySuccess('Form1099Div Reclass has been updated.');
      } else {
        //ADD
        const resp = await createForm1099DivReClass(data);
        setRows([resp.divReclass, ...rows]);
        notifySuccess('New Form1099Div Reclass has been added.');
      }

      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUploadClose = async (data, isEdit) => {
    setOpenImport(false);
  };

  const isDeleteEnalbed = (dataIndex) => {
    return rows[dataIndex].status !== 'disabled';
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="symbol"
                  fullWidth
                  label="Symbol"
                  value={searchData.symbol}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen();
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={handleSearch}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          <div className={classes.grdCellNone} style={{ marginLeft: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<ImportIcon />}
              onClick={() => {
                setOpenImport(true);
              }}
            >
              Import
            </Button>
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        <Table
          title={'Form 1099 Div Reclass'}
          data={rows}
          columns={columns}
          options={options}
          additionalCell={true}
        />
      </Box>
      {open && (
        <Form1099DivReclassModal
          onClose={handleClose}
          open={open}
          value={rowData}
        ></Form1099DivReclassModal>
      )}
      {openImport && (
        <Form1099DivReclassUpload
          onClose={handleUploadClose}
          open={openImport}
          value={rowData}
        ></Form1099DivReclassUpload>
      )}
    </div>
  );
}
