/*eslint eqeqeq: "off"*/
import {
  Form1099MiscServiceClient,
  Form1099MiscRequest,
  DownLoadMiscRequest,
} from '../proto/taxfilibpb/form1099_misc_grpc_web_pb';
import { notifyError,notifySuccess } from 'components/Notification/Notification';
import { auth } from '../lib/auth/Auth';
import download from './DownloadService';
const service = new Form1099MiscServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function listForm1099Misc(param) {
  return new Promise((resolve, reject) => {
    const req = new Form1099MiscRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setOffice(param.office);
    req.setAccountNo(param.accountNo);

    service.listForm1099Misc(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function form1099MiscDetails(param) {
  return new Promise((resolve, reject) => {
    const req = new Form1099MiscRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setOffice(param.office);
    req.setAccountNo(param.accountNo);

    service.form1099MiscDetails(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}


export async function downloadMisc(param, filetype,batchNo,downloadType) {
  return new Promise((resolve, reject) => {
    let request = new DownLoadMiscRequest();
    request.setFileType(filetype);
    request.setBatchNo(batchNo);
    request.setDownloadType(downloadType);
    let req = new Form1099MiscRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setOffice(param.office);
    req.setAccountNo(param.accountNo);
    req.setUsrId(param.usrId);
    request.setForm1099MiscRequest(req);

    service.download(request, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        console.log(response.array);
        download(response);
        resolve(response);
        if(response.array[2]){
          notifySuccess('Download '+ response.array[2] +' completed');
        }else{
          notifySuccess('Download completed');
        }
      }
    });
  });
}


export async function generateMisc(param, filetype,batchNo,downloadType) {
  return new Promise((resolve, reject) => {
    let request = new DownLoadMiscRequest();
    request.setFileType(filetype);
    request.setBatchNo(batchNo);
    request.setDownloadType(downloadType);
    let req = new Form1099MiscRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setOffice(param.office);
    req.setAccountNo(param.accountNo);
    req.setUsrId(param.usrId);
    request.setForm1099MiscRequest(req);

    service.generate(request, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        console.log(response.array);
        resolve(response);
      }
    });
  });
}



