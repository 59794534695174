/**
 * @fileoverview gRPC-Web generated client stub for taxfilingpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.taxfilingpb = require('./account_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.AccountServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.AccountServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Account,
 *   !proto.taxfilingpb.CreateAccountResponse>}
 */
const methodDescriptor_AccountService_CreateAccount = new grpc.web.MethodDescriptor(
  '/taxfilingpb.AccountService/CreateAccount',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Account,
  proto.taxfilingpb.CreateAccountResponse,
  /**
   * @param {!proto.taxfilingpb.Account} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.CreateAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Account,
 *   !proto.taxfilingpb.CreateAccountResponse>}
 */
const methodInfo_AccountService_CreateAccount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.CreateAccountResponse,
  /**
   * @param {!proto.taxfilingpb.Account} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.CreateAccountResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Account} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.CreateAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.CreateAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.AccountServiceClient.prototype.createAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.AccountService/CreateAccount',
      request,
      metadata || {},
      methodDescriptor_AccountService_CreateAccount,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Account} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.CreateAccountResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.AccountServicePromiseClient.prototype.createAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.AccountService/CreateAccount',
      request,
      metadata || {},
      methodDescriptor_AccountService_CreateAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Account,
 *   !proto.taxfilingpb.UpdateAccountResponse>}
 */
const methodDescriptor_AccountService_UpdateAccount = new grpc.web.MethodDescriptor(
  '/taxfilingpb.AccountService/UpdateAccount',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Account,
  proto.taxfilingpb.UpdateAccountResponse,
  /**
   * @param {!proto.taxfilingpb.Account} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UpdateAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Account,
 *   !proto.taxfilingpb.UpdateAccountResponse>}
 */
const methodInfo_AccountService_UpdateAccount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.UpdateAccountResponse,
  /**
   * @param {!proto.taxfilingpb.Account} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UpdateAccountResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Account} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.UpdateAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.UpdateAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.AccountServiceClient.prototype.updateAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.AccountService/UpdateAccount',
      request,
      metadata || {},
      methodDescriptor_AccountService_UpdateAccount,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Account} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.UpdateAccountResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.AccountServicePromiseClient.prototype.updateAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.AccountService/UpdateAccount',
      request,
      metadata || {},
      methodDescriptor_AccountService_UpdateAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.ReadAccountRequest,
 *   !proto.taxfilingpb.ReadAccountResponse>}
 */
const methodDescriptor_AccountService_ReadAccount = new grpc.web.MethodDescriptor(
  '/taxfilingpb.AccountService/ReadAccount',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.ReadAccountRequest,
  proto.taxfilingpb.ReadAccountResponse,
  /**
   * @param {!proto.taxfilingpb.ReadAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ReadAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.ReadAccountRequest,
 *   !proto.taxfilingpb.ReadAccountResponse>}
 */
const methodInfo_AccountService_ReadAccount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.ReadAccountResponse,
  /**
   * @param {!proto.taxfilingpb.ReadAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ReadAccountResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.ReadAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.ReadAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.ReadAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.AccountServiceClient.prototype.readAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.AccountService/ReadAccount',
      request,
      metadata || {},
      methodDescriptor_AccountService_ReadAccount,
      callback);
};


/**
 * @param {!proto.taxfilingpb.ReadAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.ReadAccountResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.AccountServicePromiseClient.prototype.readAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.AccountService/ReadAccount',
      request,
      metadata || {},
      methodDescriptor_AccountService_ReadAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.DeleteAccountRequest,
 *   !proto.taxfilingpb.DeleteAccountResponse>}
 */
const methodDescriptor_AccountService_DeleteAccount = new grpc.web.MethodDescriptor(
  '/taxfilingpb.AccountService/DeleteAccount',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.DeleteAccountRequest,
  proto.taxfilingpb.DeleteAccountResponse,
  /**
   * @param {!proto.taxfilingpb.DeleteAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.DeleteAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.DeleteAccountRequest,
 *   !proto.taxfilingpb.DeleteAccountResponse>}
 */
const methodInfo_AccountService_DeleteAccount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.DeleteAccountResponse,
  /**
   * @param {!proto.taxfilingpb.DeleteAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.DeleteAccountResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.DeleteAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.DeleteAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.DeleteAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.AccountServiceClient.prototype.deleteAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.AccountService/DeleteAccount',
      request,
      metadata || {},
      methodDescriptor_AccountService_DeleteAccount,
      callback);
};


/**
 * @param {!proto.taxfilingpb.DeleteAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.DeleteAccountResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.AccountServicePromiseClient.prototype.deleteAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.AccountService/DeleteAccount',
      request,
      metadata || {},
      methodDescriptor_AccountService_DeleteAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Account,
 *   !proto.taxfilingpb.ListAccountResponse>}
 */
const methodDescriptor_AccountService_ListAccount = new grpc.web.MethodDescriptor(
  '/taxfilingpb.AccountService/ListAccount',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Account,
  proto.taxfilingpb.ListAccountResponse,
  /**
   * @param {!proto.taxfilingpb.Account} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Account,
 *   !proto.taxfilingpb.ListAccountResponse>}
 */
const methodInfo_AccountService_ListAccount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.ListAccountResponse,
  /**
   * @param {!proto.taxfilingpb.Account} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListAccountResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Account} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.ListAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.ListAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.AccountServiceClient.prototype.listAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.AccountService/ListAccount',
      request,
      metadata || {},
      methodDescriptor_AccountService_ListAccount,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Account} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.ListAccountResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.AccountServicePromiseClient.prototype.listAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.AccountService/ListAccount',
      request,
      metadata || {},
      methodDescriptor_AccountService_ListAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Account,
 *   !proto.taxfilingpb.CreateAccountResponse>}
 */
const methodDescriptor_AccountService_ImportAccount = new grpc.web.MethodDescriptor(
  '/taxfilingpb.AccountService/ImportAccount',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Account,
  proto.taxfilingpb.CreateAccountResponse,
  /**
   * @param {!proto.taxfilingpb.Account} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.CreateAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Account,
 *   !proto.taxfilingpb.CreateAccountResponse>}
 */
const methodInfo_AccountService_ImportAccount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.CreateAccountResponse,
  /**
   * @param {!proto.taxfilingpb.Account} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.CreateAccountResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Account} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.CreateAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.CreateAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.AccountServiceClient.prototype.importAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.AccountService/ImportAccount',
      request,
      metadata || {},
      methodDescriptor_AccountService_ImportAccount,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Account} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.CreateAccountResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.AccountServicePromiseClient.prototype.importAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.AccountService/ImportAccount',
      request,
      metadata || {},
      methodDescriptor_AccountService_ImportAccount);
};


module.exports = proto.taxfilingpb;

