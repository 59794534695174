/*ReactJS*/
import React, { useState } from 'react';
import AdministratorModal from './AdministratorModal';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

import {
  createAdministrator,
  updateAdministrator,
  deleteAdministrator,
  listAdministrator,
} from '../../../services/AdministratorService';

/*Material UI Components*/
import { Button, Box, TextField, IconButton } from '@material-ui/core';

/*Material UI Icons*/
import {
  Add as AddIcon,
  Create as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import Table, { columnType } from 'components/Table/Table';

/*Styles*/
import useStyles from '../../../styles';

/*Custom components*/
import SearchButton from '../../../components/Button/Search';
import QueryParam from '../../../services/QueryParamService';
import { useConfirm } from 'material-ui-confirm';
import { validatePassword } from '../../../lib/validate/validate';

export default function AdministratorTable() {
  const classes = useStyles();
  const confirm = useConfirm();

  const options = {
    isRowSelectable: (dataIndex) => isDeleteEnalbed(dataIndex),
    onRowsDelete: (selectedItems) => {
      handleDelete(selectedItems.data);
      return false;
    },
  };

  const columns = [
    {
      name: '',
      type: columnType.buttons,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div
              align={'left'}
              className={classes.grdRow}
              style={{ margin: 0, padding: 0 }}
            >
              <div className={classes.grdCellNone}>
                <IconButton
                  aria-label="delete"
                  //disabled={!isDeleteEnabled(dataIndex)}
                  onClick={() => {
                    handleDelete([
                      {
                        dataIndex: dataIndex,
                      },
                    ]);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              <div align={'left'} className={classes.grdCellNone}>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleOpen(rows[dataIndex])}
                >
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          );
        },
        setCellProps: () => ({
          style: { padding: '4px 16px' },
        }),
      },
    },
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'createdAt',
      label: 'Created At',
      type: columnType.dateTime,
    },
  ];

  const [rowData, setRowData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [rows, setRows] = useState([]);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      name: '',
      email: '',
      createdAt: '',
    })
  );

  const handleChange = (e, x) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({
      ...searchData,
      [input.name]: input.value,
    });
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      QueryParam.set(searchData);
      const data = await listAdministrator(searchData);

      setRows(data.administratorsList);
      notifyInfo(data.administratorsList.length + ' search results.');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (rowsToDelete) => {
    let messageKey = '';
    if (rowsToDelete.length === 1) {
      messageKey = rows[rowsToDelete[0].dataIndex].name;
    } else {
      messageKey = rowsToDelete.length + ' items';
    }

    let idsToDelete = [];
    confirm({
      description:
        "You are about to delete '" +
        messageKey +
        "'. Please confirm your action.",
      confirmationText: 'Yes, delete',
    }).then(async () => {
      const rowsCopy = [...rows];
      try {
        for (const r of rowsToDelete) {
          let index = r.dataIndex;
          await deleteAdministrator(rows[index].usrId);
          idsToDelete.push(rows[index].usrId);
        }
      } catch (error) {
        console.error(error);
      } finally {
        const filtered = rowsCopy.filter((r) => !idsToDelete.includes(r.usrId));
        setRows(filtered);
        notifySuccess(messageKey + ' Account has been deleted');
      }
    });
  };

  const isDeleteEnalbed = (dataIndex) => {
    return rows[dataIndex].status !== 'disabled';
  };

  const handleOpen = (data) => {
    if (!data) {
      //ADD default values
      data = {
        name: '',
        email: '',
      };
    }
    setRowData(data);
    setOpen(true);
  };

  const handleClose = async (data, isEdit) => {
    if (!data) {
      setOpen(false);
      return;
    }

    try {
      let valid = true;

      if (!data.name) {
        notifyError('Name is required.');
        valid = false;
      }

      if (!data.email) {
        notifyError('Email is required.');
        valid = false;
      }

      if (!isEdit && validatePassword(data.password).err) {
        if (!data.password) {
          notifyError('Password is required.');
        } else {
          notifyError(validatePassword(data.password).msg);
        }

        valid = false;
      }

      if (isEdit && validatePassword(data.newPassword).err) {
        notifyError('New Password does not meet the requirement.');
        return;
      }

      if (isEdit && validatePassword(data.confirmPassword).err) {
        notifyError('Confirm Password does not meet the requirement.');
        return;
      }

      // Check if confirm password is correct
      if (isEdit && data.confirmPassword !== data.newPassword) {
        notifyError('Confirm Password is incorrect.');
        return;
      }

      if (!valid) {
        return;
      }

      if (isEdit) {
        //EDIT
        const resp = await updateAdministrator(data);
        const rowsCopy = [...rows];
        const index = rows.indexOf(rowData);
        rowsCopy[index] = resp.administrator;
        setRows(rowsCopy);
        notifySuccess('Administrator has been updated.');
      } else {
        //ADD
        const resp = await createAdministrator(data);
        setRows([resp.administrator, ...rows]);
        notifySuccess('New Administrator has been added.');
      }

      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="name"
                  fullWidth
                  label="Name"
                  value={searchData.name}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  name="email"
                  fullWidth
                  label="Email"
                  value={searchData.email}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen();
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={handleSearch}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        <Table
          title={'Administrator'}
          data={rows}
          columns={columns}
          options={options}
          additionalCell={true}
        />
      </Box>
      {open && (
        <AdministratorModal
          onClose={handleClose}
          open={open}
          value={rowData}
        ></AdministratorModal>
      )}
    </div>
  );
}
