import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const Timer = (props) => {
  const [minutes, setMinutes] = useState(props.minutes);
  const [seconds, setSeconds] = useState(props.seconds);

  function resetTimer() {
    setMinutes(props.minutes);
    setSeconds(props.seconds);
  }

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          props.setLoading({ timer: false });
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });

  const useStyles = makeStyles(() => ({
    timer: {
      backgroundColor:
        seconds <= 10 && minutes === 0
          ? seconds === 0 && minutes === 0
            ? '#ffeaec'
            : '#ffebd9'
          : '#d5dae5',
      color:
        seconds <= 10 && minutes === 0
          ? seconds === 0 && minutes === 0
            ? '#ff425f'
            : '#ff9d42'
          : '#46485c',
      border:
        seconds <= 10 && minutes === 0
          ? seconds === 0 && minutes === 0
            ? '1px solid ' + '#ff7a8f'
            : '1px solid ' + '#ffc48d'
          : '#d5dae5',
      display: 'inline-block',
      marginTop: -5,
      padding: '6px 10px 7px 10px',
      borderRadius: '5px',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '14px',
      whiteSpace: 'nowrap',
    },
  }));

  const classes = useStyles();

  return (
    <div id="timerReset" style={{ pointerEvents: 'none' }} onClick={resetTimer}>
      {minutes === 0 && seconds === 0 ? (
        <span className={classes.timer}>Code Expired</span>
      ) : (
        <span className={classes.timer}>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
      )}
    </div>
  );
};

export default Timer;
