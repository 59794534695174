/*eslint eqeqeq: "off"*/
/*ReactJS*/
import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';

/*components*/
// import * as XLSX from "xlsx";
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';
import Table, { columnType } from 'components/Table/Table';



/*Service*/

import {
  dateProtoObjectToString,
  dateStringToDateSTD,
} from '../../../services/ConvertService';
import {
  upload,
  createUploadStatus,
  processUpload,
  readUploadStatus,
} from '../../../services/Form1099DivReClassService';
import { getFilesInfo } from '../../../services/ExcelService';
import Form1099DivReclassUploadModal from './Form1099DivReclassUploadModal';
/*Material UI*/
import { useConfirm } from 'material-ui-confirm';
import {
  Create as EditIcon,
  Delete as DeleteIcon,
  AttachFile,
  TrendingUpRounded,
} from '@material-ui/icons';
import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  IconButton,
} from '@material-ui/core';

export default function Firn1099DivReclassUpload({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const confirm = useConfirm();
  const options = {
    selectableRows: 'none',
  };

  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [rowsProcess, setRowsProcess] = useState([]);
  const [processPopup, setProcessPopup] = React.useState(false);
  const [saveEnable,setSaveEnable] = React.useState(false);
  const [showCloseProgress, setShowCloseProgress] = useState(false);


  useEffect(() => {
    if (isOpen) {
    }
  }, [isOpen, value]);

  useEffect(() => {
    setRowsProcess([
        {   
            id:1,
            isInProgress:true,
            process: "Uploading files",
            progress:"0%",
            status:"Pending",
            counter:0,
        },
        {    
            id:2,
            isInProgress:false,
            process: "Importing data",
            progress:"0%",
            status:"Pending",
            counter:0,
        },
        {   
          id:3,
          isInProgress:false,
          process: "Data conversion",
          progress:"0",
          status:"Pending",
          counter:0,
        },
        {   
            id:4,
            isInProgress:false,
            process: "Invalid calculation",
            progress:"0",
            status:"Pending",
            counter:0,
        },
        {   
          id:5,
          isInProgress:false,
          process: "Invalid symbol",
          progress:"0",
          status:"Pending",
          counter:0,
      }
    ])
    
  },[]);

  const readFiles = async (files)  => {
    if (!files) {
      return;
    }

    let rowsCopy = [];
    Array.from(files).map(function(file, i){ 
      getFilesInfo(file).then((d)=>{
        rowsCopy = [...rowsCopy];
        rowsCopy.push(d);
        setRows(rowsCopy);
      });
    });
    setSaveEnable(true);
  };

  const handleDelete = (rowsToDelete) => {
    let messageKey = '';
    if (rowsToDelete.length === 1) {
      messageKey = rows[rowsToDelete[0].dataIndex].correspondent;
    } else {
      messageKey = rowsToDelete.length + ' items';
    }

    confirm({
      description:
        "You are about to delete '" +
        messageKey +
        "'. Please confirm your action.",
      confirmationText: 'Yes',
    }).then(async () => {
      const rowsCopy = [...rows];
      try {
        for (const r of rowsToDelete) {
          let index = r.dataIndex;
          rowsCopy.splice(index, 1);
        }

        setRows(rowsCopy);
        notifySuccess(messageKey + 'Beginning of the Year has been deleted');
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleUploadFiles=(batchNo) => {
    const records = rows.length;
    if (records > 0) {
      const rowsCopy = [...rows];
      rowsCopy.map(function(data, i){ 
        data.batchNo=batchNo;
        const indexRows = rows.indexOf(data);
        const rowsCopy2 = [...rows];
         upload(data).then(() =>{
        //   console.log()
        data.status = "uploaded";
          let item = rowsProcess.find(item => item.id == 1);
          const rowsProcessCopy = [...rowsProcess];
          const index = rowsProcess.indexOf(item);
          item.counter = item.counter+1;
          item.progress = ((item.counter / rows.length) * 100).toFixed(2)+"%"; 
          item.status = "processing...";
          if (rows.length === item.counter){
           item.status = "Completed";
           item.isInProgress = false;
          }
          rowsCopy2[indexRows]=data;
            setRows(rowsCopy2);
            rowsProcessCopy[index] = item;
            setRowsProcess(rowsProcessCopy);
         }).catch((error) => {
          console.error('Error Uploading files', error);
        });;
      })
    }
  };

  const handleProcess= async (param) => {
   let sheetsCounts = rows.map(item => {return item.sheetCounts;});
   let total = sheetsCounts.reduce((total,count)=>{ return total + count;});
   console.log(total);
      await createUploadStatus(param);
      processUpload(param);
      await sleep(1000) 
      let isProcessing = true;
      while (isProcessing) {
          let data = await readUploadStatus(param);
          let item = rowsProcess.find(item => item.id == 2);
          const rowsProcessCopy = [...rowsProcess];
          const index = rowsProcess.indexOf(item);
          item.counter = item.counter+1;
          item.progress = ((data.uploadedSheets / total) * 100).toFixed(2)+"%"; 
          item.status = data.uploadedSheets > 0 ? "processing..." :"preparing...";
          if (data.uploadedSheets >= total){
            item.status = "Completed";
          }
          rowsProcessCopy[index] = item;
          setRowsProcess(rowsProcessCopy);
          await sleep(500) 
          isProcessing = data.isProcessing;
      }
      let data = await readUploadStatus(param);
      const rowsProcessCopy = [...rowsProcess];
      //convertion
      let itemConversion = rowsProcess.find(item => item.id == 3);
      const indexitemConversion = rowsProcess.indexOf(itemConversion);
      if(data.errorMessage.length > 0){
        itemConversion.status = "Failed";
        itemConversion.progress=data.errorMessage;
        notifyError("div reclass Failed to upload: "+data.errorMessage);
      }else{
        itemConversion.status = "Completed";
        itemConversion.progress="";
        //checking data
        let itemNotEqual = rowsProcess.find(item => item.id == 4);
        const indexNotEqual = rowsProcess.indexOf(itemNotEqual);
  
        if (data.notEqualTotal > 0){
          itemNotEqual.status = "Failed";
          itemNotEqual.progress = data.notEqualTotal;
          notifyError("div reclass Failed to upload: Invalid calculation "+data.notEqualTotal+" item(s)");
        }else{
          itemNotEqual.status = "Completed";
        }
        let itemSymbol = rowsProcess.find(item => item.id == 5);
        const indexSymbol = rowsProcess.indexOf(itemSymbol);
        if (data.emptySymbol > 0){
          itemSymbol.status = "Failed";
          itemSymbol.progress = data.emptySymbol;
          notifyError("div reclass Failed to upload: Empty symbol: "+data.emptySymbol+" item(s)");
        }else{
          itemSymbol.status = "Completed";
        }
  
        rowsProcessCopy[indexNotEqual] = itemNotEqual;
        rowsProcessCopy[indexSymbol] = itemSymbol;
      }
      rowsProcessCopy[indexitemConversion] = itemConversion;
      setRowsProcess(rowsProcessCopy);
      setShowCloseProgress(true);
      if(data.errorMessage.length === 0 && data.notEqualTotal === 0 && data.emptySymbol===0 ){
        notifySuccess("div reclass Sucessfully uploaded");
      }
  };


  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  const handleProcessUpload = (data) => {
    let today  = new Date();
    let batchNo = today.getFullYear() +""+  ('0' + (today.getMonth() + 1)).slice(-2)+""+   ('0' + today.getDate()).slice(-2) + "-"+   ('0' + today.getHours()).slice(-2)+""+ ('0' + today.getMinutes()).slice(-2)+""+ ('0' + today.getSeconds()).slice(-2);
    confirm({
      description:
        "You are about to upload '" +
        rows.length + ' items' +
        "'. Please confirm your action.",
      confirmationText: 'Yes, upload',
    }).then(async () => {
      setProcessPopup(true);
    })
    .then(async () =>{
      handleUploadFiles(batchNo);
    })
    .then(async () =>{
      handleProcess({batchNo:batchNo});
    });
  };

  const handleUploadProcessClose = async () => {
      setProcessPopup(false);
  };

   

  /*=========================================================================================
  Table modifications
  ===========================================================================================*/
  const columns = [
    {
      name: '',
      type: columnType.buttons,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div
              align={'left'}
              className={classes.grdRow}
              style={{ margin: 0, padding: 0 }}
            >
              <div className={classes.grdCellNone}>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    handleDelete([{ dataIndex: dataIndex }]);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'fileName',
      label: 'File',
    },
    {
      name: 'sheetCounts',
      label: 'Sheets',
      type: columnType.quantity,
    },
    {
      name: 'status',
      label: 'Status',
    },
  ];

  return (
    <div className={classes.root}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={isOpen}>
          <div className={classes.fadeModalFull}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
             Div Reclass
            </Typography>
            <Box mt={5}>
              <div className={classes.root}>
                <Box component="div" mt={5}>
                  <div className={classes.actionContainer}>
                    <div
                      className={classes.grdCellNone}
                      style={{ marginRight: 10 }}
                    >
                      <input
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain"
                        className={classes.input}
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        multiple
                        onChange={(e) => {
                          readFiles(e.target.files);
                        }}
                      />
                      <label htmlFor="raised-button-file">
                        <Button
                          component="span"
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<AttachFile />}
                        >
                          Choose File
                        </Button>
                      </label>
                    </div>
                  </div>
                </Box>

                <Box component="div" mt={2}>
                  <Table
                    title={'Files'}
                    data={rows}
                    columns={columns}
                    options={options}
                  />
                </Box>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    {
                        saveEnable && (<Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={() => {
                          handleProcessUpload();
                        }}
                      >
                        Save
                      </Button>)
                    }
                  
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </Fade>
      </Modal>
      {processPopup && (
        <Form1099DivReclassUploadModal
          onClose={handleUploadProcessClose}
          open={processPopup}
          value={rowsProcess}
          isCancelEnable = {showCloseProgress}
        ></Form1099DivReclassUploadModal>
      )}
    </div>
  );
}
