/*ReactJS*/
import React, { useEffect } from 'react';

/*Material UI Components*/
import { TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

/*List of legalEntities*/
import { legalEntities } from './AutoCompleteData';
import {
  listSystemCode,
} from '../../services/SystemCodeService';

export default function CountriesSelect(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        var systemCodeSearchData = {
          type: 'Legal Entity',
          code: '',
          description: '',
          note: '',
        }
    
        const { systemCodeList } = await listSystemCode(systemCodeSearchData);

        setOptions(systemCodeList)
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, options]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const selectedCountry = ["Corporation"]

  return (
    <Autocomplete
      name={props.name}
      autoHighlight={true}
      autoComplete={true}
      autoSelect={true}
      clearOnEscape={true}
      id={props.id}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.description === value.description}
      getOptionLabel={(option) => option.description || ''}
      options={options}
      loading={loading}
      onChange={(_, selectedValue) => {
        props.onChange({
          currentTarget: {
            name: props.name,
            value: selectedValue ? selectedValue.description : '',
            note2: selectedValue ? selectedValue.note2 : '',
          },
        });
      }}
      defaultValue={selectedCountry[0]}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name}
          required
          labelid={props.labelid}
          label={props.label}
          value={props.value}
          // onChange={props.onChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
