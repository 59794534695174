/*ReactJS*/
import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';

/*Material-ui*/
import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

/*Service*/
import { dateProtoObjectToString } from '../../../services/ConvertService';

export default function TrnsUploadEditModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (isOpen) {
      setModalData({
        ...value,
        tradeDate: dateProtoObjectToString(value.tradeDate),
        settleDate:  dateProtoObjectToString(value.settleDate),
        systemDate:  dateProtoObjectToString(value.systemDate),
      });
    }
  }, [isOpen, value]);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalFull}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
            Edit Transactions
          </Typography>
          <Box mt={5}>
            <form noValidate autoComplete="off">
            <div className={classes.grdRow}>
             
             <div className={classes.grdCell1} style={{ marginRight: 30 }}>
               <TextField
                 required
                 name="correspondent"
                 fullWidth
                 label="Correspondent"
               
                 value={modalData.correspondent}
                 onChange={handleChange}
                 InputLabelProps={{ shrink: true }}
                 inputProps={{ maxLength: 4 }}
               />
             </div>
             <div className={classes.grdCell1} style={{ marginRight: 30 }}>
               <TextField
                 required
                 name="office"
                 fullWidth
                 label="Office"
                 value={modalData.office}
                 onChange={handleChange}
                 InputLabelProps={{ shrink: true }}
                 inputProps={{ maxLength: 50 }}
               />
             </div>
             <div className={classes.grdCell1} style={{ marginRight: 30 }}>
               <TextField
                 required
                 name="accountNo"
                 fullWidth
                 label="Account No"
                 value={modalData.accountNo}
                 onChange={handleChange}
                 InputLabelProps={{ shrink: true }}
                 inputProps={{ maxLength: 50 }}
               />
             </div>
           </div>

           <div className={classes.grdRow}>
             <div className={classes.grdCell1} style={{ marginRight: 30 }}>
               <TextField
                 required
                 name="tradeDate"
                 fullWidth
                 label="Trade Date"
                 type="date"
                 value={modalData.tradeDate}
                 onChange={handleChange}
                 InputLabelProps={{ shrink: true }}
               />
             </div>
             <div className={classes.grdCell1} style={{ marginRight: 30 }}>
               <TextField
                 required
                 name="settleDate"
                 fullWidth
                 label="Settle Date"
                 type="date"
                 value={modalData.settleDate}
                 onChange={handleChange}
                 InputLabelProps={{ shrink: true }}
               />
             </div>
             <div className={classes.grdCell1} style={{ marginRight: 30 }}>
               <TextField
                 required
                 name="systemDate"
                 fullWidth
                 label="System Date"
                 type="date"
                 value={modalData.systemDate}
                 onChange={handleChange}
                 InputLabelProps={{ shrink: true }}
               />
             </div>
           </div>

           <div className={classes.grdRow}>
           <div className={classes.grdCell1} style={{ marginRight: 30 }}>
               <TextField
                 required
                 name="year"
                 label="Year"
                 type="number"
                 fullWidth
                 InputLabelProps={{ shrink: true }}
                 value={modalData.year}
                 onChange={handleChange}
               />
             </div>

           <div className={classes.grdCell1} style={{ marginRight: 30 }}>
               <TextField
                required
                 name="description"
                 fullWidth
                 label="Description"
                 inputProps={{
                  maxlength:150,
                }}
                 value={modalData.description}
                 onChange={handleChange}
                 InputLabelProps={{ shrink: true }}
               />
             </div>
             <div className={classes.grdCell1} style={{ marginRight: 30 }}>
               <TextField
                required
                 name="netAmount"
                 fullWidth
                 label="Net Amount"
                 type="number"
                 value={modalData.netAmount}
                 onChange={handleChange}
                 InputLabelProps={{ shrink: true }}
               />
             </div>
           
           </div>
           <div className={classes.grdRow}>
             <div className={classes.grdCell1} style={{ marginRight: 30 }}>
               <TextField
                 required
                 name="qty"
                 fullWidth
                 label="Qty"
                 value={modalData.qty}
                 onChange={handleChange}
                 InputLabelProps={{ shrink: true }}
               />
             </div>
             <div className={classes.grdCell1} style={{ marginRight: 30 }}>
               <TextField
                 required
                 name="symbol"
                 fullWidth
                 label="Symbol"
                 inputProps={{
                  maxlength:50,
                }}
                 value={modalData.symbol}
                 onChange={handleChange}
                 InputLabelProps={{ shrink: true }}
               />
             </div>
             <div className={classes.grdCell1} style={{ marginRight: 30 }}>
               <TextField
                 required
                 name="externalId"
                 fullWidth
                 label="External ID"
                 inputProps={{
                 maxlength:50,
               }}
               value={modalData.externalId}
               onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              />
            </div>
            </div>
          
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <InputLabel shrink>IRS Form</InputLabel>
                  <Select
                    required
                    name="irsForm"
                    displayEmpty
                    fullWidth
                    value={modalData.irsForm}
                    onChange={handleChange}
                  >
                    <MenuItem value="1099 div">1099 DIV</MenuItem>
                    <MenuItem value="1099 INT">1099 INT</MenuItem>
                    <MenuItem value="1099 misc">1099 MISC</MenuItem>
                    <MenuItem value="1099 B">1099 B</MenuItem>
                  </Select>
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}></div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}></div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}></div>
            </div>


              <div className={classes.modalFooter}>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div className={classes.grdCellNone}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => {
                      handleClose(modalData);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
