export const menus = [
  {
    id: 1,
    groupLabel: 'Admin / User Management',
    label: 'Administrator',
    link: '/app/user-management/administrator',
    page: 'administrator',
  },
  {
    id: 2,
    groupLabel: 'Admin / Account Setup',
    label: 'Account',
    link: '/app/account-setup/account',
    page: 'account',
  },
  {
    id: 2.5,
    groupLabel: 'Admin / Account Setup',
    label: 'TIN Validation',
    link: '/app/account-setup/tin-validation',
    page: 'tin-validation',
  },
  {
    id: 3.0,
    groupLabel: 'Admin / Account Setup',
    label: 'Tax Treaty',
    link: '/app/account-setup/tax-treaty',
    page: 'tax-treaty',
  },
  {
    id: 3.1,
    groupLabel: 'Tax Filing',
    label: 'Form 1042 DIVNRA',
    link: '/app/tax-filing/form-1042-divnra',
    page: 'form-1042-divnra',
  },
  {
    id: 4,
    groupLabel: 'Tax Filing',
    label: 'Form 1099 B',
    link: '/app/tax-filing/form-1099-b',
    page: 'form-1099-b',
  },
  {
    id: 5,
    groupLabel: 'Tax Filing',
    label: 'Form 1099 INT',
    link: '/app/tax-filing/form-1099-int',
    page: 'form-1099-int',
  },
  {
    id: 6,
    groupLabel: 'Tax Filing',
    label: 'Form 1099 DIV',
    link: '/app/tax-filing/form-1099-div',
    page: 'form-1099-div',
  },
  {
    id: 6.5,
    groupLabel: 'Tax Filing',
    label: 'Form 1099 DIV Reclass',
    link: '/app/tax-filing/form-1099-reclass',
    page: 'form-1099-reclass',
  },
  {
    id: 7,
    groupLabel: 'Tax Filing',
    label: 'Form 1099 MISC',
    link: '/app/tax-filing/form-1099-misc',
    page: 'form-1099-misc',
  },
  {
    id: 8,
    groupLabel: 'Trasnsaction',
    label: 'Beginning of the Year',
    link: '/app/transaction/boy-cost',
    page: 'boy-cost',
  },
  {
    id: 9,
    groupLabel: 'Trasnsaction',
    label: 'TRNS',
    link: '/app/transaction/trns',
    page: 'trns',
  },
  {
    id: 10,
    groupLabel: 'Trasnsaction',
    label: 'Process',
    link: '/app/transaction/process',
    page: 'process',
  },
];
