// IN COLUMN SETTINGS
//  {
//   name: 'sdDebitCashBalance',
//   label: 'SD Debit Cash Balance',
//   type: 'quantity', //custom quantity/amount/percentage/date/dateTime
//   addFooter: true, //custom true/false
// },

//pending: filter retain values
/*eslint eqeqeq: "off"*/
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormGroup, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import tableTheme from './TableStyle';
import TableBodyFooter from './TableBodyFooter';
import { createSelector } from 'reselect'; //memoization

import Checkbox from '@material-ui/core/Checkbox';
import { CheckCircleOutline, CheckBoxOutlineBlank,FiberManualRecord } from '@material-ui/icons';
import {
  formatPbDate,
  formatCurrency,
  formatPercentage,
  formatQty,
} from 'lib/fmt';
import {
  protoTimeSpanObjectToString,
  protoDateObjectToDate,
  protoDatTimeObjectToDate,
  dateStringToDate,
  stringToDateTime,
} from '../../services/ConvertService';

export const columnType = {
  date: 'date',
  dateTime: 'dateTime',
  quantity: 'quantity',
  percentage: 'percentage',
  amount: 'amount',
  text: 'text',
  buttons: 'buttons',
  boolean: 'boolean',
  progress:'progress',
};

export default function Table({
  data: rows,
  columns,
  title,
  options,
  additionalCell,
}) {
  const [visibleColumns, setVisibleColumns] = React.useState([]);
  const [columnOrder, setColumnOrder] = React.useState(
    columns.map((a, index) => {
      return index;
    })
  );

  const columnSettings = getColumnSettings(columns, visibleColumns, rows);
  const optionSettings = getOptionSettings(
    options,
    columns,
    rows,
    title,
    columnOrder,
    setColumnOrder,
    visibleColumns,
    setVisibleColumns,
    additionalCell
  );

  useEffect(() => {
    setColumnOrder(getColumnOrder(columns, columnSettings));
    setVisibleColumns(getVisibleColumns(columns));
  }, []);

  return (
    <MuiThemeProvider theme={tableTheme()}>
      <MUIDataTable
        title={title}
        data={rows}
        columns={columnSettings}
        options={optionSettings}
        components={{
          Checkbox: CustomCheckbox,
        }}
        additionalCell={additionalCell}
      />
    </MuiThemeProvider>
  );
}

const CustomCheckbox = (props) => {
  const bgColor =
    props['data-description'] === 'row-select-header' ? '' : 'whitesmoke';

  return (
    <Checkbox
      style={{
        backgroundColor: bgColor,
      }}
      {...props}
    />
  );
};

const getColumnSettings = createSelector(
  (columns, visibleColumns, rows) => ({
    columns,
    visibleColumns,
    rows,
  }),
  (param) => {
    const sortedColumns = sortColumnSettings([...param.columns]);
    const { visibleColumns, rows } = param;

    return sortedColumns.map((col) => {
      col.options = col.options || {};

      //visibility state
      if (visibleColumns.includes(col.name)) {
        col.options.display = true;
      } else if (visibleColumns.length) {
        col.options.display = false;
      }

      switch (col.type) {
        case columnType.date:
          col.options.filterType = 'custom';
          col.options.filterList = [];
          col.options.customFilterListOptions = getCustomFilterListOptions(col);
          col.options.filterOptions = {
            names: [],
            logic(stringValue, filters) {
              const from = dateStringToDate(filters[0]);
              const to = dateStringToDate(filters[1]);
              const value = protoDateObjectToDate(stringValue);

              if (from && to) {
                return !(value >= from && value <= to);
              } else if (from) {
                return !(value >= from);
              } else if (to) {
                return !(value <= to);
              }

              return false;
            },
            display: (filterList, onChange, index, column) => {
              return dateRangeTemplate(
                filterList,
                onChange,
                index,
                column,
                col,
                'date'
              );
            },
          };

          col.options.customBodyRenderLite = (dataIndex) => {
            return getColumnValue(col.type, rows[dataIndex][col.name]);
          };

          col.options.sortCompare = dateSortCompare;
          break;
        case columnType.dateTime:
          col.options.filterType = 'custom';
          col.options.filterList = [];
          col.options.customFilterListOptions = getCustomFilterListOptions(col);
          col.options.filterOptions = {
            fullWidth: true,
            names: [],
            logic(stringValue, filters) {
              const from = stringToDateTime(filters[0]);
              const to = stringToDateTime(filters[1]);
              const value = protoDatTimeObjectToDate(stringValue);

              if (from && to) {
                return !(value >= from && value <= to);
              } else if (from) {
                return !(value >= from);
              } else if (to) {
                return !(value <= to);
              }

              return false;
            },
            display: (filterList, onChange, index, column) => {
              return dateRangeTemplate(
                filterList,
                onChange,
                index,
                column,
                col,
                'datetime-local'
              );
            },
          };
          col.options.customBodyRenderLite = (dataIndex) => {
            return getColumnValue(col.type, rows[dataIndex][col.name]);
          };

          col.options.sortCompare = dateTimeSortCompare;
          break;
        case columnType.amount:
        case columnType.percentage:
        case columnType.quantity:
          col.options.customBodyRenderLite = (dataIndex) => {
            const value = rows[dataIndex][col.name];
            const formattedValue = getColumnValue(col.type, value);

            return (
              <div align="right" style={{ color: value < 0 && '#f44336' }}>
                {formattedValue}
              </div>
            );
          };

          col.options.sortCompare = amountSortCompare;
          break;
        case columnType.buttons:
          col.options = {
            ...{
              draggable: false,
              resizable: false,
              print: false,
              searchable: false,
              filter: false,
              sort: false,
              empty: true,
              viewColumns: false,
              download: false,
            },
            ...col.options,
          };

          break;
        case columnType.boolean:
          col.options.customBodyRenderLite = (dataIndex) => {
            return rows[dataIndex][col.name] ? (
              <CheckCircleOutline />
            ) : (
              <CheckBoxOutlineBlank />
            );
          };
          break;
        case columnType.progress:
            col.options.customBodyRenderLite = (dataIndex) => {
              return rows[dataIndex][col.name] ? (
                <CircularProgress  size={32}/>
              ) : (
                <FiberManualRecord  size={32}/>
              );
            };
            break;
      }

      return col;
    });
  }
);
const getCustomFilterListOptions = (col) => {
  return {
    render: (value) => {
      if (value[0] && value[1]) {
        return `From ${col.label}: ${value[0]}, To ${col.label}: ${value[1]}`;
      } else if (value[0]) {
        return `From ${col.label}: ${value[0]}`;
      } else if (value[1]) {
        return `To ${col.label}: ${value[1]}`;
      }
      return [];
    },
    update: (filterList, filterPos, index) => {
      if (filterPos === 0) {
        filterList[index].splice(filterPos, 1, '');
      } else if (filterPos === 1) {
        filterList[index].splice(filterPos, 1);
      } else if (filterPos === -1) {
        filterList[index] = [];
      }

      return filterList;
    },
  };
};

const dateRangeTemplate = (
  filterList,
  onChange,
  index,
  column,
  col,
  dateType
) => (
  <div>
    <FormGroup row>
      <TextField
        label={'From ' + col.label}
        type={dateType}
        InputLabelProps={{ shrink: true }}
        value={filterList[index][0] || ''}
        onChange={(event) => {
          filterList[index][0] = event.target.value;
          onChange(filterList[index], index, column);
        }}
        style={{ width: '45%', marginRight: '5%' }}
        inputProps={{
          max: filterList[index][1],
        }}
      />
      <TextField
        label={'To ' + col.label}
        type={dateType}
        InputLabelProps={{ shrink: true }}
        value={filterList[index][1] || ''}
        onChange={(event) => {
          filterList[index][1] = event.target.value;
          onChange(filterList[index], index, column);
        }}
        style={{ width: '45%' }}
        inputProps={{
          min: filterList[index][0],
        }}
      />
    </FormGroup>
  </div>
);

const getOptionSettings = createSelector(
  (
    options,
    columns,
    rows,
    title,
    columnOrder,
    setColumnOrder,
    visibleColumns,
    setVisibleColumns,
    additionalCell
  ) => {
    const footerSettings = getFooterSettings(columns);

    const defaultSettings = {
      columnOrder: columnOrder,
      filterType: 'multiselect',
      responsive: 'standard',
      download: true,
      filter: true,
      search: true,
      print: false,
      sort: true,
      viewColumns: true,
      resizableColumns: false,
      draggableColumns: {
        enabled: true,
      },
      selectableRowsHeader: true,
      selectableRows: 'multiple',
      rowsPerPage: 10,
      downloadOptions: {
        filename:
          title.replace(/ /g, '') +
          '_' +
          moment().format('MMMM Do YYYY') +
          '.csv',
      },
      onColumnOrderChange: (newColumnOrder) => {
        setColumnOrder(newColumnOrder);
      },
      customTableBodyFooterRender: function(opts) {
        return (
          <TableBodyFooter
            columnOrder={columnOrder}
            columns={opts.columns}
            columnsWithAmt={footerSettings.columnsWithAmt}
            columnsWithQty={footerSettings.columnsWithQty}
            columnsWithPercentage={footerSettings.columnsWithPercentage}
            rows={rows}
            additionalCell={additionalCell}
          ></TableBodyFooter>
        );
      },
      onViewColumnsChange: (changedColumn, action) => {
        if (action === 'add') {
          setVisibleColumns([...visibleColumns, changedColumn]);
        } else {
          const copy = [...visibleColumns];
          const index = copy.indexOf(changedColumn);
          copy.splice(index, 1);
          setVisibleColumns(copy);
        }
      },
      customSearch: (searchQuery, currentRow, cols) => {
        const types = getColumnTypes(cols, columns);
        for (let i = 0; i < currentRow.length; i++) {
          const value = currentRow[i];
          if (!value) continue;
          if (typeof value === 'string') {
            if (value.toLowerCase().includes(searchQuery.toLowerCase()))
              return true;
          }
          const formattedValue = getColumnValue(types[i], value);
          if (formattedValue.toLowerCase().includes(searchQuery.toLowerCase()))
            return true;
        }
        return false;
      },
      onDownload: (buildHead, buildBody, cols, data) => {
        const types = getColumnTypes(cols, columns);
        const body = data.map((row) => {
          row.data = row.data.map((value, index) => {
            return getColumnValue(types[index], value);
          });
          return row;
        });
        return '\uFEFF' + buildHead(cols) + buildBody(body).replace(/"'/g, '"');
      },
    };

    return {
      defaultSettings,
      options,
    };
  },
  (param) => {
    if (!param.options) return param.defaultSettings;

    return { ...param.defaultSettings, ...param.options };
  }
);

const getColumnTypes = (currentColumnSettings, baseColumnSettings) => {
  return currentColumnSettings.map((col) => {
    const columnSetting = getColumnSettingsByName(baseColumnSettings, col.name);
    return columnSetting.type;
  });
};

const getColumnValue = (type, value) => {
  switch (type) {
    case columnType.date:
      return formatPbDate(value);
    case columnType.dateTime:
      return protoTimeSpanObjectToString(value, 'DD/MM/YYYY hh:mm');
    case columnType.quantity:
      return formatQty(value);
    case columnType.percentage:
      return formatPercentage(value);
    case columnType.amount:
      return formatCurrency(value);
    case columnType.text:
    case '':
    case columnType.buttons:
    case undefined:
      return value;
  }

  console.error('invalid column type');
  return value;
};

const getFooterSettings = createSelector(
  (columns) => columns,
  (columns) =>
    columns.reduce(
      (settings, col) => {
        if (col.addFooter)
          switch (col.type) {
            case columnType.amount:
              settings.columnsWithAmt.push(col.name);
              break;
            case columnType.percentage:
              settings.columnsWithPercentage.push(col.name);
              break;
            case columnType.quantity:
              settings.columnsWithQty.push(col.name);
              break;
            default:
              console.error(
                "cannot add custom footer, object have invalid 'type' property"
              );
              break;
          }

        return settings;
      },
      {
        columnsWithAmt: [],
        columnsWithQty: [],
        columnsWithPercentage: [],
        // additionalCell,
      }
    )
);

const getVisibleColumns = (columns) =>
  columns.reduce((filtered, col) => {
    if (
      col.options === undefined ||
      col.options.display === undefined ||
      col.options.display === true ||
      col.options.display === 'true'
    ) {
      filtered.push(col.name);
    }

    return filtered;
  }, []);

const getColumnOrder = (columns, columnsSettings) =>
  columns.map((col) => {
    return getNewColumnIndex(columnsSettings, col.name);
  });

const getNewColumnIndex = (columnsSettings, name) => {
  for (let i = 0; i < columnsSettings.length; i++) {
    if (columnsSettings[i].name == name) return i;
  }
};

const getColumnSettingsByName = (columnsSettings, name) => {
  const index = getNewColumnIndex(columnsSettings, name);
  return columnsSettings[index];
};

const sortColumnSettings = (columns) =>
  columns.sort(function(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

const amountSortCompare = (order) => {
  return (obj1, obj2) => {
    const val1 = parseFloat(obj1.data);
    const val2 = parseFloat(obj2.data);
    return (val1 - val2) * (order === 'asc' ? 1 : -1);
  };
};

const dateSortCompare = (order) => {
  return (obj1, obj2) => {
    if (!obj1.data) return order === 'asc' ? -1 : 1;
    if (!obj2.data) return order === 'asc' ? 1 : -1;

    const yearDiff = obj1.data.year - obj2.data.year;
    if (yearDiff != 0) {
      return yearDiff * (order === 'asc' ? 1 : -1);
    }

    const monthDiff = obj1.data.month - obj2.data.month;
    if (monthDiff != 0) {
      return monthDiff * (order === 'asc' ? 1 : -1);
    }
    return (obj1.data.day - obj2.data.day) * (order === 'asc' ? 1 : -1);
  };
};

const dateTimeSortCompare = (order) => {
  return (obj1, obj2) => {
    if (!obj1.data) return order === 'asc' ? -1 : 1;
    if (!obj2.data) return order === 'asc' ? 1 : -1;

    const secondsDiff = obj1.data.seconds - obj2.data.seconds;
    if (secondsDiff != 0) {
      return secondsDiff * (order === 'asc' ? 1 : -1);
    }

    return (obj1.data.nanos - obj2.data.nanos) * (order === 'asc' ? 1 : -1);
  };
};
