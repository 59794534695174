import React from 'react';
import { TextField } from '@material-ui/core';
import Timer from '../Misc/Timer';

const CodeField = (props) => {
  const endIcon = () => {
    return <Timer minutes={2} seconds={0} setLoading={props.setLoading} />;
  };

  
  return (
    <div>
      <TextField
        style={{
          marginTop: props.mt ? 20 : 0,
          marginBottom: props.mb ? props.mb : 0,
        }}
        fullWidth={true}
        autoFocus={props.autoFocus}
        autoComplete="off"
        error={props.error}
        required={props.required}
        disabled={props.disabled}
        id={props.id}
        name={props.name}
        label={props.label}
        placeholder={props.placeholder}
        helperText={props.error ? props.msg : ''}
        type="text"
        value={props.value || ''}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: props.startTimer ? endIcon() : null,
        }}
        inputProps={{
          readOnly: props.readOnly ? true : false,
          maxLength: props.max,
          autoComplete: 'off',
        }}
      />
    </div>
  );
};
export default CodeField;
