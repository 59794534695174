/*eslint eqeqeq: "off"*/
import jwtDecode from 'jwt-decode';
import moment from 'moment';

import {
  AuthServiceClient,
  LoginRequest,
  RefreshTokenRequest,
  ValidateCodeRequest,
  ChangePasswordRequest,
  GetModeRequest,
  GetKeyRequest,
  SessionRequest,
} from '../proto/authpb/auth_grpc_web_pb';

import { verifyToken } from 'authenticator';

import { auth } from '../lib/auth/Auth';

const tokenKey = 'id_token';
const refreshTokenKey = 'refresh_token';
const authMsgKey = 'auth_error';
const client = new AuthServiceClient(window.env.GRPC_ENDPOINT);

export function getToken() {
  return localStorage.getItem(tokenKey);
}

export function removeToken() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(refreshTokenKey);
  localStorage.removeItem('code_validity');
  localStorage.removeItem('access_token');
}

export function getAuthErrorMessage() {
  return localStorage.getItem(authMsgKey);
}

export function setAuthErrorMessage(msg) {
  return localStorage.setItem(authMsgKey, msg);
}

export function removeAuthErrorMessage() {
  return localStorage.removeItem(authMsgKey);
}

export function getCurrentUser() {
  try {
    const jwt = getToken();
    return jwtDecode(jwt);
  } catch (error) {
    return null;
  }
}

export function refreshToken(auth) {
  //if user not logged in then return
  const user = getCurrentUser();
  if (!user) return;

  //if token already expired then return
  const unixNow = new Date().getTime() / 1000;
  if (unixNow > user.exp) return;

  //if token will not expire in 1hr then return
  const refreshTime = moment(new Date())
    .add(60, 'm')
    .toDate();
  const refreshUnix = refreshTime.getTime() / 1000;
  if (refreshUnix < user.exp) return;

  //if token will about to expire then request a new one
  let req = new RefreshTokenRequest();
  req.setRefreshToken(localStorage.getItem(refreshTokenKey));
  req.setClientId(window.env.GRPC_CLIENT_ID);

  const service = new AuthServiceClient(
    window.env.GRPC_ENDPOINT,
    {},
    { ...auth }
  );

  service.refreshToken(req, {}, (error, response) => {
    if (error) {
      console.error('refreshToken', error);
    } else {
      const { accessToken, refreshToken } = response.toObject();
      setTokens(accessToken, refreshToken);
    }
  });
}

async function loginPromise(data) {
  return new Promise((resolve, reject) => {
    let req = new LoginRequest();
    req.setEmail(data.email);
    req.setPassword(data.password);
    req.setClientId(window.env.GRPC_CLIENT_ID);
    req.setMode(data.mode);
    req.setAuthenticationMode(data.authenticationMode);

    if (data.mode === 'Log In Via Token') {
      req.setAccessToken(data.access_token);
      req.setUsrId(data.usr_id);
    }

    client.login(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        if (data.mode === 'Log In Via Token') {
          localStorage.setItem('code_validity', true);
        }

        resolve(response.toObject());
      }
    });
  });
}

export async function login(data) {
  const { accessToken, refreshToken, usrId } = await loginPromise(data);
  setTokens(accessToken, refreshToken);

  return usrId;
}

function setTokens(accessToken, refreshToken) {
  localStorage.setItem(tokenKey, accessToken);
  localStorage.setItem(refreshTokenKey, refreshToken);
}

export function logout() {
  removeToken();
}

export function validateAuthCode(param) {
  const service = new AuthServiceClient(
    window.env.GRPC_ENDPOINT,
    {},
    { ...auth }
  );

  return new Promise((resolve, reject) => {
    let req = new ValidateCodeRequest();
    req.setUsrId(param.usrId);
    req.setCode(param.code);

    service.validateCode(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        const res = response.toObject();
        if (res.status === 'Success') {
          localStorage.setItem('code_validity', true);
        }

        resolve(res);
      }
    });
  });
}

export function changePassword(param) {
  const service = new AuthServiceClient(
    window.env.GRPC_ENDPOINT,
    {},
    { ...auth }
  );
  return new Promise((resolve, reject) => {
    let req = new ChangePasswordRequest();
    req.setUsrId(param.usrId);
    req.setNewPassword(param.newPassword);

    service.changePassword(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export function getValidCodeResponse() {
  const valid = localStorage.getItem('code_validity');
  return valid;
}

export async function validateSession(params) {
  const service = new AuthServiceClient(
    window.env.GRPC_ENDPOINT,
    {},
    { ...auth }
  );

  return new Promise((resolve, reject) => {
    const req = new SessionRequest();
    req.setUsrId(params.usrId);
    req.setEmail(params.email);
    req.setAccessToken(params.accessToken);

    service.validateSession(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

async function getAuthMode(data) {
  return new Promise((resolve, reject) => {
    let req = new GetModeRequest();
    req.setEmail(data.email);

    client.getAuthMode(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

async function getKey(data) {
  const service = new AuthServiceClient(
    window.env.GRPC_ENDPOINT,
    {},
    { ...auth }
  );

  return new Promise((resolve, reject) => {
    let req = new GetKeyRequest();
    req.setEmail(data.email);
    req.setUsrId(data.usrId);

    service.getKey(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export function validateAuthenticatorCode(param) {
  if (verifyToken(param.key, param.code) == null) {
    return 'Invalid verification code.';
  } else {
    localStorage.setItem('code_validity', true);
    return 'Success';
  }
}

export default {
  removeToken,
  getToken,
  getAuthErrorMessage,
  setAuthErrorMessage,
  removeAuthErrorMessage,
  logout,
  login,
  getCurrentUser,
  refreshToken,
  validateAuthCode,
  getValidCodeResponse,
  changePassword,
  validateSession,
  getKey,
  getAuthMode,
  validateAuthenticatorCode,
};
