/*eslint eqeqeq: "off"*/
import {
  TrnsServiceClient,
  Trns,
  ReadTrnsRequest,
  DeleteTrnsRequest,
} from '../proto/taxfilibpb/trns_grpc_web_pb';
import { notifyError } from 'components/Notification/Notification';
import { auth } from '../lib/auth/Auth';
import { stringToProtoDate, dateStringToDateSTD } from './ConvertService';
const service = new TrnsServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

const requestTrnsParameter = (param) => {
  let req = new Trns();
  req.setTrnsId(param.trnsId);
  req.setYear(param.year ? param.year.toString() : '');
  req.setCorrespondent(
    param.correspondent ? param.correspondent.toString() : ''
  );
  req.setOffice(param.office ? param.office.toString() : '');
  req.setAccountNo(param.accountNo ? param.accountNo.toString() : '');
  req.setTradeDate(stringToProtoDate(param.tradeDate));
  req.setEntryType(param.entryType ? param.entryType.toString() : '');
  req.setSymbol(param.symbol ? param.symbol.toString() : '');
  req.setCusip(param.cusip ? param.cusip.toString() : '');
  req.setQty(param.qty ? param.qty.toString() : '');
  req.setNetAmount(param.netAmount);
  req.setSettleDate(stringToProtoDate(param.settleDate));
  req.setExternalId(param.externalId ? param.externalId.toString() : '');
  req.setSystemDate(stringToProtoDate(param.systemDate));
  req.setDescription(param.description ? param.description.toString() : '');
  req.setGrossAmount(param.grossAmount ? param.grossAmount.toString() : '');
  req.setIrsForm(param.irsForm ? param.irsForm.toString() : '');
  req.setSymbolDescription(
    param.symbolDescription ? param.symbolDescription.toString() : ''
  );
  req.setSymbolId(param.symbolId ? param.symbolId.toString() : '');
  req.setAccountId(param.accountId ? param.accountId.toString() : '');
  return req;
};

export async function createTrns(param) {
  return new Promise((resolve, reject) => {
    const req = requestTrnsParameter(param);
    service.createTrns(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateTrns(param) {
  return new Promise((resolve, reject) => {
    const req = requestTrnsParameter(param);
    service.updateTrns(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listTrns(param) {
  return new Promise((resolve, reject) => {
    const req = requestTrnsParameter(param);
    service.listTrns(req, {}, (error, response) => {
      if (error) {
        console.log(error.message);
        notifyError(error.message);
        reject(error);
      } else {
        console.log(response.toObject());
        resolve(response.toObject());
      }
    });
  });
}

export async function ReadTrns(param) {
  return new Promise((resolve, reject) => {
    const req = new ReadTrnsRequest();
    req.setTrnsId(param.trnsId);

    service.ReadTrns(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteTrns(param) {
  return new Promise((resolve, reject) => {
    const req = new DeleteTrnsRequest();
    req.setTrnsId(param.trnsId);

    service.deleteTrns(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function importTrns(param) {
  return new Promise((resolve, reject) => {
    console.log(param);
    let req = new Trns();
    req.setYear(param.year ? param.year.toString() : '');
    req.setCorrespondent(
      param.correspondent ? param.correspondent.toString() : ''
    );
    req.setOffice(param.office ? param.office.toString() : '');
    req.setAccountNo(param.accountNo ? param.accountNo.toString() : '');

    req.setTradeDate(stringToProtoDate(param.tradeDate));
    req.setSystemDate(stringToProtoDate(param.systemDate));
    req.setSettleDate(stringToProtoDate(param.settleDate));

    req.setEntryType(param.entryType ? param.entryType.toString() : '');
    req.setSymbol(param.symbol ? param.symbol.toString() : '');
    req.setQty(param.qty.toString());
    req.setNetAmount(param.netAmount.toString());
    req.setExternalId(param.externalId ? param.externalId.toString() : '');
    req.setDescription(param.description ? param.description.toString() : '');
    req.setPrice(param.price.toString());
    req.setIrsForm(param.irsForm ? param.irsForm.toString() : '');

    service.importTrns(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export const convertDataToTrnsModel = (d) => {
  return {
    externalId: d.ID,
    year: d.Year,
    correspondent: d.Correspondent,
    office: d.Office,
    accountNo: d.AccountNo,
    systemDate: dateStringToDateSTD(d.SystemDate),
    tradeDate: dateStringToDateSTD(d.TradeDate),
    settleDate: dateStringToDateSTD(d.SettleDate),
    entryType: d.EntryType,
    symbol: d.Symbol,
    qty: d.QTY,
    netAmount: d.NetAmount,
    description: d.Description,
    price: d.Price,
    irsForm: d.IRSForm,
  };
};

export const isValidData = (d) => {
  let error = '';
  if (!d.hasOwnProperty('Year')) {
    error = error.concat('Year field missing  ');
  } else {
    if (d.Year.length > 4) {
      error = error.concat('Year value exceed to 4 characters ');
    }
  }

  if (!d.hasOwnProperty('Correspondent')) {
    error = error.concat('Correspondent field missing  ');
  } else {
    if (d.Correspondent.length > 4) {
      error = error.concat('Correspondent value exceed to 4 characters ');
    }
  }

  if (!d.hasOwnProperty('Office')) {
    error = error.concat('Office field missing  ');
  } else {
    if (d.Office.length > 50) {
      error = error.concat('Office value exceed to 50 characters ');
    }
  }

  if (!d.hasOwnProperty('AccountNo')) {
    error = error.concat('AccountNo field missing  ');
  } else {
    if (d.AccountNo.length > 50) {
      error = error.concat('AccountNo value exceed to 50 characters ');
    }
  }
  if (!d.hasOwnProperty('ID')) {
    error = error.concat('ID field missing  ');
  } else {
    if (d.ID.length > 50) {
      error = error.concat('ID value exceed to 50 characters ');
    }
  }

  if (!d.hasOwnProperty('TradeDate')) {
    error = error.concat('TradeDate field missing  ');
  } else {
    let date = new Date(d.TradeDate);
    if (!(date instanceof Date && !isNaN(date.valueOf()))) {
      error = error.concat('TradeDate format invalid');
    }
  }

  if (!d.hasOwnProperty('EntryType')) {
    error = error.concat('EntryType field missing  ');
  } else {
    if (d.EntryType.length > 50) {
      error = error.concat('EntryType value exceed to 50 characters ');
    }
  }

  if (!d.hasOwnProperty('Symbol')) {
    error = error.concat('Symbol field missing  ');
  } else {
    if (d.Symbol.length > 50) {
      error = error.concat('Symbol value exceed to 50 characters ');
    }
  }

  if (!d.hasOwnProperty('QTY')) {
    error = error.concat(', QTY field missing  ');
  }

  let year = parseInt(d.Year.toString());
  if (year.toString().length < 4 || year.toString().length > 4) {
    error = error.concat('(Year:' + year + ') invalid range  ');
  }

  let qty = parseInt(d.QTY.toString());
  if (isNaN(qty)) {
    error = error.concat('QTY:' + d.QTY + ') invalid value  ');
  }

  if (!d.hasOwnProperty('NetAmount')) {
    error = error.concat(', NetAmount field missing  ');
  } else {
    let netAmount = parseFloat(d.NetAmount.toString());
    if (isNaN(netAmount)) {
      error = error.concat('(NetAmount:' + d.NetAmount + ') invalid value  ');
    } else {
      if (!(netAmount.toString() === d.NetAmount.toString())) {
        error = error.concat('(NetAmount:' + d.NetAmount + ') invalid value  ');
      }
    }
  }

  if (!d.hasOwnProperty('SettleDate')) {
    error = error.concat('SettleDate field missing  ');
  } else {
    let date = new Date(d.SettleDate);
    if (!(date instanceof Date && !isNaN(date.valueOf()))) {
      error = error.concat('SettleDate format invalid');
    }
  }

  if (!d.hasOwnProperty('SystemDate')) {
    error = error.concat('SystemDate field missing  ');
  } else {
    let date = new Date(d.SettleDate);
    if (!(date instanceof Date && !isNaN(date.valueOf()))) {
      error = error.concat('SystemDate format invalid');
    }
  }

  if (!d.hasOwnProperty('Description')) {
    error = error.concat(', Description field missing  ');
  } else {
    if (d.Description.length > 150) {
      error = error.concat('Description value exceed to 150 characters ');
    }
  }

  if (!d.hasOwnProperty('IRSForm')) {
    error = error.concat(', IRSForm field missing  ');
  } else {
    if (d.IRSForm.length > 20) {
      error = error.concat('IRSForm value exceed to 20 characters ');
    }
  }

  return error;
};
