/*ReactJS*/
import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';

import {
  Typography,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
} from '@material-ui/core';

import Button from '../../../components/Button/Button';
import CountriesSelect from '../../../components/AutoComplete/Countries';
import StatesSelect from '../../../components/AutoComplete/States';
import MaskedField from '../../../components/TextField/MaskedField';

export default function ProfileModal({
  onClose: handleClose,
  open: isOpen,
  value,
  loading,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({
    companyName: value.companyName,
    companyTin: value.companyTin,
    telNo: value.telNo,
    address: value.address,
    state: value.state,
    city: value.city,
    zip: value.zip,
    country: value.country,
    photo: value.photo,
  });

  useEffect(() => {
    if (isOpen) {
      setModalData({ ...value });
    }
  }, [isOpen, value]);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    console.log(input);

    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  const selectPhoto = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setModalData({ ...modalData, photo: reader.result });
    };

    reader.readAsDataURL(file);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalMd}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
            Edit System Profile
          </Typography>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <div className={classes.grdRow}>
                    <div className={classes.grdCell1}>
                      <div className={classes.logoContainer}>
                        {modalData.photo ? (
                          <img
                            className={classes.uploadThumbnail}
                            src={modalData.photo}
                            alt="logo"
                          />
                        ) : (
                          <label className={classes.noThumbnailLabel}>
                            Please select an Image for report logo.
                          </label>
                        )}
                      </div>
                      <input
                        style={{ display: 'none' }}
                        accept="image/*"
                        id="input-photo"
                        type="file"
                        onChange={(e) => {
                          selectPhoto(e);
                        }}
                      />
                      <label htmlFor="input-photo">
                        <Button
                          disabled={loading}
                          color="primary"
                          component="span"
                          type="photo"
                          label="Select Photo"
                        />
                      </label>
                    </div>
                  </div>
                  <div className={classes.grdRow}>
                    <div className={classes.grdCell1}>
                      <TextField
                        fullWidth
                        required={true}
                        name="companyName"
                        label="Company Name"
                        value={modalData.companyName}
                        onChange={handleChange}
                        inputProps={{ maxLength: 50 }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <div className={classes.grdRow}>
                    <div className={classes.grdCell1}>
                      <MaskedField
                        required={true}
                        name="companyTin"
                        label="Company TIN"
                        format="##-#######"
                        value={modalData.companyTin}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className={classes.grdRow}>
                    <div className={classes.grdCell1}>
                      <MaskedField
                        required={true}
                        name="telNo"
                        label="Telephone No."
                        format="###-###-####"
                        value={modalData.telNo}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.grdCell1}>
                  <div className={classes.grdRow}>
                    <div className={classes.grdCell1}>
                      <TextField
                        fullWidth
                        required={true}
                        name="address"
                        label="Address"
                        value={modalData.address}
                        onChange={handleChange}
                        inputProps={{ maxLength: 50 }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <div className={classes.grdRow}>
                    <div className={classes.grdCell1}>
                      <TextField
                        fullWidth
                        required={true}
                        name="city"
                        label="City"
                        value={modalData.city}
                        onChange={handleChange}
                        inputProps={{ maxLength: 50 }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <div className={classes.grdRow}>
                    <div className={classes.grdCell1}>
                      <StatesSelect
                        required={true}
                        name="state"
                        label="State"
                        value={modalData.state}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className={classes.grdRow}>
                    <div className={classes.grdCell1}>
                      <TextField
                        fullWidth
                        name="zip"
                        label="Zip Code"
                        value={modalData.zip}
                        onChange={handleChange}
                        inputProps={{ maxLength: 10 }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <div className={classes.grdRow}>
                    <div className={classes.grdCell1}>
                      <CountriesSelect
                        fullWidth
                        required={true}
                        name="country"
                        label="Country"
                        value={modalData.country}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.modalFooter}>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    disabled={loading}
                    color="primary"
                    label="Close"
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </div>
                <div className={classes.grdCellNone}>
                  <Button
                    disabled={loading}
                    loading={loading}
                    color="secondary"
                    label={loading ? 'Saving...' : 'Save'}
                    onClick={() => {
                      handleClose(modalData);
                    }}
                  />
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
