// source: proto/taxfilingpb/form1042_divnra.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_type_date_pb = require('../../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
var proto_commonpb_file_pb = require('../../proto/commonpb/file_pb.js');
goog.object.extend(proto, proto_commonpb_file_pb);
goog.exportSymbol('proto.taxfilingpb.DownLoadForm1042DivnraRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1042Divnra', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1042DivnraDetails', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1042DivnraProcessRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1042DivnraProcessResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1042DivnraRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.GenerateForm1042DivnraResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.ListForm1042DivnraDetailsResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.ListForm1042DivnraResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1042DivnraRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1042DivnraRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1042DivnraRequest.displayName = 'proto.taxfilingpb.Form1042DivnraRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1042Divnra = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1042Divnra, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1042Divnra.displayName = 'proto.taxfilingpb.Form1042Divnra';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1042DivnraDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1042DivnraDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1042DivnraDetails.displayName = 'proto.taxfilingpb.Form1042DivnraDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ListForm1042DivnraResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.taxfilingpb.ListForm1042DivnraResponse.repeatedFields_, null);
};
goog.inherits(proto.taxfilingpb.ListForm1042DivnraResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ListForm1042DivnraResponse.displayName = 'proto.taxfilingpb.ListForm1042DivnraResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ListForm1042DivnraDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.taxfilingpb.ListForm1042DivnraDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.taxfilingpb.ListForm1042DivnraDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ListForm1042DivnraDetailsResponse.displayName = 'proto.taxfilingpb.ListForm1042DivnraDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1042DivnraProcessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1042DivnraProcessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1042DivnraProcessRequest.displayName = 'proto.taxfilingpb.Form1042DivnraProcessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1042DivnraProcessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1042DivnraProcessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1042DivnraProcessResponse.displayName = 'proto.taxfilingpb.Form1042DivnraProcessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.DownLoadForm1042DivnraRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.DownLoadForm1042DivnraRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.DownLoadForm1042DivnraRequest.displayName = 'proto.taxfilingpb.DownLoadForm1042DivnraRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.GenerateForm1042DivnraResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.GenerateForm1042DivnraResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.GenerateForm1042DivnraResponse.displayName = 'proto.taxfilingpb.GenerateForm1042DivnraResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1042DivnraRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1042DivnraRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1042DivnraRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1042DivnraRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    office: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    usrId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    accountName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1042DivnraRequest}
 */
proto.taxfilingpb.Form1042DivnraRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1042DivnraRequest;
  return proto.taxfilingpb.Form1042DivnraRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1042DivnraRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1042DivnraRequest}
 */
proto.taxfilingpb.Form1042DivnraRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOffice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsrId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1042DivnraRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1042DivnraRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1042DivnraRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1042DivnraRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOffice();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string year = 1;
 * @return {string}
 */
proto.taxfilingpb.Form1042DivnraRequest.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042DivnraRequest} returns this
 */
proto.taxfilingpb.Form1042DivnraRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.taxfilingpb.Form1042DivnraRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042DivnraRequest} returns this
 */
proto.taxfilingpb.Form1042DivnraRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string office = 3;
 * @return {string}
 */
proto.taxfilingpb.Form1042DivnraRequest.prototype.getOffice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042DivnraRequest} returns this
 */
proto.taxfilingpb.Form1042DivnraRequest.prototype.setOffice = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.taxfilingpb.Form1042DivnraRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042DivnraRequest} returns this
 */
proto.taxfilingpb.Form1042DivnraRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 usr_id = 5;
 * @return {number}
 */
proto.taxfilingpb.Form1042DivnraRequest.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.Form1042DivnraRequest} returns this
 */
proto.taxfilingpb.Form1042DivnraRequest.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string account_name = 6;
 * @return {string}
 */
proto.taxfilingpb.Form1042DivnraRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042DivnraRequest} returns this
 */
proto.taxfilingpb.Form1042DivnraRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1042Divnra.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1042Divnra.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1042Divnra} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1042Divnra.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromDate: (f = msg.getFromDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toDate: (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    account: jspb.Message.getFieldWithDefault(msg, 4, ""),
    companyName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    address: jspb.Message.getFieldWithDefault(msg, 6, ""),
    city: jspb.Message.getFieldWithDefault(msg, 7, ""),
    state: jspb.Message.getFieldWithDefault(msg, 8, ""),
    zip: jspb.Message.getFieldWithDefault(msg, 9, ""),
    country: jspb.Message.getFieldWithDefault(msg, 10, ""),
    taxCountry: jspb.Message.getFieldWithDefault(msg, 11, ""),
    div: jspb.Message.getFieldWithDefault(msg, 12, ""),
    taxRate: jspb.Message.getFieldWithDefault(msg, 13, ""),
    divnra: jspb.Message.getFieldWithDefault(msg, 14, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 15, ""),
    office: jspb.Message.getFieldWithDefault(msg, 16, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 17, ""),
    legalEntity: jspb.Message.getFieldWithDefault(msg, 18, ""),
    governmentId: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1042Divnra}
 */
proto.taxfilingpb.Form1042Divnra.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1042Divnra;
  return proto.taxfilingpb.Form1042Divnra.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1042Divnra} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1042Divnra}
 */
proto.taxfilingpb.Form1042Divnra.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setZip(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxCountry(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiv(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxRate(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDivnra(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setOffice(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalEntity(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setGovernmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1042Divnra.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1042Divnra.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1042Divnra} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1042Divnra.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getZip();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTaxCountry();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDiv();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTaxRate();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDivnra();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getOffice();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getLegalEntity();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getGovernmentId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional string year = 1;
 * @return {string}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date from_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getFromDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
*/
proto.taxfilingpb.Form1042Divnra.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Form1042Divnra.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.Date to_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getToDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
*/
proto.taxfilingpb.Form1042Divnra.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Form1042Divnra.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string account = 4;
 * @return {string}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string company_name = 5;
 * @return {string}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string address = 6;
 * @return {string}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string city = 7;
 * @return {string}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string state = 8;
 * @return {string}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string zip = 9;
 * @return {string}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.setZip = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string country = 10;
 * @return {string}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string tax_country = 11;
 * @return {string}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getTaxCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.setTaxCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string div = 12;
 * @return {string}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getDiv = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.setDiv = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string tax_rate = 13;
 * @return {string}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getTaxRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.setTaxRate = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string divnra = 14;
 * @return {string}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getDivnra = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.setDivnra = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string correspondent = 15;
 * @return {string}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string office = 16;
 * @return {string}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getOffice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.setOffice = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string account_no = 17;
 * @return {string}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string legal_entity = 18;
 * @return {string}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getLegalEntity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.setLegalEntity = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string government_id = 19;
 * @return {string}
 */
proto.taxfilingpb.Form1042Divnra.prototype.getGovernmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042Divnra} returns this
 */
proto.taxfilingpb.Form1042Divnra.prototype.setGovernmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1042DivnraDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1042DivnraDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1042DivnraDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    trNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 4, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    divnra: jspb.Message.getFieldWithDefault(msg, 6, ""),
    taxRate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    taxAmount: jspb.Message.getFieldWithDefault(msg, 8, ""),
    div: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1042DivnraDetails}
 */
proto.taxfilingpb.Form1042DivnraDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1042DivnraDetails;
  return proto.taxfilingpb.Form1042DivnraDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1042DivnraDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1042DivnraDetails}
 */
proto.taxfilingpb.Form1042DivnraDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrNo(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDivnra(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxRate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxAmount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiv(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1042DivnraDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1042DivnraDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1042DivnraDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDivnra();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTaxRate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTaxAmount();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDiv();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string tr_no = 1;
 * @return {string}
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.getTrNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042DivnraDetails} returns this
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.setTrNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date settle_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.taxfilingpb.Form1042DivnraDetails} returns this
*/
proto.taxfilingpb.Form1042DivnraDetails.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Form1042DivnraDetails} returns this
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042DivnraDetails} returns this
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cusip = 4;
 * @return {string}
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042DivnraDetails} returns this
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string symbol_description = 5;
 * @return {string}
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042DivnraDetails} returns this
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string divnra = 6;
 * @return {string}
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.getDivnra = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042DivnraDetails} returns this
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.setDivnra = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string tax_rate = 7;
 * @return {string}
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.getTaxRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042DivnraDetails} returns this
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.setTaxRate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string tax_amount = 8;
 * @return {string}
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.getTaxAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042DivnraDetails} returns this
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.setTaxAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string div = 9;
 * @return {string}
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.getDiv = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1042DivnraDetails} returns this
 */
proto.taxfilingpb.Form1042DivnraDetails.prototype.setDiv = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.taxfilingpb.ListForm1042DivnraResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ListForm1042DivnraResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ListForm1042DivnraResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ListForm1042DivnraResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1042DivnraResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    form1042DivnraList: jspb.Message.toObjectList(msg.getForm1042DivnraList(),
    proto.taxfilingpb.Form1042Divnra.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ListForm1042DivnraResponse}
 */
proto.taxfilingpb.ListForm1042DivnraResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ListForm1042DivnraResponse;
  return proto.taxfilingpb.ListForm1042DivnraResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ListForm1042DivnraResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ListForm1042DivnraResponse}
 */
proto.taxfilingpb.ListForm1042DivnraResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1042Divnra;
      reader.readMessage(value,proto.taxfilingpb.Form1042Divnra.deserializeBinaryFromReader);
      msg.addForm1042Divnra(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ListForm1042DivnraResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ListForm1042DivnraResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ListForm1042DivnraResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1042DivnraResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForm1042DivnraList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.taxfilingpb.Form1042Divnra.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Form1042Divnra form1042_divnra = 1;
 * @return {!Array<!proto.taxfilingpb.Form1042Divnra>}
 */
proto.taxfilingpb.ListForm1042DivnraResponse.prototype.getForm1042DivnraList = function() {
  return /** @type{!Array<!proto.taxfilingpb.Form1042Divnra>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.taxfilingpb.Form1042Divnra, 1));
};


/**
 * @param {!Array<!proto.taxfilingpb.Form1042Divnra>} value
 * @return {!proto.taxfilingpb.ListForm1042DivnraResponse} returns this
*/
proto.taxfilingpb.ListForm1042DivnraResponse.prototype.setForm1042DivnraList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.taxfilingpb.Form1042Divnra=} opt_value
 * @param {number=} opt_index
 * @return {!proto.taxfilingpb.Form1042Divnra}
 */
proto.taxfilingpb.ListForm1042DivnraResponse.prototype.addForm1042Divnra = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.taxfilingpb.Form1042Divnra, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.taxfilingpb.ListForm1042DivnraResponse} returns this
 */
proto.taxfilingpb.ListForm1042DivnraResponse.prototype.clearForm1042DivnraList = function() {
  return this.setForm1042DivnraList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.taxfilingpb.ListForm1042DivnraDetailsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ListForm1042DivnraDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ListForm1042DivnraDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ListForm1042DivnraDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1042DivnraDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    form1042DivnraDetailsList: jspb.Message.toObjectList(msg.getForm1042DivnraDetailsList(),
    proto.taxfilingpb.Form1042DivnraDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ListForm1042DivnraDetailsResponse}
 */
proto.taxfilingpb.ListForm1042DivnraDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ListForm1042DivnraDetailsResponse;
  return proto.taxfilingpb.ListForm1042DivnraDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ListForm1042DivnraDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ListForm1042DivnraDetailsResponse}
 */
proto.taxfilingpb.ListForm1042DivnraDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1042DivnraDetails;
      reader.readMessage(value,proto.taxfilingpb.Form1042DivnraDetails.deserializeBinaryFromReader);
      msg.addForm1042DivnraDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ListForm1042DivnraDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ListForm1042DivnraDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ListForm1042DivnraDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1042DivnraDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForm1042DivnraDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.taxfilingpb.Form1042DivnraDetails.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Form1042DivnraDetails form1042_divnra_details = 1;
 * @return {!Array<!proto.taxfilingpb.Form1042DivnraDetails>}
 */
proto.taxfilingpb.ListForm1042DivnraDetailsResponse.prototype.getForm1042DivnraDetailsList = function() {
  return /** @type{!Array<!proto.taxfilingpb.Form1042DivnraDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.taxfilingpb.Form1042DivnraDetails, 1));
};


/**
 * @param {!Array<!proto.taxfilingpb.Form1042DivnraDetails>} value
 * @return {!proto.taxfilingpb.ListForm1042DivnraDetailsResponse} returns this
*/
proto.taxfilingpb.ListForm1042DivnraDetailsResponse.prototype.setForm1042DivnraDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.taxfilingpb.Form1042DivnraDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.taxfilingpb.Form1042DivnraDetails}
 */
proto.taxfilingpb.ListForm1042DivnraDetailsResponse.prototype.addForm1042DivnraDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.taxfilingpb.Form1042DivnraDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.taxfilingpb.ListForm1042DivnraDetailsResponse} returns this
 */
proto.taxfilingpb.ListForm1042DivnraDetailsResponse.prototype.clearForm1042DivnraDetailsList = function() {
  return this.setForm1042DivnraDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1042DivnraProcessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1042DivnraProcessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1042DivnraProcessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1042DivnraProcessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromDate: (f = msg.getFromDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toDate: (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1042DivnraProcessRequest}
 */
proto.taxfilingpb.Form1042DivnraProcessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1042DivnraProcessRequest;
  return proto.taxfilingpb.Form1042DivnraProcessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1042DivnraProcessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1042DivnraProcessRequest}
 */
proto.taxfilingpb.Form1042DivnraProcessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1042DivnraProcessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1042DivnraProcessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1042DivnraProcessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1042DivnraProcessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.type.Date from_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.taxfilingpb.Form1042DivnraProcessRequest.prototype.getFromDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.taxfilingpb.Form1042DivnraProcessRequest} returns this
*/
proto.taxfilingpb.Form1042DivnraProcessRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Form1042DivnraProcessRequest} returns this
 */
proto.taxfilingpb.Form1042DivnraProcessRequest.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Form1042DivnraProcessRequest.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.type.Date to_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.taxfilingpb.Form1042DivnraProcessRequest.prototype.getToDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.taxfilingpb.Form1042DivnraProcessRequest} returns this
*/
proto.taxfilingpb.Form1042DivnraProcessRequest.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Form1042DivnraProcessRequest} returns this
 */
proto.taxfilingpb.Form1042DivnraProcessRequest.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Form1042DivnraProcessRequest.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1042DivnraProcessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1042DivnraProcessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1042DivnraProcessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1042DivnraProcessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1042DivnraProcessResponse}
 */
proto.taxfilingpb.Form1042DivnraProcessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1042DivnraProcessResponse;
  return proto.taxfilingpb.Form1042DivnraProcessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1042DivnraProcessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1042DivnraProcessResponse}
 */
proto.taxfilingpb.Form1042DivnraProcessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1042DivnraProcessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1042DivnraProcessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1042DivnraProcessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1042DivnraProcessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.taxfilingpb.Form1042DivnraProcessResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.taxfilingpb.Form1042DivnraProcessResponse} returns this
 */
proto.taxfilingpb.Form1042DivnraProcessResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.DownLoadForm1042DivnraRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.DownLoadForm1042DivnraRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.DownLoadForm1042DivnraRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DownLoadForm1042DivnraRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    form1042DivnraRequest: (f = msg.getForm1042DivnraRequest()) && proto.taxfilingpb.Form1042DivnraRequest.toObject(includeInstance, f),
    fileType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    downloadType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    batchNo: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.DownLoadForm1042DivnraRequest}
 */
proto.taxfilingpb.DownLoadForm1042DivnraRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.DownLoadForm1042DivnraRequest;
  return proto.taxfilingpb.DownLoadForm1042DivnraRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.DownLoadForm1042DivnraRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.DownLoadForm1042DivnraRequest}
 */
proto.taxfilingpb.DownLoadForm1042DivnraRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1042DivnraRequest;
      reader.readMessage(value,proto.taxfilingpb.Form1042DivnraRequest.deserializeBinaryFromReader);
      msg.setForm1042DivnraRequest(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownloadType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.DownLoadForm1042DivnraRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.DownLoadForm1042DivnraRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.DownLoadForm1042DivnraRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DownLoadForm1042DivnraRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForm1042DivnraRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.taxfilingpb.Form1042DivnraRequest.serializeBinaryToWriter
    );
  }
  f = message.getFileType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDownloadType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional Form1042DivnraRequest form1042_divnra_request = 1;
 * @return {?proto.taxfilingpb.Form1042DivnraRequest}
 */
proto.taxfilingpb.DownLoadForm1042DivnraRequest.prototype.getForm1042DivnraRequest = function() {
  return /** @type{?proto.taxfilingpb.Form1042DivnraRequest} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.Form1042DivnraRequest, 1));
};


/**
 * @param {?proto.taxfilingpb.Form1042DivnraRequest|undefined} value
 * @return {!proto.taxfilingpb.DownLoadForm1042DivnraRequest} returns this
*/
proto.taxfilingpb.DownLoadForm1042DivnraRequest.prototype.setForm1042DivnraRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.DownLoadForm1042DivnraRequest} returns this
 */
proto.taxfilingpb.DownLoadForm1042DivnraRequest.prototype.clearForm1042DivnraRequest = function() {
  return this.setForm1042DivnraRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.DownLoadForm1042DivnraRequest.prototype.hasForm1042DivnraRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string file_type = 2;
 * @return {string}
 */
proto.taxfilingpb.DownLoadForm1042DivnraRequest.prototype.getFileType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.DownLoadForm1042DivnraRequest} returns this
 */
proto.taxfilingpb.DownLoadForm1042DivnraRequest.prototype.setFileType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string download_type = 3;
 * @return {string}
 */
proto.taxfilingpb.DownLoadForm1042DivnraRequest.prototype.getDownloadType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.DownLoadForm1042DivnraRequest} returns this
 */
proto.taxfilingpb.DownLoadForm1042DivnraRequest.prototype.setDownloadType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string batch_no = 4;
 * @return {string}
 */
proto.taxfilingpb.DownLoadForm1042DivnraRequest.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.DownLoadForm1042DivnraRequest} returns this
 */
proto.taxfilingpb.DownLoadForm1042DivnraRequest.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.GenerateForm1042DivnraResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.GenerateForm1042DivnraResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.GenerateForm1042DivnraResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.GenerateForm1042DivnraResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.GenerateForm1042DivnraResponse}
 */
proto.taxfilingpb.GenerateForm1042DivnraResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.GenerateForm1042DivnraResponse;
  return proto.taxfilingpb.GenerateForm1042DivnraResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.GenerateForm1042DivnraResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.GenerateForm1042DivnraResponse}
 */
proto.taxfilingpb.GenerateForm1042DivnraResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.GenerateForm1042DivnraResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.GenerateForm1042DivnraResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.GenerateForm1042DivnraResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.GenerateForm1042DivnraResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.taxfilingpb.GenerateForm1042DivnraResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.taxfilingpb.GenerateForm1042DivnraResponse} returns this
 */
proto.taxfilingpb.GenerateForm1042DivnraResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.taxfilingpb);
