/*eslint eqeqeq: "off"*/
import {
  BoyCostServiceClient,
  BoyCost,
  ReadBoyCostRequest,
  DeleteBoyCostRequest,
} from '../proto/taxfilibpb/boy_cost_grpc_web_pb';
import { notifyError } from 'components/Notification/Notification';
import { auth } from '../lib/auth/Auth';
import { stringToProtoDate, dateStringToDateSTD } from './ConvertService';

const service = new BoyCostServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

const requestBoyCostParameter = (param) => {
  let req = new BoyCost();
  req.setBoyCostId(param.boyCostId);
  req.setYear(param.year);
  req.setAccountId(param.accountId);
  req.setCorrespondent(param.correspondent);
  req.setOffice(param.office);
  req.setAccountNo(param.accountNo);
  req.setAcquiredDate(stringToProtoDate(param.acquiredDate));
  req.setSymbolId(param.symbolId);
  req.setSymbol(param.symbol);
  req.setCusip(param.cusip);
  req.setAssetType(param.assetType);
  req.setQty(param.qty);
  req.setPrice(param.price);
  req.setValue(param.value);
  return req;
};

export async function createBoyCost(param) {
  return new Promise((resolve, reject) => {
    const req = requestBoyCostParameter(param);
    service.createBoyCost(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateBoyCost(param) {
  return new Promise((resolve, reject) => {
    const req = requestBoyCostParameter(param);
    service.updateBoyCost(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listBoyCost(param) {
  return new Promise((resolve, reject) => {
    const req = requestBoyCostParameter(param);

    service.listBoyCost(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function readBoyCost(param) {
  return new Promise((resolve, reject) => {
    const req = new ReadBoyCostRequest();
    req.setBoyCostId(param.boyCostId);

    service.readBoyCost(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteBoyCost(param) {
  return new Promise((resolve, reject) => {
    const req = new DeleteBoyCostRequest();
    req.setBoyCostId(param.boyCostId);

    service.deleteBoyCost(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function importBoyCost(param) {
  return new Promise((resolve, reject) => {
    console.log(param);
    let req = new BoyCost();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setOffice(param.office);
    req.setAccountNo(param.accountNo);
    req.setAcquiredDate(stringToProtoDate(param.acquiredDate));
    req.setSymbol(param.symbol);
    req.setQty(param.qty);
    req.setPrice(param.price);
    req.setValue(param.value);
    service.importBoyCost(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export const convertDataToBoyCostModel = (d) => {
  return {
    boyCostId: 0,
    year: d.Year ? d.Year : '',
    correspondent: d.Correspondent ? d.Correspondent : '',
    office: d.Office ? d.Office : '',
    accountNo: d.AccountNo ? d.AccountNo : '',
    acquiredDate: dateStringToDateSTD(d.AcquiredDate),
    symbol: d.Symbol ? d.Symbol : '',
    qty: d.QTY ? d.QTY : '0',
    price: d.Price ? d.Price : '0',
    value: d.Value ? d.Value : '0',
  };
};

export const isValidData = (d) => {
  let error = '';
  if (!d.hasOwnProperty('Year')) {
    error = error.concat('Year field missing  ');
  } else {
    if (d.Year.length > 4) {
      error = error.concat('Year value exceed to 4 characters ');
    }
  }

  if (!d.hasOwnProperty('Correspondent')) {
    error = error.concat('Correspondent field missing  ');
  } else {
    if (d.Correspondent.length > 4) {
      error = error.concat('Correspondent value exceed to 4 characters ');
    }
  }

  if (!d.hasOwnProperty('Office')) {
    error = error.concat('Office field missing  ');
  } else {
    if (d.Office.length > 3) {
      error = error.concat('Office value exceed to 3 characters ');
    }
  }

  if (!d.hasOwnProperty('AccountNo')) {
    error = error.concat('AccountNo field missing  ');
  } else {
    if (d.AccountNo.length > 12) {
      error = error.concat('AccountNo value exceed to 12 characters ');
    }
  }

  if (!d.hasOwnProperty('AcquiredDate')) {
    error = error.concat('AcquiredDate field missing  ');
  } else {
    let date = new Date(d.AcquiredDate);
    if (!(date instanceof Date && !isNaN(date.valueOf()))) {
      error = error.concat('AcquiredDate format invalid');
    }
  }
  if (!d.hasOwnProperty('Symbol')) {
    error = error.concat('Symbol field missing  ');
  } else {
    if (d.Symbol.length > 30) {
      error = error.concat('Symbol value exceed to 30 characters ');
    }
  }

  if (!d.hasOwnProperty('QTY')) {
    error = error.concat(', QTY field missing  ');
  }

  let year = parseInt(d.Year.toString());
  if (year.toString().length < 4 || year.toString().length > 4) {
    error = error.concat('(Year:' + year + ') invalid range  ');
  }

  let qty = parseInt(d.QTY.toString());
  if (!qty && qty == 0) {
    error = error.concat('QTY:' + d.QTY + ') invalid value  ');
  }

  if (!d.hasOwnProperty('Price')) {
    error = error.concat(', Price field missing  ');
  } else {
    let price = parseFloat(d.Price.toString());
    if (isNaN(price)) {
      error = error.concat('(Price:' + d.Price + ') invalid value  ');
    } else {
      if (!(price.toString() === d.Price.toString())) {
        error = error.concat('(Price:' + d.Price + ') invalid value  ');
      }
    }
  }

  if (!d.hasOwnProperty('Value')) {
    error = error.concat(', Value field missing  ');
  } else {
    let value = parseFloat(d.Value.toString());
    if (isNaN(value)) {
      error = error.concat('(Value:' + d.Value + ') invalid value  ');
    } else {
      if (!(value.toString() === d.Value.toString())) {
        error = error.concat('(Value:' + d.Value + ') invalid value  ');
      }
    }
  }

  return error;
};
