/*ReactJS*/
import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';
import CountriesSelect from '../../../components/AutoComplete/Countries';

import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  InputLabel,
} from '@material-ui/core';

export default function AccountModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({});
  const [isEdit, setIsEdit] = React.useState(false);

  useEffect(() => {
    if (isOpen) {
      setModalData({ ...value });
      if (value.taxTreatyId) {
        setIsEdit(true);
      }
    }
  }, [isOpen, value]);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalMd}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
            {isEdit ? 'Edit' : 'Add New'} TAX Treaty
          </Typography>
          <Box mt={5}>

            <form noValidate autoComplete="off">
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="year"
                  label="Year *"
                  type="number"
                  inputProps={{
                    min: 2000,
                    max: 3000,
                  }}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={modalData.year}
                  onChange={handleChange}
                />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel id="taxCountry" shrink>
                      Tax Country *
                    </InputLabel>
                    <CountriesSelect
                      labelid="taxCountry"
                      fullWidth
                      required={false}
                      name="taxCountry"
                      value={modalData.taxCountry}
                      onChange={handleChange}
                    />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                    name="taxRate"
                    required
                    label="Tax Rate"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.taxRate}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={classes.modalFooter}>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div className={classes.grdCellNone}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => {
                      handleClose(modalData, isEdit);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
