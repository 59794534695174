/*ReactJS*/
import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';

/*Material-ui*/
import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import CountriesSelect from '../../../components/AutoComplete/Countries';
import TaxCountriesSelect from '../../../components/AutoComplete/TaxCountries';
import LegalEntitiesSelect from '../../../components/AutoComplete/LegalEntities';
export default function AccountUploadEditModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (isOpen) {
      setModalData({ ...value });
    }
  }, [isOpen, value]);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalFull}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
            Edit Account
          </Typography>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="year"
                    label="Year"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.year}
                    inputProps={{
                      min: 2000,
                      max: 3000,
                    }}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="externalId"
                    fullWidth
                    label="External ID"
                    value={modalData.externalId}
                    inputProps={{
                      maxLength: 50,
                    }}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="correspondent"
                    required
                    label="Correspondent"
                    inputProps={{
                      maxLength: 4,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.correspondent}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="office"
                    required
                    label="Office"
                    inputProps={{
                      maxLength: 3,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.office}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1}>
                  <TextField
                    name="accountNo"
                    required
                    label="Account No"
                    inputProps={{
                      maxLength: 12,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.accountNo}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="fullName"
                    required
                    label="Full Name"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.fullName}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="companyName"
                    required
                    label="Company Name"
                    inputProps={{
                      maxLength: 300,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.companyName}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="w8W9"
                    required
                    label="W8/W9"
                    inputProps={{
                      maxLength: 20,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.w8W9}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <InputLabel id="legalEntity" shrink>
                    Legal Entity *
                  </InputLabel>
                  <LegalEntitiesSelect
                    labelid="legalEntity"
                    fullWidth
                    required={false}
                    name="legalEntity"
                    value={modalData.legalEntity}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1}>
                  <TextField
                    name="governmentId"
                    label="Government ID"
                    fullWidth
                    inputProps={{
                      maxLength: 20,
                    }}
                    InputLabelProps={{ shrink: true }}
                    value={modalData.governmentId}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="address1"
                    required
                    inputProps={{
                      maxLength: 150,
                    }}
                    label="Address 1"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.address1}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="address2"
                    required
                    label="Address 2"
                    inputProps={{
                      maxLength: 150,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.address2}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="address3"
                    required
                    label="Address 3"
                    inputProps={{
                      maxLength: 150,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.address3}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="city"
                    required
                    label="City"
                    inputProps={{
                      maxLength: 35,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.city}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1}>
                  <TextField
                    name="state"
                    label="State"
                    inputProps={{
                      maxLength: 2,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.state}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="zip"
                    label="Zip"
                    inputProps={{
                      maxLength: 30,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.zip}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <InputLabel id="country" shrink>
                    Country *
                  </InputLabel>
                  <CountriesSelect
                    labelid="country"
                    fullWidth
                    required={false}
                    name="country"
                    value={modalData.country}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <InputLabel id="taxCountry" shrink>
                    Tax Country *
                  </InputLabel>
                  <TaxCountriesSelect
                    labelid="Tax Country"
                    fullWidth
                    name="taxCountry"
                    value={modalData.taxCountry}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <InputLabel shrink>1099B Calc Type</InputLabel>
                  <Select
                    required
                    name="calcType"
                    displayEmpty
                    fullWidth
                    value={modalData.calcType}
                    onChange={handleChange}
                  >
                    <MenuItem value="AVG">AVG</MenuItem>
                    <MenuItem value="FIFO">FIFO</MenuItem>
                  </Select>
                </div>
                <div className={classes.grdCell1}></div>
              </div>
              <div className={classes.modalFooter}>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div className={classes.grdCellNone}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => {
                      handleClose(modalData);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
