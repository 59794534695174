import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import { ReactComponent as Valid } from '../../images/check.svg';
import { ReactComponent as Invalid } from '../../images/x-out.svg';

const useStyles = makeStyles(() => ({
  passwordRequirementWrapper: {
    marginTop: 25,
    marginBottom: 10,
    fontSize: 14,
    color: '#6b757f',
    '& label': {
      fontWeight: 700,
      color: '#dce3ea',
    },
  },
  passwordRequirementList: {
    display: 'flex',
    '& div:first-child': {
      flex: 'none',
      marginRight: 30,
    },
    '& div': {
      flex: 1,
    },
    '& ul': {
      marginLeft: -40,
      lineHeight: '21px',
      listStyle: 'none',
      '& li > svg': {
        width: 18,
        height: 18,
        position: 'relative',
        top: 5,
      },
    },
  },
  valid: {
    '& path': {
      fill: '#4ce13c',
    },
  },
  invalid: {
    '& path': {
      fill: '#f73f3f',
    },
  },
}));

const PasswordRequirement = (props) => {
  const classes = useStyles();

  const { password } = props;

  const regex = {
    hasNumber: /\d/,
    hasWhiteSpace: /\s/,
    hasSpecialChars: /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/,
    hasLower: /[a-z]/,
    hasUpper: /[A-Z]/,
  };

  return (
    <Zoom in={true}>
      <div className={classes.passwordRequirementWrapper}>
        <label>Password Requirements:</label>
        <div className={classes.passwordRequirementList}>
          <div>
            <ul>
              <li>
                {regex.hasLower.test(password) ? (
                  <Valid className={classes.valid} />
                ) : (
                  <Invalid className={classes.invalid} />
                )}{' '}
                At least one lowercase letter [a-z]
              </li>
              <li>
                {regex.hasUpper.test(password) ? (
                  <Valid className={classes.valid} />
                ) : (
                  <Invalid className={classes.invalid} />
                )}{' '}
                At least one uppercase letter [A-Z]
              </li>
              <li>
                {regex.hasSpecialChars.test(password) ? (
                  <Valid className={classes.valid} />
                ) : (
                  <Invalid className={classes.invalid} />
                )}{' '}
                Allowed special characters: !@#$%-_
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                {regex.hasNumber.test(password) ? (
                  <Valid className={classes.valid} />
                ) : (
                  <Invalid className={classes.invalid} />
                )}{' '}
                At least one number
              </li>
              <li>
                {password.length >= 8 ? (
                  <Valid className={classes.valid} />
                ) : (
                  <Invalid className={classes.invalid} />
                )}{' '}
                Minimum of 8 characters
              </li>
              <li>
                {!regex.hasWhiteSpace.test(password) ? (
                  <Valid className={classes.valid} />
                ) : (
                  <Invalid className={classes.invalid} />
                )}{' '}
                Cannot contain white spaces
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Zoom>
  );
};
export default PasswordRequirement;
