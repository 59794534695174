import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AttachFile as Paperclip,
  Search as SearchIcon,
  FilterList as FilterListIcon,
  Add as AddIcon,
  Loop as LoopIcon,
  CompareArrowsRounded as CompareIcon,
  AddAPhoto as AddAPhotoIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import {
  Button as MUIButton,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';

const Button = (props) => {
  const useStyles = makeStyles(() => ({
    button: {
      '&.MuiButton-containedPrimary': {
        backgroundColor: props.color === 'primary' ? '#181b1e' : '#ff0000',
        color: '#ffffff',
      },
      '&.Mui-disabled': {
        backgroundColor: '#d5d5d5 !important',
        color: '#7e7e7e',
        '& span > span > .MuiCircularProgress-colorPrimary': {
          color: '#1e7dff',
          height: '20px !important',
          width: '20px !important',
        },
      },
    },
  }));

  const classes = useStyles();

  const StartIcon = (button) => {
    if (button.type === 'import') {
      return props.loading ? <CircularProgress /> : <Paperclip />;
    } else if (button.type === 'search') {
      return props.loading ? <CircularProgress /> : <SearchIcon />;
    } else if (button.type === 'filter') {
      return <FilterListIcon />;
    } else if (button.type === 'photo') {
      return <AddAPhotoIcon />;
    } else if (button.type === 'add') {
      return <AddIcon />;
    } else if (button.type === 'recurring') {
      return <LoopIcon />;
    } else if (button.type === 'transfer') {
      return <CompareIcon />;
    } else if (button.type === 'hideShow') {
      return props.showZeroValues ? <VisibilityIcon /> : <VisibilityOffIcon />;
    } else {
      return <CircularProgress />;
    }
  };

  return (
    <Tooltip title={props.tooltip ? props.tooltip : ''} arrow>
      <div>
        <MUIButton
          style={{ marginTop: props.mt ? 20 : 0 }}
          className={classes.button}
          fullWidth={true}
          disabled={props.disabled}
          name={props.name}
          onClick={props.onClick}
          startIcon={
            props.loading || props.type ? (
              <StartIcon type={props.type} />
            ) : (
              null || ''
            )
          }
          color={props.color}
          component={props.component}
          variant="contained"
          size="large"
        >
          {props.label}
        </MUIButton>
      </div>
    </Tooltip>
  );
};
export default Button;
