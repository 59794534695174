/*ReactJS*/
import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';

import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
} from '@material-ui/core';
import { dateProtoObjectToString } from '../../../services/ConvertService';

export default function Form1099DivReclassUpload({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({});
  const [isEdit, setIsEdit] = React.useState(false);

  useEffect(() => {
    if (isOpen) {
      setModalData({ 
          ...value,
          recordDate: typeof(value.recordDate) === 'object' ? dateProtoObjectToString(value.recordDate) : value.recordDate,
          paymentDate: typeof(value.recordDate) === 'object' ? dateProtoObjectToString(value.paymentDate) : value.paymentDate,
      });
      if (value.id) {
        setIsEdit(true);
      }
    }
  }, [isOpen, value]);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalFull}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
            {isEdit ? 'Edit' : 'Add New'} Form 1099 Div Reclass
          </Typography>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    name="year"
                    label="Year"
                    type="number"
                    onChange={handleChange}
                    value={modalData.year}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      min: 2000,
                      max: 3000,
                    }}
                    
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    name="cusip"
                    label="Cusip"
                    onChange={handleChange}
                    value={modalData.cusip}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    name="symbol"
                    label="Symbol"
                    onChange={handleChange}
                    value={modalData.symbol}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    name="recordDate"
                    label="Record Date"
                    type="date"
                    value={modalData.recordDate}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1}>
                  <TextField
                    fullWidth
                    name="paymentDate"
                    label="Payment Date"
                    type="date"
                    value={modalData.paymentDate}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    name="divQualified"
                    label="Div Qualified"
                    type="number"
                    onChange={handleChange}
                    value={modalData.divQualified}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    name="divNonQualified"
                    label="Div Non Qualified"
                    type="number"
                    onChange={handleChange}
                    value={modalData.divNonQualified}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    type="number"
                    name="foreignTaxQualified"
                    label="Foreign Tax Qualified"
                    onChange={handleChange}
                    value={modalData.foreignTaxQualified}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    type="number"
                    name="foreignTaxNonQualified"
                    label="Foreign Tax Non Qualified"
                    onChange={handleChange}
                    value={modalData.foreignTaxNonQualified}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1}>
                  <TextField
                    fullWidth
                    type="number"
                    name="stGainQualified"
                    label="Short Term Gain Qualified"
                    onChange={handleChange}
                    value={modalData.stGainQualified}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    type="number"
                    name="stGainNonQualified"
                    label="Short Term Gain Non Qualified"
                    onChange={handleChange}
                    value={modalData.stGainNonQualified}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    type="number"
                    name="ltCapitalGain"
                    label="Long Term Capital Gain"
                    onChange={handleChange}
                    value={modalData.ltCapitalGain}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    type="number"
                    name="rcap"
                    label="RCAP"
                    onChange={handleChange}
                    value={modalData.rcap}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    type="number"
                    name="unrecapturedTwelvefiftyGain"
                    label="Unrecaptured 1250 Gain"
                    onChange={handleChange}
                    value={modalData.unrecapturedTwelvefiftyGain}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1}>
                  <TextField
                    fullWidth
                    type="number"
                    name="defPymtAmount"
                    label="Def Pymt Amount"
                    onChange={handleChange}
                    value={modalData.defPymtAmount}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    type="number"
                    name="cashLiquidation"
                    label="Cash Liquidation"
                    onChange={handleChange}
                    value={modalData.cashLiquidation}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    type="number"
                    name="nonCashLiquidation"
                    label="Non Cash Liquidation"
                    onChange={handleChange}
                    value={modalData.nonCashLiquidation}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    type="number"
                    name="sectionTwelvezerotwoGain"
                    label="Section 1202 Gain"
                    onChange={handleChange}
                    value={modalData.sectionTwelvezerotwoGain}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    type="number"
                    name="collectibleTwentyeightGain"
                    label="Collectible 28 Gain"
                    onChange={handleChange}
                    value={modalData.collectibleTwentyeightGain}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1}>
                  <TextField
                    fullWidth
                    type="number"
                    name="intt"
                    label="Int"
                    onChange={handleChange}
                    value={modalData.intt}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    type="number"
                    name="adrFee"
                    label="Adr Fee"
                    onChange={handleChange}
                    value={modalData.adrFee}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    type="number"
                    name="exemptInterestDiv"
                    label="Exempt Interest Div"
                    onChange={handleChange}
                    value={modalData.exemptInterestDiv}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    type="number"
                    name="altMinTax"
                    label="Alt Min Tax"
                    onChange={handleChange}
                    value={modalData.altMinTax}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    type="number"
                    name="divNineteenninea"
                    label="Div 199a"
                    onChange={handleChange}
                    value={modalData.divNineteenninea}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.grdCell1}>
                  <TextField
                    fullWidth
                    type="number"
                    name="grossProceeds"
                    label="Gross Proceeds"
                    onChange={handleChange}
                    value={modalData.grossProceeds}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
              </div>           
              <div className={classes.modalFooter}>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div className={classes.grdCellNone}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => {
                      handleClose(modalData, isEdit);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
