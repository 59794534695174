/**
 * @fileoverview gRPC-Web generated client stub for usrpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.usrpb = require('./administrator_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.usrpb.AdministratorServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.usrpb.AdministratorServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.ListAdministratorRequest,
 *   !proto.usrpb.ListAdministratorResponse>}
 */
const methodDescriptor_AdministratorService_ListAdministrator = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/ListAdministrator',
  grpc.web.MethodType.UNARY,
  proto.usrpb.ListAdministratorRequest,
  proto.usrpb.ListAdministratorResponse,
  /**
   * @param {!proto.usrpb.ListAdministratorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ListAdministratorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usrpb.ListAdministratorRequest,
 *   !proto.usrpb.ListAdministratorResponse>}
 */
const methodInfo_AdministratorService_ListAdministrator = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usrpb.ListAdministratorResponse,
  /**
   * @param {!proto.usrpb.ListAdministratorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ListAdministratorResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.ListAdministratorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usrpb.ListAdministratorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.ListAdministratorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.listAdministrator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/ListAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_ListAdministrator,
      callback);
};


/**
 * @param {!proto.usrpb.ListAdministratorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.ListAdministratorResponse>}
 *     A native promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.listAdministrator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/ListAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_ListAdministrator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.Administrator,
 *   !proto.usrpb.CreateAdministratorResponse>}
 */
const methodDescriptor_AdministratorService_CreateAdministrator = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/CreateAdministrator',
  grpc.web.MethodType.UNARY,
  proto.usrpb.Administrator,
  proto.usrpb.CreateAdministratorResponse,
  /**
   * @param {!proto.usrpb.Administrator} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.CreateAdministratorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usrpb.Administrator,
 *   !proto.usrpb.CreateAdministratorResponse>}
 */
const methodInfo_AdministratorService_CreateAdministrator = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usrpb.CreateAdministratorResponse,
  /**
   * @param {!proto.usrpb.Administrator} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.CreateAdministratorResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.Administrator} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usrpb.CreateAdministratorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.CreateAdministratorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.createAdministrator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/CreateAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_CreateAdministrator,
      callback);
};


/**
 * @param {!proto.usrpb.Administrator} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.CreateAdministratorResponse>}
 *     A native promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.createAdministrator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/CreateAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_CreateAdministrator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.Administrator,
 *   !proto.usrpb.UpdateAdministratorResponse>}
 */
const methodDescriptor_AdministratorService_UpdateAdministrator = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/UpdateAdministrator',
  grpc.web.MethodType.UNARY,
  proto.usrpb.Administrator,
  proto.usrpb.UpdateAdministratorResponse,
  /**
   * @param {!proto.usrpb.Administrator} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.UpdateAdministratorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usrpb.Administrator,
 *   !proto.usrpb.UpdateAdministratorResponse>}
 */
const methodInfo_AdministratorService_UpdateAdministrator = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usrpb.UpdateAdministratorResponse,
  /**
   * @param {!proto.usrpb.Administrator} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.UpdateAdministratorResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.Administrator} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usrpb.UpdateAdministratorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.UpdateAdministratorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.updateAdministrator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/UpdateAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_UpdateAdministrator,
      callback);
};


/**
 * @param {!proto.usrpb.Administrator} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.UpdateAdministratorResponse>}
 *     A native promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.updateAdministrator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/UpdateAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_UpdateAdministrator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.ReadAdministratorRequest,
 *   !proto.usrpb.ReadAdministratorResponse>}
 */
const methodDescriptor_AdministratorService_ReadAdministrator = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/ReadAdministrator',
  grpc.web.MethodType.UNARY,
  proto.usrpb.ReadAdministratorRequest,
  proto.usrpb.ReadAdministratorResponse,
  /**
   * @param {!proto.usrpb.ReadAdministratorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ReadAdministratorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usrpb.ReadAdministratorRequest,
 *   !proto.usrpb.ReadAdministratorResponse>}
 */
const methodInfo_AdministratorService_ReadAdministrator = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usrpb.ReadAdministratorResponse,
  /**
   * @param {!proto.usrpb.ReadAdministratorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ReadAdministratorResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.ReadAdministratorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usrpb.ReadAdministratorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.ReadAdministratorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.readAdministrator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/ReadAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_ReadAdministrator,
      callback);
};


/**
 * @param {!proto.usrpb.ReadAdministratorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.ReadAdministratorResponse>}
 *     A native promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.readAdministrator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/ReadAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_ReadAdministrator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.DeleteAdministratorRequest,
 *   !proto.usrpb.DeleteAdministratorResponse>}
 */
const methodDescriptor_AdministratorService_DeleteAdministrator = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/DeleteAdministrator',
  grpc.web.MethodType.UNARY,
  proto.usrpb.DeleteAdministratorRequest,
  proto.usrpb.DeleteAdministratorResponse,
  /**
   * @param {!proto.usrpb.DeleteAdministratorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.DeleteAdministratorResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usrpb.DeleteAdministratorRequest,
 *   !proto.usrpb.DeleteAdministratorResponse>}
 */
const methodInfo_AdministratorService_DeleteAdministrator = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usrpb.DeleteAdministratorResponse,
  /**
   * @param {!proto.usrpb.DeleteAdministratorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.DeleteAdministratorResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.DeleteAdministratorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usrpb.DeleteAdministratorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.DeleteAdministratorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.deleteAdministrator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/DeleteAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_DeleteAdministrator,
      callback);
};


/**
 * @param {!proto.usrpb.DeleteAdministratorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.DeleteAdministratorResponse>}
 *     A native promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.deleteAdministrator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/DeleteAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_DeleteAdministrator);
};


module.exports = proto.usrpb;

