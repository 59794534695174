/*eslint eqeqeq: "off"*/
import {
    TaxTreatyServiceClient,
    TaxTreaty,
    ReadTaxTreatyRequest,
    } from '../proto/taxfilibpb/tax_treaty_grpc_web_pb';
    import { notifyError } from 'components/Notification/Notification';
    import { auth } from '../lib/auth/Auth'

//   rpc CreateTaxTreaty(TaxTreaty) returns (TaxTreatyDetailsResponse);
//   rpc UpdateTaxTreaty(TaxTreaty) returns (TaxTreatyDetailsResponse);
//   rpc ReadTaxTreaty(ReadTaxTreatyRequest) returns (TaxTreatyDetailsResponse);
//   rpc DeleteTaxTreaty(ReadTaxTreatyRequest) returns (TaxTreatyDetailsResponse);
//   rpc ListTaxTreaty(TaxTreaty) returns (ListTaxTreatyResponse);

    const service = new TaxTreatyServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

    const requestTaxTreatyParameter = (param) => {
      let req = new TaxTreaty();
      req.setTaxTreatyId(param.taxTreatyId);
      req.setYear(param.year);
      req.setTaxCountry(param.taxCountry);
      req.setTaxRate(param.taxRate);
      return req;
    };

  
  export async function createTaxTreaty(param) {
      return new Promise((resolve, reject) => {
        const req = requestTaxTreatyParameter(param);
        service.createTaxTreaty(req, {}, (error, response) => {
          if (error) {
            notifyError(error.message);
            reject(error);
          } else {
            resolve(response.toObject());
          }
        });
      });
    }
    
    export async function updateTaxTreaty(param) {
      return new Promise((resolve, reject) => {
        const req = requestTaxTreatyParameter(param);
        service.updateTaxTreaty(req, {}, (error, response) => {
          if (error) {
            notifyError(error.message);
            reject(error);
          } else {
            resolve(response.toObject());
          }
        });
      });
    }

    export async function listTaxTreaty(param) {
      return new Promise((resolve, reject) => {
        const req = requestTaxTreatyParameter(param);
        service.listTaxTreaty(req, {}, (error, response) => {
          if (error) {
            notifyError(error.message);
            reject(error);
          } else {
            resolve(response.toObject());
          }
        });
      });
    }
  

    export async function readTaxTreaty(param) {
      return new Promise((resolve, reject) => {
        const req = new ReadTaxTreatyRequest();
        req.setTaxTreatyId(param.taxTreatyId);
        service.readTaxTreaty(req, {}, (error, response) => {
          if (error) {
            notifyError(error.message);
            reject(error);
          } else {
            resolve(response.toObject());
          }
        });
      });
    }
  
    export async function deleteTaxTreaty(param) {
      return new Promise((resolve, reject) => {
        const req = new ReadTaxTreatyRequest();
        req.setTaxTreatyId(param.taxTreatyId);
        service.deleteTaxTreaty(req, {}, (error, response) => {
          if (error) {
            notifyError(error.message);
            reject(error);
          } else {
            resolve(response.toObject());
          }
        });
      });
    }



  


//   rpc CreateTaxTreaty(TaxTreaty) returns (TaxTreatyDetailsResponse);
//   rpc UpdateTaxTreaty(TaxTreaty) returns (TaxTreatyDetailsResponse);
//   rpc ReadTaxTreaty(ReadTaxTreatyRequest) returns (TaxTreatyDetailsResponse);
//   rpc DeleteTaxTreaty(ReadTaxTreatyRequest) returns (TaxTreatyDetailsResponse);
//   rpc ListTaxTreaty(TaxTreaty) returns (ListTaxTreatyResponse);