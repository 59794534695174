/*eslint eqeqeq: "off"*/
import React, { useState } from 'react';
import useStyles from '../../../styles';
import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
} from '@material-ui/core';

import {
  notifyError,
} from 'components/Notification/Notification';

export default function ProcessModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({
    type: '',
    year: 2020,
    fromDate: '',
    toDate:'',
  });
  const [minDate,setMinDate] = useState('');
  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
   
    if(input.name === "fromDate"){
      setMinDate(input.value);
      setModalData({
        ...modalData,
        toDate: "",
        [input.name]: input.value,
      });
    }

  };

  const checkDinvraTypeDateFromAndTo =() =>{
   if(modalData.type == "dinvra"){
    let error = "";
     if(modalData.fromDate == ''){
      error  = error.concat('From Date is Required');
     }
     if(modalData.toDate == ''){
       if (error != ""){
        error  = error.concat(' And ');
       }
       error  = error.concat('To Date is Required');
    }
    if (error != ""){
      notifyError(error);
      return false;
     }
   }
   return true;
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalMd}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
            Process Forms
            {value.length > 1
              ? ' - Selected Items(' + value.length + ')'
              : !value.length
              ? ''
              : ' - Year ' +
                value[0].year +
                ' Account ' +
                value[0].correspondent +
                '-' +
                value[0].office +
                '-' +
                value[0].accountNo}
          </Typography>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <InputLabel shrink>Form Type: *</InputLabel>
                  <Select
                    name="type"
                    required
                    displayEmpty
                    fullWidth
                    value={modalData.type}
                    onChange={handleChange}
                  >
                    {/* <MenuItem value="divnra">1042 DIVNRA</MenuItem> */}
                    <MenuItem value="b">1099 B</MenuItem>
                    <MenuItem value="int">1099 INT</MenuItem>
                    <MenuItem value="div">1099 DIV</MenuItem>
                    <MenuItem value="misc">1099 MISC</MenuItem>
                    <MenuItem value="dinvra">1042 DIVNRA</MenuItem>
                  </Select>
                </div>
                {(!value.length && modalData.type !="dinvra") && (
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      name="year"
                      required
                      label="Year"
                      type="number"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 4 }}
                      value={modalData.year}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </div>
              {modalData.type =="dinvra" && (
              <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="fromDate"
                  fullWidth
                  label="From Date"
                  type="date"
                  required
                  value={modalData.fromDate}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="toDate"
                  fullWidth
                  label="To Date"
                  type="date"
                  inputProps={{
                    min : minDate,
                  }}
                  value={modalData.toDate}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
            )}
              <div className={classes.modalFooter}>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div className={classes.grdCellNone}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => {
                      if (checkDinvraTypeDateFromAndTo()){
                        handleClose(modalData, value, true);
                      }
                   
                    }}
                  >
                    Process
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
