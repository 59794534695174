import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, List } from '@material-ui/core';
import {
  SupervisorAccount as SupervisorAccountIcon,
  ArrowBack as ArrowBackIcon,
  HowToVote as HowToVoteIcon,
  Iso as IsoIcon,
} from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

// styles
import useStyles from './styles';

// components
import SidebarLink from './components/SidebarLink/SidebarLink';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';

const structure = [
  {
    id: 0,
    label: 'Admin',
    link: '/app/home',
    icon: <SupervisorAccountIcon />,
    children: [
      {
        type: 'sub label',
        label: 'User Management',
        link: '/app/user-management',
      },
      {
        type: 'link',
        label: 'Administrator',
        link: '/app/user-management/administrator',
      },
      { type: 'sub label', label: 'Account Setup', link: '/app/account-setup' },
      {
        type: 'link',
        label: 'Account',
        link: '/app/account-setup/account',
      },
      {
        type: 'link',
        label: 'TIN Validation',
        link: '/app/account-setup/tin-validation',
      },

      {
        type: 'link',
        label: 'Tax Treaty',
        link: '/app/account-setup/tax-treaty',
      },
      {
        type: 'sub label',
        label: 'System Profile',
        link: '/app/system-profile',
      },
      {
        type: 'link',
        label: 'User Guide',
        link: '/app/system-profile/user-guide',
      },
      {
        type: 'link',
        label: 'Profile',
        link: '/app/system-profile/profile',
      },
    ],
  },
  {
    id: 1,
    label: 'Tax Filing',
    link: '/app/home',
    icon: <IsoIcon />,
    children: [
      {
        type: 'link',
        label: 'Form 1042 DIVNRA',
        link: '/app/tax-filing/form-1042-divnra',
      },

      {
        type: 'link',
        label: 'Form 1099 B',
        link: '/app/tax-filing/form-1099-b',
      },
      {
        type: 'link',
        label: 'Form 1099 INT',
        link: '/app/tax-filing/form-1099-int',
      },
      {
        type: 'link',
        label: 'Form 1099 DIV',
        link: '/app/tax-filing/form-1099-div',
      },
      {
        type: 'link',
        label: 'Form 1099 DIV Reclass',
        link: '/app/tax-filing/form-1099-reclass',
      },
      {
        type: 'link',
        label: 'Form 1099 MISC',
        link: '/app/tax-filing/form-1099-misc',
      },
    ],
  },
  {
    id: 2,
    label: 'Transaction',
    link: '/app/home',
    icon: <HowToVoteIcon />,
    children: [
      {
        type: 'link',
        label: 'Beginning of the Year',
        link: '/app/transaction/boy-cost',
      },
      { type: 'link', label: 'TRNS', link: '/app/transaction/trns' },
      { type: 'link', label: 'Process', link: '/app/transaction/process' },
    ],
  },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
