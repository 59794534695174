 /*eslint eqeqeq: "off"*/
 import * as XLSX from "xlsx";

 export const readExcelData = (file) => {
let promise = new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      let bufferArray = e.target.result;
      if(bufferArray){
        let wb = XLSX.read(bufferArray,{type:'buffer',cellDates:true,cellText:false});
        let wsname = wb.SheetNames[0];
        let ws = wb.Sheets[wsname];
        let data = XLSX.utils.sheet_to_json(ws, {raw:false,dateNF:'yyyy-mm-dd'});
        resolve(data);
      }
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
  return promise;
}


export const getFilesInfo = (file) => {
    let promise = new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
          let bufferArray = e.target.result;
          if(bufferArray){
            const wb = XLSX.read(bufferArray,{type:'buffer',cellDates:true,cellText:false});
            let cusipSheets =  wb.SheetNames.filter(name=> !name.includes("Sheet") && !name.includes("sheet"));
            resolve({
              fileName:file.name,
              status:"",
              sheetCounts: cusipSheets.length,
              bytes:bufferArray,
             });
          }
        };
        fileReader.onerror = (error) => {
          reject(error);
          };
        });
      return promise;
  }