/*ReactJS*/
import React, { useState } from 'react';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

import { listProfile, updateProfile } from '../../../services/ProfileService';

/*Material UI Components*/
import { Box, TextField, IconButton } from '@material-ui/core';

/*Material UI Icons*/
import { Create as EditIcon } from '@material-ui/icons';

import Table, { columnType } from 'components/Table/Table';
import ProfileModal from './ProfileModal';

/*Styles*/
import useStyles from '../../../styles';

/*Custom components*/
import SearchButton from '../../../components/Button/Search';
import QueryParam from '../../../services/QueryParamService';

export default function ProfileTable({ params }) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();

  const options = {
    selectableRows: 'none',
  };
  /*=========================================================================================
  End of component style
  ===========================================================================================*/

  /*=========================================================================================
  Table modifications
  ===========================================================================================*/
  const columns = [
    {
      name: '',
      type: columnType.buttons,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div
              align={'left'}
              className={classes.grdRow}
              style={{ margin: 0, padding: 0 }}
            >
              <div align={'left'} className={classes.grdCellNone}>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleOpen(rows[dataIndex])}
                >
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          );
        },
        setCellProps: () => ({
          style: { padding: '4px 16px' },
        }),
      },
    },
    {
      name: 'systemDate',
      label: 'System Date',
      type: columnType.date,
    },
    {
      name: 'tradeDate',
      label: 'Trade Date',
      type: columnType.date,
    },
    {
      name: 'settleDate',
      label: 'Settle Date',
      type: columnType.date,
    },
    {
      name: 'previousDate',
      label: 'Previous Date',
      type: columnType.date,
    },
    {
      name: 'baseCurrency',
      label: 'Base Currency',
    },
    {
      name: 'versionNo',
      label: 'Version No',
    },
    {
      name: 'photo',
      label: 'Logo',
      options: {
        customBodyRenderLite: (dataIndex) => {
          return rows[dataIndex].photo ? (
            <img
              style={{ width: 100 }}
              src={rows[dataIndex].photo}
              alt="logo"
            />
          ) : (
            ''
          );
        },
      },
    },
    {
      name: 'companyName',
      label: 'Company Name',
    },
    {
      name: 'companyTin',
      label: 'Company TIN',
    },
    {
      name: 'telNo',
      label: 'Telephone No',
    },
    {
      name: 'address',
      label: 'Address',
    },
    {
      name: 'city',
      label: 'City',
    },
    {
      name: 'state',
      label: 'State',
    },
    {
      name: 'zip',
      label: 'Zip',
    },
    {
      name: 'country',
      label: 'Country',
    },
  ];

  /*=========================================================================================
  End of table modifications
  ===========================================================================================*/
  const [rowData, setRowData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [rows, setRows] = useState([]);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      systemDate: '',
      tradeDate: '',
      settleDate: '',
      previousDate: '',
      baseCurrency: '',
      versionNo: '',
    })
  );

  const handleChange = (e, x) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({
      ...searchData,
      [input.name]: input.value,
    });
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      QueryParam.set(searchData);
      const data = await listProfile(searchData);
      setRows(data.profilesList);
      notifyInfo(data.profilesList.length + ' search results.');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = (data) => {
    setRowData(data);
    setOpen(true);
  };

  const handleClose = async (data) => {
    if (!data) {
      setOpen(false);
      return;
    }

    try {
      let valid = true;

      if (!data.photo) {
        notifyError('Logo is required.');
        valid = false;
      }
      if (!data.companyName) {
        notifyError('Company Name is required.');
        valid = false;
      }
      if (!data.companyTin) {
        notifyError('Company TIN is required.');
        valid = false;
      }
      if (data.companyTin && data.companyTin.replace('_', '').length < 10) {
        notifyError('Company TIN should be 10 characters.');
        valid = false;
      }
      if (!data.telNo) {
        notifyError('Telephone No. is required.');
        valid = false;
      }
      if (data.telNo && data.telNo.replaceAll('_', '').length < 10) {
        notifyError('Telephone No. should be 10 digits.');
        valid = false;
      }
      if (!data.address) {
        notifyError('Address is required.');
        valid = false;
      }
      if (!data.city) {
        notifyError('City is required.');
        valid = false;
      }
      if (!data.state) {
        notifyError('State is required.');
        valid = false;
      }
      if (!data.country) {
        notifyError('Country is required.');
        valid = false;
      }

      if (!valid) {
        return;
      }

      const resp = await updateProfile(data);
      const rowsCopy = [...rows];
      const index = rows.indexOf(rowData);
      rowsCopy[index] = resp.profile;
      setRows(rowsCopy);
      notifySuccess('Profile has been updated.');

      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="systemDate"
                  fullWidth
                  label="System Date"
                  type="date"
                  value={searchData.systemDate}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="settleDate"
                  fullWidth
                  label="Settle Date"
                  type="date"
                  value={searchData.settleDate}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  name="tradeDate"
                  fullWidth
                  label="Trade Date"
                  type="date"
                  value={searchData.tradeDate}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>

            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="previousDate"
                  fullWidth
                  label="Previous Date"
                  type="date"
                  value={searchData.previousDate}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="baseCurrency"
                  fullWidth
                  label="Base Currency"
                  value={searchData.baseCurrency}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  name="versionNo"
                  fullWidth
                  label="Version No"
                  value={searchData.versionNo}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={handleSearch}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        <Table
          title={'System Profile'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Box>
      {open && (
        <ProfileModal
          onClose={handleClose}
          open={open}
          value={rowData}
        ></ProfileModal>
      )}
    </div>
  );
}
