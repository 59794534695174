import React, { useState, useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { getSystemDate } from '../../services/ProfileService';

import { AppBar, Toolbar, IconButton, Menu, MenuItem } from '@material-ui/core';
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import { Typography } from '../Wrappers/Wrappers';
import NavigationMenuSelect from '../SearchBar/SearchBar';
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';

import Logo from '../../images/Sas-logo.png';

import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import moment from 'moment/moment.js';
import useStyles from './styles';

export default function Header(props) {
  const classes = useStyles();
  const history = useHistory();

  // global
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  // local
  const [profileMenu, setProfileMenu] = useState(null);

  //system date
  const [systemDateValue, setSystemDateValue] = React.useState('');

  //search menu
  const [menuValue, setMenuValue] = React.useState('');

  useEffect(() => {
    (async () => {
      const systemDate = await getSystemDate();
      setSystemDateValue(systemDate);
    })();
  }, []);

  return (
    <AppBar
      position="fixed"
      style={{
        backgroundColor: '#GGGGGG',
      }}
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          )}
        </IconButton>
        <NavLink to="/app/home" style={{ textDecoration: 'none' }}>
          <div style={{ marginTop: -5 }}>
            <img alt="sas_tax" className={classes.logoImage} src={Logo}></img>
          </div>
        </NavLink>
        <div className={classes.grow} />
        <div style={{ width: 300, marginRight: 50 }}>
          <NavigationMenuSelect
            labelid="menu"
            label="Navigation Menu"
            value={menuValue}
            onChange={(e) => setMenuValue(e.target.value)}
            setNewValue={(event, newValue) => {
              if (newValue) {
                setMenuValue(newValue.link);

                const currentPath = window.location.pathname;
                history.push(newValue.link ? newValue.link : currentPath);
              } else {
                setMenuValue('');
                return;
              }
            }}
          />
        </div>
        <div style={{ marginRight: 20 }}>
          <DateRangeRoundedIcon />
          <label style={{ position: 'relative', top: -5, marginLeft: 5 }}>
            {moment(systemDateValue).format('MM/DD/YYYY')}
          </label>
        </div>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>

        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h6">{props.user.Name}</Typography>
          </div>
          <MenuItem
            onClick={() => {
              history.push('/logout');
            }}
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
          >
            <AccountIcon className={classes.profileMenuLink} /> Sign Out
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
