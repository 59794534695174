export const countries = [
  { id: '1', code: 'Afghanistan', codeDescription: 'Afghanistan' },
  { id: '2', code: 'Albania', codeDescription: 'Albania' },
  { id: '3', code: 'Algeria', codeDescription: 'Algeria' },
  { id: '4', code: 'American Samoa', codeDescription: 'American Samoa' },
  { id: '5', code: 'Andorra', codeDescription: 'Andorra' },
  { id: '6', code: 'Angola', codeDescription: 'Angola' },
  { id: '7', code: 'Anguilla', codeDescription: 'Anguilla' },
  { id: '8', code: 'Antarctica', codeDescription: 'Antarctica' },
  {
    id: '9',
    code: 'Antigua and Barbuda',
    codeDescription: 'Antigua and Barbuda',
  },
  { id: '10', code: 'Argentina', codeDescription: 'Argentina' },
  { id: '11', code: 'Armenia', codeDescription: 'Armenia' },
  { id: '12', code: 'Aruba', codeDescription: 'Aruba' },
  { id: '13', code: 'Australia', codeDescription: 'Australia' },
  { id: '14', code: 'Austria', codeDescription: 'Austria' },
  { id: '15', code: 'Azerbaijan', codeDescription: 'Azerbaijan' },
  { id: '16', code: 'Bahamas', codeDescription: 'Bahamas' },
  { id: '17', code: 'Bahrain', codeDescription: 'Bahrain' },
  { id: '18', code: 'Bangladesh', codeDescription: 'Bangladesh' },
  { id: '19', code: 'Barbados', codeDescription: 'Barbados' },
  { id: '20', code: 'Belarus', codeDescription: 'Belarus' },
  { id: '21', code: 'Belgium', codeDescription: 'Belgium' },
  { id: '22', code: 'Belize', codeDescription: 'Belize' },
  { id: '23', code: 'Benin', codeDescription: 'Benin' },
  { id: '24', code: 'Bermuda', codeDescription: 'Bermuda' },
  { id: '25', code: 'Bhutan', codeDescription: 'Bhutan' },
  {
    id: '26',
    code: 'Bolivia (Plurinational State of)',
    codeDescription: 'Bolivia (Plurinational State of)',
  },
  {
    id: '27',
    code: 'Bonaire, Sint Eustatius and Saba',
    codeDescription: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    id: '28',
    code: 'Bosnia and Herzegovina',
    codeDescription: 'Bosnia and Herzegovina',
  },
  { id: '29', code: 'Botswana', codeDescription: 'Botswana' },
  { id: '30', code: 'Bouvet Island', codeDescription: 'Bouvet Island' },
  { id: '31', code: 'Brazil', codeDescription: 'Brazil' },
  {
    id: '32',
    code: 'British Indian Ocean Territory',
    codeDescription: 'British Indian Ocean Territory',
  },
  { id: '33', code: 'Brunei Darussalam', codeDescription: 'Brunei Darussalam' },
  { id: '34', code: 'Bulgaria', codeDescription: 'Bulgaria' },
  { id: '35', code: 'Burkina Faso', codeDescription: 'Burkina Faso' },
  { id: '36', code: 'Burundi', codeDescription: 'Burundi' },
  { id: '37', code: 'Cabo Verde', codeDescription: 'Cabo Verde' },
  { id: '38', code: 'Cambodia', codeDescription: 'Cambodia' },
  { id: '39', code: 'Cameroon', codeDescription: 'Cameroon' },
  { id: '40', code: 'Canada', codeDescription: 'Canada' },
  { id: '41', code: 'Cayman Islands', codeDescription: 'Cayman Islands' },
  {
    id: '42',
    code: 'Central African Republic',
    codeDescription: 'Central African Republic',
  },
  { id: '43', code: 'Chad', codeDescription: 'Chad' },
  { id: '44', code: 'Chile', codeDescription: 'Chile' },
  { id: '45', code: 'China', codeDescription: 'China' },
  { id: '46', code: 'Christmas Island', codeDescription: 'Christmas Island' },
  {
    id: '47',
    code: 'Cocos (Keeling) Islands',
    codeDescription: 'Cocos (Keeling) Islands',
  },
  { id: '48', code: 'Colombia', codeDescription: 'Colombia' },
  { id: '49', code: 'Comoros', codeDescription: 'Comoros' },
  {
    id: '50',
    code: 'Congo (the Democratic Republic of the)',
    codeDescription: 'Congo (the Democratic Republic of the)',
  },
  { id: '51', code: 'Congo', codeDescription: 'Congo' },
  { id: '52', code: 'Cook Islands', codeDescription: 'Cook Islands' },
  { id: '53', code: 'Costa Rica', codeDescription: 'Costa Rica' },
  { id: '54', code: 'Croatia', codeDescription: 'Croatia' },
  { id: '55', code: 'Cuba', codeDescription: 'Cuba' },
  { id: '56', code: 'Curatao', codeDescription: 'Curatao' },
  { id: '57', code: 'Cyprus', codeDescription: 'Cyprus' },
  { id: '58', code: 'Czechia', codeDescription: 'Czechia' },
  { id: '59', code: "Côte d'Ivoire", codeDescription: "Côte d'Ivoire" },
  { id: '60', code: 'Denmark', codeDescription: 'Denmark' },
  { id: '61', code: 'Djibouti', codeDescription: 'Djibouti' },
  { id: '62', code: 'Dominica', codeDescription: 'Dominica' },
  {
    id: '63',
    code: 'Dominican Republic',
    codeDescription: 'Dominican Republic',
  },
  { id: '64', code: 'Ecuador', codeDescription: 'Ecuador' },
  { id: '65', code: 'Egypt', codeDescription: 'Egypt' },
  { id: '66', code: 'El Salvador', codeDescription: 'El Salvador' },
  { id: '67', code: 'Equatorial Guinea', codeDescription: 'Equatorial Guinea' },
  { id: '68', code: 'Eritrea', codeDescription: 'Eritrea' },
  { id: '69', code: 'Estonia', codeDescription: 'Estonia' },
  { id: '70', code: 'Eswatini', codeDescription: 'Eswatini' },
  { id: '71', code: 'Ethiopia', codeDescription: 'Ethiopia' },
  {
    id: '72',
    code: 'Falkland Islands [Malvinas]',
    codeDescription: 'Falkland Islands [Malvinas]',
  },
  { id: '73', code: 'Faroe Islands', codeDescription: 'Faroe Islands' },
  { id: '74', code: 'Fiji', codeDescription: 'Fiji' },
  { id: '75', code: 'Finland', codeDescription: 'Finland' },
  { id: '76', code: 'France', codeDescription: 'France' },
  { id: '77', code: 'French Guiana', codeDescription: 'French Guiana' },
  { id: '78', code: 'French Polynesia', codeDescription: 'French Polynesia' },
  {
    id: '79',
    code: 'French Southern Territories',
    codeDescription: 'French Southern Territories',
  },
  { id: '80', code: 'Gabon', codeDescription: 'Gabon' },
  { id: '81', code: 'Gambia', codeDescription: 'Gambia' },
  { id: '82', code: 'Georgia', codeDescription: 'Georgia' },
  { id: '83', code: 'Germany', codeDescription: 'Germany' },
  { id: '84', code: 'Ghana', codeDescription: 'Ghana' },
  { id: '85', code: 'Gibraltar', codeDescription: 'Gibraltar' },
  { id: '86', code: 'Greece', codeDescription: 'Greece' },
  { id: '87', code: 'Greenland', codeDescription: 'Greenland' },
  { id: '88', code: 'Grenada', codeDescription: 'Grenada' },
  { id: '89', code: 'Guadeloupe', codeDescription: 'Guadeloupe' },
  { id: '90', code: 'Guam', codeDescription: 'Guam' },
  { id: '91', code: 'Guatemala', codeDescription: 'Guatemala' },
  { id: '92', code: 'Guernsey', codeDescription: 'Guernsey' },
  { id: '93', code: 'Guinea', codeDescription: 'Guinea' },
  { id: '94', code: 'Guinea-Bissau', codeDescription: 'Guinea-Bissau' },
  { id: '95', code: 'Guyana', codeDescription: 'Guyana' },
  { id: '96', code: 'Haiti', codeDescription: 'Haiti' },
  {
    id: '97',
    code: 'Heard Island and McDonald Islands',
    codeDescription: 'Heard Island and McDonald Islands',
  },
  { id: '98', code: 'Holy See', codeDescription: 'Holy See' },
  { id: '99', code: 'Honduras', codeDescription: 'Honduras' },
  { id: '100', code: 'Hong Kong', codeDescription: 'Hong Kong' },
  { id: '101', code: 'Hungary', codeDescription: 'Hungary' },
  { id: '102', code: 'Iceland', codeDescription: 'Iceland' },
  { id: '103', code: 'India', codeDescription: 'India' },
  { id: '104', code: 'Indonesia', codeDescription: 'Indonesia' },
  {
    id: '105',
    code: 'Iran (Islamic Republic of)',
    codeDescription: 'Iran (Islamic Republic of)',
  },
  { id: '106', code: 'Iraq', codeDescription: 'Iraq' },
  { id: '107', code: 'Ireland', codeDescription: 'Ireland' },
  { id: '108', code: 'Isle of Man', codeDescription: 'Isle of Man' },
  { id: '109', code: 'Israel', codeDescription: 'Israel' },
  { id: '110', code: 'Italy', codeDescription: 'Italy' },
  { id: '111', code: 'Jamaica', codeDescription: 'Jamaica' },
  { id: '112', code: 'Japan', codeDescription: 'Japan' },
  { id: '113', code: 'Jersey', codeDescription: 'Jersey' },
  { id: '114', code: 'Jordan', codeDescription: 'Jordan' },
  { id: '115', code: 'Kazakhstan', codeDescription: 'Kazakhstan' },
  { id: '116', code: 'Kenya', codeDescription: 'Kenya' },
  { id: '117', code: 'Kiribati', codeDescription: 'Kiribati' },
  {
    id: '118',
    code: "Korea (the Democratic People's Republic of)",
    codeDescription: "Korea (the Democratic People's Republic of)",
  },
  {
    id: '119',
    code: 'Korea (the Republic of)',
    codeDescription: 'Korea (the Republic of)',
  },
  { id: '120', code: 'Kuwait', codeDescription: 'Kuwait' },
  { id: '121', code: 'Kyrgyzstan', codeDescription: 'Kyrgyzstan' },
  {
    id: '122',
    code: "Lao People's Democratic Republic",
    codeDescription: "Lao People's Democratic Republic",
  },
  { id: '123', code: 'Latvia', codeDescription: 'Latvia' },
  { id: '124', code: 'Lebanon', codeDescription: 'Lebanon' },
  { id: '125', code: 'Lesotho', codeDescription: 'Lesotho' },
  { id: '126', code: 'Liberia', codeDescription: 'Liberia' },
  { id: '127', code: 'Libya', codeDescription: 'Libya' },
  { id: '128', code: 'Liechtenstein', codeDescription: 'Liechtenstein' },
  { id: '129', code: 'Lithuania', codeDescription: 'Lithuania' },
  { id: '130', code: 'Luxembourg', codeDescription: 'Luxembourg' },
  { id: '131', code: 'Macao', codeDescription: 'Macao' },
  { id: '132', code: 'Madagascar', codeDescription: 'Madagascar' },
  { id: '133', code: 'Malawi', codeDescription: 'Malawi' },
  { id: '134', code: 'Malaysia', codeDescription: 'Malaysia' },
  { id: '135', code: 'Maldives', codeDescription: 'Maldives' },
  { id: '136', code: 'Mali', codeDescription: 'Mali' },
  { id: '137', code: 'Malta', codeDescription: 'Malta' },
  { id: '138', code: 'Marshall Islands', codeDescription: 'Marshall Islands' },
  { id: '139', code: 'Martinique', codeDescription: 'Martinique' },
  { id: '140', code: 'Mauritania', codeDescription: 'Mauritania' },
  { id: '141', code: 'Mauritius', codeDescription: 'Mauritius' },
  { id: '142', code: 'Mayotte', codeDescription: 'Mayotte' },
  { id: '143', code: 'Mexico', codeDescription: 'Mexico' },
  {
    id: '144',
    code: 'Micronesia (Federated States of)',
    codeDescription: 'Micronesia (Federated States of)',
  },
  {
    id: '145',
    code: 'Moldova (the Republic of)',
    codeDescription: 'Moldova (the Republic of)',
  },
  { id: '146', code: 'Monaco', codeDescription: 'Monaco' },
  { id: '147', code: 'Mongolia', codeDescription: 'Mongolia' },
  { id: '148', code: 'Montenegro', codeDescription: 'Montenegro' },
  { id: '149', code: 'Montserrat', codeDescription: 'Montserrat' },
  { id: '150', code: 'Morocco', codeDescription: 'Morocco' },
  { id: '151', code: 'Mozambique', codeDescription: 'Mozambique' },
  { id: '152', code: 'Myanmar', codeDescription: 'Myanmar' },
  { id: '153', code: 'Namibia', codeDescription: 'Namibia' },
  { id: '154', code: 'Nauru', codeDescription: 'Nauru' },
  { id: '155', code: 'Nepal', codeDescription: 'Nepal' },
  { id: '156', code: 'Netherlands', codeDescription: 'Netherlands' },
  { id: '157', code: 'New Caledonia', codeDescription: 'New Caledonia' },
  { id: '158', code: 'New Zealand', codeDescription: 'New Zealand' },
  { id: '159', code: 'Nicaragua', codeDescription: 'Nicaragua' },
  { id: '160', code: 'Niger', codeDescription: 'Niger' },
  { id: '161', code: 'Nigeria', codeDescription: 'Nigeria' },
  { id: '162', code: 'Niue', codeDescription: 'Niue' },
  { id: '163', code: 'Norfolk Island', codeDescription: 'Norfolk Island' },
  {
    id: '164',
    code: 'Northern Mariana Islands (MP)',
    codeDescription: 'Northern Mariana Islands (MP)',
  },
  { id: '165', code: 'Norway', codeDescription: 'Norway' },
  { id: '166', code: 'Oman', codeDescription: 'Oman' },
  { id: '167', code: 'Pakistan', codeDescription: 'Pakistan' },
  { id: '168', code: 'Palau', codeDescription: 'Palau' },
  {
    id: '169',
    code: 'Palestine, State of',
    codeDescription: 'Palestine, State of',
  },
  { id: '170', code: 'Panama', codeDescription: 'Panama' },
  { id: '171', code: 'Papua New Guinea', codeDescription: 'Papua New Guinea' },
  { id: '172', code: 'Paraguay', codeDescription: 'Paraguay' },
  { id: '173', code: 'Peru', codeDescription: 'Peru' },
  { id: '174', code: 'Philippines', codeDescription: 'Philippines' },
  { id: '175', code: 'Pitcairn', codeDescription: 'Pitcairn' },
  { id: '176', code: 'Poland', codeDescription: 'Poland' },
  { id: '177', code: 'Portugal', codeDescription: 'Portugal' },
  { id: '178', code: 'Puerto Rico', codeDescription: 'Puerto Rico' },
  { id: '179', code: 'Qatar', codeDescription: 'Qatar' },
  {
    id: '180',
    code: 'Republic of North Macedonia',
    codeDescription: 'Republic of North Macedonia',
  },
  { id: '181', code: 'Romania', codeDescription: 'Romania' },
  {
    id: '182',
    code: 'Russian Federation',
    codeDescription: 'Russian Federation',
  },
  { id: '183', code: 'Rwanda', codeDescription: 'Rwanda' },
  { id: '184', code: 'Réunion', codeDescription: 'Réunion' },
  { id: '185', code: 'Saint Barthélemy', codeDescription: 'Saint Barthélemy' },
  {
    id: '186',
    code: 'Saint Helena, Ascension and Tristan da Cunha',
    codeDescription: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  {
    id: '187',
    code: 'Saint Kitts and Nevis',
    codeDescription: 'Saint Kitts and Nevis',
  },
  { id: '188', code: 'Saint Lucia', codeDescription: 'Saint Lucia' },
  {
    id: '189',
    code: 'Saint Martin (French part)',
    codeDescription: 'Saint Martin (French part)',
  },
  {
    id: '190',
    code: 'Saint Pierre and Miquelon',
    codeDescription: 'Saint Pierre and Miquelon',
  },
  {
    id: '191',
    code: 'Saint Vincent and the Grenadines',
    codeDescription: 'Saint Vincent and the Grenadines',
  },
  { id: '192', code: 'Samoa', codeDescription: 'Samoa' },
  { id: '193', code: 'San Marino', codeDescription: 'San Marino' },
  {
    id: '194',
    code: 'Sao Tome and Principe',
    codeDescription: 'Sao Tome and Principe',
  },
  { id: '195', code: 'Saudi Arabia', codeDescription: 'Saudi Arabia' },
  { id: '196', code: 'Senegal', codeDescription: 'Senegal' },
  { id: '197', code: 'Serbia', codeDescription: 'Serbia' },
  { id: '198', code: 'Seychelles', codeDescription: 'Seychelles' },
  { id: '199', code: 'Sierra Leone', codeDescription: 'Sierra Leone' },
  { id: '200', code: 'Singapore', codeDescription: 'Singapore' },
  {
    id: '201',
    code: 'Sint Maarten (Dutch part)',
    codeDescription: 'Sint Maarten (Dutch part)',
  },
  { id: '202', code: 'Slovakia', codeDescription: 'Slovakia' },
  { id: '203', code: 'Slovenia', codeDescription: 'Slovenia' },
  { id: '204', code: 'Solomon Islands', codeDescription: 'Solomon Islands' },
  { id: '205', code: 'Somalia', codeDescription: 'Somalia' },
  { id: '206', code: 'South Africa', codeDescription: 'South Africa' },
  {
    id: '207',
    code: 'South Georgia and the South Sandwich Islands',
    codeDescription: 'South Georgia and the South Sandwich Islands',
  },
  { id: '208', code: 'South Sudan', codeDescription: 'South Sudan' },
  { id: '209', code: 'Spain', codeDescription: 'Spain' },
  { id: '210', code: 'Sri Lanka', codeDescription: 'Sri Lanka' },
  { id: '211', code: 'Sudan', codeDescription: 'Sudan' },
  { id: '212', code: 'Suriname', codeDescription: 'Suriname' },
  {
    id: '213',
    code: 'Svalbard and Jan Mayen',
    codeDescription: 'Svalbard and Jan Mayen',
  },
  { id: '214', code: 'Sweden', codeDescription: 'Sweden' },
  { id: '215', code: 'Switzerland', codeDescription: 'Switzerland' },
  {
    id: '216',
    code: 'Syrian Arab Republic',
    codeDescription: 'Syrian Arab Republic',
  },
  {
    id: '217',
    code: 'Taiwan (Province of China)',
    codeDescription: 'Taiwan (Province of China)',
  },
  { id: '218', code: 'Tajikistan', codeDescription: 'Tajikistan' },
  {
    id: '219',
    code: 'Tanzania, United Republic of',
    codeDescription: 'Tanzania, United Republic of',
  },
  { id: '220', code: 'Thailand', codeDescription: 'Thailand' },
  { id: '221', code: 'Timor-Leste', codeDescription: 'Timor-Leste' },
  { id: '222', code: 'Togo', codeDescription: 'Togo' },
  { id: '223', code: 'Tokelau', codeDescription: 'Tokelau' },
  { id: '224', code: 'Tonga', codeDescription: 'Tonga' },
  {
    id: '225',
    code: 'Trinidad and Tobago',
    codeDescription: 'Trinidad and Tobago',
  },
  { id: '226', code: 'Tunisia', codeDescription: 'Tunisia' },
  { id: '227', code: 'Turkey', codeDescription: 'Turkey' },
  { id: '228', code: 'Turkmenistan', codeDescription: 'Turkmenistan' },
  {
    id: '229',
    code: 'Turks and Caicos Islands',
    codeDescription: 'Turks and Caicos Islands',
  },
  { id: '230', code: 'Tuvalu', codeDescription: 'Tuvalu' },
  { id: '231', code: 'Uganda', codeDescription: 'Uganda' },
  { id: '232', code: 'Ukraine', codeDescription: 'Ukraine' },
  {
    id: '233',
    code: 'United Arab Emirates',
    codeDescription: 'United Arab Emirates',
  },
  {
    id: '234',
    code: 'United Kingdom of Great Britain and Northern Ireland',
    codeDescription: 'United Kingdom of Great Britain and Northern Ireland',
  },
  {
    id: '235',
    code: 'United States Minor Outlying Islands',
    codeDescription: 'United States Minor Outlying Islands',
  },
  {
    id: '236',
    code: 'United States of America',
    codeDescription: 'United States of America',
  },
  { id: '237', code: 'Uruguay', codeDescription: 'Uruguay' },
  { id: '238', code: 'Uzbekistan', codeDescription: 'Uzbekistan' },
  { id: '239', code: 'Vanuatu', codeDescription: 'Vanuatu' },
  {
    id: '240',
    code: 'Venezuela (Bolivarian Republic of)',
    codeDescription: 'Venezuela (Bolivarian Republic of)',
  },
  { id: '241', code: 'Viet Nam', codeDescription: 'Viet Nam' },
  {
    id: '242',
    code: 'Virgin Islands (British)',
    codeDescription: 'Virgin Islands (British)',
  },
  {
    id: '243',
    code: 'Virgin Islands (U.S.)',
    codeDescription: 'Virgin Islands (U.S.)',
  },
  {
    id: '244',
    code: 'Wallis and Futuna',
    codeDescription: 'Wallis and Futuna',
  },
  { id: '245', code: 'Western Sahara', codeDescription: 'Western Sahara' },
  { id: '246', code: 'Yemen', codeDescription: 'Yemen' },
  { id: '247', code: 'Zambia', codeDescription: 'Zambia' },
  { id: '248', code: 'Zimbabwe', codeDescription: 'Zimbabwe' },
  { id: '249', code: 'Åland Islands', codeDescription: 'Åland Islands' },
];

export const taxCountries = [
  { id: '1', code: 'AF', codeDescription: 'Afghanistan' },
  { id: '2', code: 'AX', codeDescription: 'Akrotiri' },
  { id: '3', code: 'AL', codeDescription: 'Albania' },
  { id: '4', code: 'AG', codeDescription: 'Algeria' },
  { id: '5', code: 'AQ', codeDescription: 'American Samoa' },
  { id: '6', code: 'AN', codeDescription: 'Andorra' },
  { id: '7', code: 'AO', codeDescription: 'Angola' },
  { id: '8', code: 'AV', codeDescription: 'Anguilla' },
  { id: '9', code: 'AY', codeDescription: 'Antarctica' },
  { id: '10', code: 'AC', codeDescription: 'Antigua & Barbuda' },
  { id: '11', code: 'AR', codeDescription: 'Argentina' },
  { id: '12', code: 'AM', codeDescription: 'Armenia' },
  { id: '13', code: 'AA', codeDescription: 'Aruba' },
  { id: '14', code: 'AT', codeDescription: 'Ashmore and Cartier Islands' },
  { id: '15', code: 'AS', codeDescription: 'Australia' },
  { id: '16', code: 'AU', codeDescription: 'Austria' },
  { id: '17', code: 'AJ', codeDescription: 'Azerbaijan' },
  { id: '18', code: 'BF', codeDescription: 'Bahamas' },
  { id: '19', code: 'BA', codeDescription: 'Bahrain' },
  { id: '20', code: 'FQ', codeDescription: 'Baker Island' },
  { id: '21', code: 'BG', codeDescription: 'Bangladesh' },
  { id: '22', code: 'BB', codeDescription: 'Barbados' },
  { id: '23', code: 'BO', codeDescription: 'Belarus' },
  { id: '24', code: 'BE', codeDescription: 'Belgium' },
  { id: '25', code: 'BH', codeDescription: 'Belize' },
  { id: '26', code: 'BN', codeDescription: 'Benin' },
  { id: '27', code: 'BD', codeDescription: 'Bermuda' },
  { id: '28', code: 'BT', codeDescription: 'Bhutan' },
  { id: '29', code: 'BL', codeDescription: 'Bolivia' },
  { id: '30', code: 'BK', codeDescription: 'Bosnia-Herzegovina' },
  { id: '31', code: 'BC', codeDescription: 'Botswana' },
  { id: '32', code: 'BV', codeDescription: 'Bouvet Island' },
  { id: '33', code: 'BR', codeDescription: 'Brazil' },
  { id: '34', code: 'IO', codeDescription: 'British Indian Ocean Territory' },
  { id: '35', code: 'VI', codeDescription: 'British Virgin Islands' },
  { id: '36', code: 'BX', codeDescription: 'Brunei' },
  { id: '37', code: 'BU', codeDescription: 'Bulgaria' },
  { id: '38', code: 'UV', codeDescription: 'Burkina Faso' },
  { id: '39', code: 'BM', codeDescription: 'Burma' },
  { id: '40', code: 'BY', codeDescription: 'Burundi' },
  { id: '41', code: 'CB', codeDescription: 'Cambodia' },
  { id: '42', code: 'CM', codeDescription: 'Cameroon' },
  { id: '43', code: 'CA', codeDescription: 'Canada' },
  { id: '44', code: 'CV', codeDescription: 'Cape Verde' },
  { id: '45', code: 'CJ', codeDescription: 'Cayman Islands' },
  { id: '46', code: 'CT', codeDescription: 'Central African Republic' },
  { id: '47', code: 'CD', codeDescription: 'Chad' },
  { id: '48', code: 'CI', codeDescription: 'Chile' },
  { id: '49', code: 'CH', codeDescription: 'China' },
  { id: '50', code: 'KT', codeDescription: 'Christmas Island' },
  { id: '51', code: 'IP', codeDescription: 'Clipperton Island' },
  { id: '52', code: 'CK', codeDescription: 'Cocos (Keeling) Islands' },
  { id: '53', code: 'CO', codeDescription: 'Colombia' },
  { id: '54', code: 'CN', codeDescription: 'Comoros' },
  { id: '55', code: 'CF', codeDescription: 'Congo (Brazzaville)' },
  { id: '56', code: 'CG', codeDescription: 'Congo (Kinshasa)' },
  { id: '57', code: 'CW', codeDescription: 'Cook Islands' },
  { id: '58', code: 'CR', codeDescription: 'Coral Sea Islands' },
  { id: '59', code: 'CS', codeDescription: 'Costa Rica' },
  { id: '60', code: 'IV', codeDescription: "Cote D'Ivoire (Ivory Coast)" },
  { id: '61', code: 'HR', codeDescription: 'Croatia' },
  { id: '62', code: 'CU', codeDescription: 'Cuba' },
  { id: '63', code: 'UC', codeDescription: 'Curacao' },
  { id: '64', code: 'CY', codeDescription: 'Cyprus' },
  { id: '65', code: 'EZ', codeDescription: 'Czech Republic' },
  { id: '66', code: 'DA', codeDescription: 'Denmark' },
  { id: '67', code: 'DX', codeDescription: 'Dhekelia' },
  { id: '68', code: 'DJ', codeDescription: 'Djibouti' },
  { id: '69', code: 'DO', codeDescription: 'Dominica' },
  { id: '70', code: 'DR', codeDescription: 'Dominican Republic' },
  { id: '71', code: 'TT', codeDescription: 'East Timor' },
  { id: '72', code: 'EC', codeDescription: 'Ecuador' },
  { id: '73', code: 'EG', codeDescription: 'Egypt' },
  { id: '74', code: 'ES', codeDescription: 'El Salvador' },
  { id: '75', code: 'EK', codeDescription: 'Equatorial Guinea' },
  { id: '76', code: 'ER', codeDescription: 'Eritrea' },
  { id: '77', code: 'EN', codeDescription: 'Estonia' },
  { id: '78', code: 'ET', codeDescription: 'Ethiopia' },
  {
    id: '79',
    code: 'FK',
    codeDescription: 'Falkland Islands (Islas Malvinas)',
  },
  { id: '80', code: 'FO', codeDescription: 'Faroe Islands' },
  { id: '81', code: 'FM', codeDescription: 'Federated States of Micronesia' },
  { id: '82', code: 'FJ', codeDescription: 'Fiji' },
  { id: '83', code: 'FI', codeDescription: 'Finland' },
  { id: '84', code: 'FR', codeDescription: 'France' },
  { id: '85', code: 'FP', codeDescription: 'French Polynesia' },
  {
    id: '86',
    code: 'FS',
    codeDescription: 'French Southern and Antarctic Lands',
  },
  { id: '87', code: 'GB', codeDescription: 'Gabon' },
  { id: '88', code: 'GA', codeDescription: 'The Gambia' },
  { id: '89', code: 'GG', codeDescription: 'Georgia' },
  { id: '90', code: 'GM', codeDescription: 'Germany' },
  { id: '91', code: 'GH', codeDescription: 'Ghana' },
  { id: '92', code: 'GI', codeDescription: 'Gibraltar' },
  { id: '93', code: 'GR', codeDescription: 'Greece' },
  { id: '94', code: 'GL', codeDescription: 'Greenland' },
  { id: '95', code: 'GJ', codeDescription: 'Grenada' },
  { id: '96', code: 'GQ', codeDescription: 'Guam' },
  { id: '97', code: 'GT', codeDescription: 'Guatemala' },
  { id: '98', code: 'GK', codeDescription: 'Guernsey' },
  { id: '99', code: 'GV', codeDescription: 'Guinea' },
  { id: '100', code: 'PU', codeDescription: 'Guinea-Bissau' },
  { id: '101', code: 'GY', codeDescription: 'Guyana' },
  { id: '102', code: 'HA', codeDescription: 'Haiti' },
  {
    id: '103',
    code: 'HM',
    codeDescription: 'Heard Island and McDonald Islands',
  },
  { id: '104', code: 'VT', codeDescription: 'Holy See' },
  { id: '105', code: 'HO', codeDescription: 'Honduras' },
  { id: '106', code: 'HK', codeDescription: 'Hong Kong' },
  { id: '107', code: 'HQ', codeDescription: 'Howland Island' },
  { id: '108', code: 'HU', codeDescription: 'Hungary' },
  { id: '109', code: 'IC', codeDescription: 'Iceland' },
  { id: '110', code: 'IN', codeDescription: 'India' },
  { id: '111', code: 'ID', codeDescription: 'Indonesia' },
  { id: '112', code: 'IR', codeDescription: 'Iran' },
  { id: '113', code: 'IZ', codeDescription: 'Iraq' },
  { id: '114', code: 'EI', codeDescription: 'Ireland' },
  { id: '115', code: 'IS', codeDescription: 'Israel' },
  { id: '116', code: 'IT', codeDescription: 'Italy' },
  { id: '117', code: 'JM', codeDescription: 'Jamaica' },
  { id: '118', code: 'JN', codeDescription: 'Jan Mayen' },
  { id: '119', code: 'JA', codeDescription: 'Japan' },
  { id: '120', code: 'DQ', codeDescription: 'Jarvis Island' },
  { id: '121', code: 'JE', codeDescription: 'Jersey' },
  { id: '122', code: 'JQ', codeDescription: 'Johnston Atoll' },
  { id: '123', code: 'JO', codeDescription: 'Jordan' },
  { id: '124', code: 'KZ', codeDescription: 'Kazakhstan' },
  { id: '125', code: 'KE', codeDescription: 'Kenya' },
  { id: '126', code: 'KQ', codeDescription: 'Kingman Reef' },
  { id: '127', code: 'KR', codeDescription: 'Kiribati' },
  {
    id: '128',
    code: 'KN',
    codeDescription: "Korea, Democratic People's Republic of (North)",
  },
  { id: '129', code: 'KS', codeDescription: 'Korea, Republic of (South)' },
  { id: '130', code: 'KV', codeDescription: 'Kosovo' },
  { id: '131', code: 'KU', codeDescription: 'Kuwait' },
  { id: '132', code: 'KG', codeDescription: 'Kyrgyzstan' },
  { id: '133', code: 'LA', codeDescription: 'Laos' },
  { id: '134', code: 'LG', codeDescription: 'Latvia' },
  { id: '135', code: 'LE', codeDescription: 'Lebanon' },
  { id: '136', code: 'LT', codeDescription: 'Lesotho' },
  { id: '137', code: 'LI', codeDescription: 'Liberia' },
  { id: '138', code: 'LY', codeDescription: 'Libya' },
  { id: '139', code: 'LS', codeDescription: 'Liechtenstein' },
  { id: '140', code: 'LH', codeDescription: 'Lithuania' },
  { id: '141', code: 'LU', codeDescription: 'Luxembourg' },
  { id: '142', code: 'MC', codeDescription: 'Macau' },
  { id: '143', code: 'MK', codeDescription: 'Macedonia' },
  { id: '144', code: 'MA', codeDescription: 'Madagascar' },
  { id: '145', code: 'MI', codeDescription: 'Malawi' },
  { id: '146', code: 'MY', codeDescription: 'Malaysia' },
  { id: '147', code: 'MV', codeDescription: 'Maldives' },
  { id: '148', code: 'ML', codeDescription: 'Mali' },
  { id: '149', code: 'MT', codeDescription: 'Malta' },
  { id: '150', code: 'IM', codeDescription: 'Man, Isle of' },
  { id: '151', code: 'RM', codeDescription: 'Marshall Islands' },
  { id: '152', code: 'MR', codeDescription: 'Mauritania' },
  { id: '153', code: 'MP', codeDescription: 'Mauritius' },
  { id: '154', code: 'MX', codeDescription: 'Mexico' },
  { id: '155', code: 'MQ', codeDescription: 'Midway Islands' },
  { id: '156', code: 'MD', codeDescription: 'Moldova' },
  { id: '157', code: 'MN', codeDescription: 'Monaco' },
  { id: '158', code: 'MG', codeDescription: 'Mongolia' },
  { id: '159', code: 'MJ', codeDescription: 'Montenegro' },
  { id: '160', code: 'MH', codeDescription: 'Montserrat' },
  { id: '161', code: 'MO', codeDescription: 'Morocco' },
  { id: '162', code: 'MZ', codeDescription: 'Mozambique' },
  { id: '163', code: 'WA', codeDescription: 'Namibia' },
  { id: '164', code: 'NR', codeDescription: 'Nauru' },
  { id: '165', code: 'BQ', codeDescription: 'Navassa Island' },
  { id: '166', code: 'NP', codeDescription: 'Nepal' },
  { id: '167', code: 'NL', codeDescription: 'Netherlands' },
  { id: '168', code: 'NC', codeDescription: 'New Caledonia' },
  { id: '169', code: 'NZ', codeDescription: 'New Zealand' },
  { id: '170', code: 'NU', codeDescription: 'Nicaragua' },
  { id: '171', code: 'NG', codeDescription: 'Niger' },
  { id: '172', code: 'NI', codeDescription: 'Nigeria' },
  { id: '173', code: 'NE', codeDescription: 'Niue' },
  { id: '174', code: 'NF', codeDescription: 'Norfolk Island' },
  { id: '175', code: 'CQ', codeDescription: 'Northern Mariana Islands' },
  { id: '176', code: 'NO', codeDescription: 'Norway' },
  { id: '177', code: 'MU', codeDescription: 'Oman' },
  { id: '178', code: 'OC', codeDescription: 'Other Country' },
  { id: '179', code: 'PK', codeDescription: 'Pakistan' },
  { id: '180', code: 'PS', codeDescription: 'Palau' },
  { id: '181', code: 'LQ', codeDescription: 'Palmyra Atoll' },
  { id: '182', code: 'PM', codeDescription: 'Panama' },
  { id: '183', code: 'PP', codeDescription: 'Papua-New Guinea' },
  { id: '184', code: 'PF', codeDescription: 'Paracel Islands' },
  { id: '185', code: 'PA', codeDescription: 'Paraguay' },
  { id: '186', code: 'PE', codeDescription: 'Peru' },
  { id: '187', code: 'RP', codeDescription: 'Philippines' },
  { id: '188', code: 'PC', codeDescription: 'Pitcairn Islands' },
  { id: '189', code: 'PL', codeDescription: 'Poland' },
  { id: '190', code: 'PO', codeDescription: 'Portugal' },
  { id: '191', code: 'RQ', codeDescription: 'Puerto Rico' },
  { id: '192', code: 'QA', codeDescription: 'Qatar' },
  { id: '193', code: 'RO', codeDescription: 'Romania' },
  { id: '194', code: 'RS', codeDescription: 'Russia' },
  { id: '195', code: 'RW', codeDescription: 'Rwanda' },
  { id: '196', code: 'TB', codeDescription: 'Saint Barthelemy' },
  { id: '197', code: 'RN', codeDescription: 'Saint Martin' },
  { id: '198', code: 'WS', codeDescription: 'Samoa' },
  { id: '199', code: 'SM', codeDescription: 'San Marino' },
  { id: '200', code: 'TP', codeDescription: 'Sao Tome and Principe' },
  { id: '201', code: 'SA', codeDescription: 'Saudi Arabia' },
  { id: '202', code: 'SG', codeDescription: 'Senegal' },
  { id: '203', code: 'RI', codeDescription: 'Serbia' },
  { id: '204', code: 'SE', codeDescription: 'Seychelles' },
  { id: '205', code: 'SL', codeDescription: 'Sierra Leone' },
  { id: '206', code: 'SN', codeDescription: 'Singapore' },
  { id: '207', code: 'NN', codeDescription: 'Sint Maarten' },
  { id: '208', code: 'LO', codeDescription: 'Slovakia' },
  { id: '209', code: 'SI', codeDescription: 'Slovenia' },
  { id: '210', code: 'BP', codeDescription: 'Solomon Islands' },
  { id: '211', code: 'SO', codeDescription: 'Somalia' },
  { id: '212', code: 'SF', codeDescription: 'South Africa' },
  {
    id: '213',
    code: 'SX',
    codeDescription: 'South Georgia and the South Sandwich Islands',
  },
  { id: '214', code: 'OD', codeDescription: 'South Sudan' },
  { id: '215', code: 'SP', codeDescription: 'Spain' },
  { id: '216', code: 'PG', codeDescription: 'Spratly Islands' },
  { id: '217', code: 'CE', codeDescription: 'Sri Lanka' },
  { id: '218', code: 'SH', codeDescription: 'St. Helena' },
  { id: '219', code: 'SC', codeDescription: 'St. Kitts and Nevis' },
  { id: '220', code: 'ST', codeDescription: 'St. Lucia Island' },
  { id: '221', code: 'SB', codeDescription: 'St. Pierre and Miquelon' },
  { id: '222', code: 'VC', codeDescription: 'St. Vincent and the Grenadines' },
  { id: '223', code: 'SU', codeDescription: 'Sudan' },
  { id: '224', code: 'NS', codeDescription: 'Suriname' },
  { id: '225', code: 'SV', codeDescription: 'Svalbard' },
  { id: '226', code: 'WZ', codeDescription: 'Swaziland' },
  { id: '227', code: 'SW', codeDescription: 'Sweden' },
  { id: '228', code: 'SZ', codeDescription: 'Switzerland' },
  { id: '229', code: 'SY', codeDescription: 'Syria' },
  { id: '230', code: 'TW', codeDescription: 'Taiwan' },
  { id: '231', code: 'TI', codeDescription: 'Tajikistan' },
  { id: '232', code: 'TZ', codeDescription: 'Tanzania' },
  { id: '233', code: 'TH', codeDescription: 'Thailand' },
  { id: '234', code: 'TO', codeDescription: 'Togo' },
  { id: '235', code: 'TL', codeDescription: 'Tokelau' },
  { id: '236', code: 'TN', codeDescription: 'Tonga' },
  { id: '237', code: 'TD', codeDescription: 'Trinidad and Tobago' },
  { id: '238', code: 'TS', codeDescription: 'Tunisia' },
  { id: '239', code: 'TU', codeDescription: 'Turkey' },
  { id: '240', code: 'TX', codeDescription: 'Turkmenistan' },
  { id: '241', code: 'TK', codeDescription: 'Turks and Caicos Islands' },
  { id: '242', code: 'TV', codeDescription: 'Tuvalu' },
  { id: '243', code: 'UG', codeDescription: 'Uganda' },
  { id: '244', code: 'UP', codeDescription: 'Ukraine' },
  { id: '245', code: 'AE', codeDescription: 'United Arab Emirates' },
  {
    id: '246',
    code: 'UK',
    codeDescription:
      'United Kingdom (England, Northern Ireland, Scotland, and Wales)',
  },
  { id: '247', code: 'UY', codeDescription: 'Uruguay' },
  { id: '248', code: 'UZ', codeDescription: 'Uzbekistan' },
  { id: '249', code: 'NH', codeDescription: 'Vanuatu' },
  { id: '250', code: 'VE', codeDescription: 'Venezuela' },
  { id: '251', code: 'VM', codeDescription: 'Vietnam' },
  { id: '252', code: 'VQ', codeDescription: 'Virgin Islands' },
  { id: '253', code: 'WQ', codeDescription: 'Wake Island' },
  { id: '254', code: 'WF', codeDescription: 'Wallis and Futuna' },
  { id: '255', code: 'WI', codeDescription: 'Western Sahara' },
  { id: '256', code: 'YM', codeDescription: 'Yemen (Aden)' },
  { id: '257', code: 'ZA', codeDescription: 'Zambia' },
  { id: '258', code: 'ZI', codeDescription: 'Zimbabwe' },
];

export const states = [
  { id: '1', code: 'AK', codeDescription: 'Alaska' },
  { id: '2', code: 'AL', codeDescription: 'Alabama' },
  { id: '3', code: 'AR', codeDescription: 'Arkansas' },
  { id: '4', code: 'AZ', codeDescription: 'Arizona' },
  { id: '5', code: 'CA', codeDescription: 'California' },
  { id: '6', code: 'CO', codeDescription: 'Colorado' },
  { id: '7', code: 'CT', codeDescription: 'Connecticut' },
  { id: '8', code: 'DC', codeDescription: 'District of Columbia' },
  { id: '9', code: 'DE', codeDescription: 'Delaware' },
  { id: '10', code: 'FL', codeDescription: 'Florida' },
  { id: '11', code: 'GA', codeDescription: 'Georgia' },
  { id: '12', code: 'HI', codeDescription: 'Hawaii' },
  { id: '13', code: 'IA', codeDescription: 'Iowa' },
  { id: '14', code: 'ID', codeDescription: 'Idaho' },
  { id: '15', code: 'IL', codeDescription: 'Illinois' },
  { id: '16', code: 'IN', codeDescription: 'Indiana' },
  { id: '17', code: 'KS', codeDescription: 'Kansas' },
  { id: '18', code: 'KY', codeDescription: 'Kentucky' },
  { id: '19', code: 'LA', codeDescription: 'Louisiana' },
  { id: '20', code: 'MA', codeDescription: 'Massachusetts' },
  { id: '21', code: 'MD', codeDescription: 'Maryland' },
  { id: '22', code: 'ME', codeDescription: 'Maine' },
  { id: '23', code: 'MI', codeDescription: 'Michigan' },
  { id: '24', code: 'MN', codeDescription: 'Minnesota' },
  { id: '25', code: 'MO', codeDescription: 'Missouri' },
  { id: '26', code: 'MS', codeDescription: 'Mississippi' },
  { id: '27', code: 'MT', codeDescription: 'Montana' },
  { id: '28', code: 'NC', codeDescription: 'North Carolina' },
  { id: '29', code: 'ND', codeDescription: 'North Dakota' },
  { id: '30', code: 'NE', codeDescription: 'Nebraska' },
  { id: '31', code: 'NH', codeDescription: 'New Hampshire' },
  { id: '32', code: 'NJ', codeDescription: 'New Jersey' },
  { id: '33', code: 'NM', codeDescription: 'New Mexico' },
  { id: '34', code: 'NV', codeDescription: 'Nevada' },
  { id: '35', code: 'NY', codeDescription: 'New York' },
  { id: '36', code: 'OH', codeDescription: 'Ohio' },
  { id: '37', code: 'OK', codeDescription: 'Oklahoma' },
  { id: '38', code: 'OR', codeDescription: 'Oregon' },
  { id: '39', code: 'PA', codeDescription: 'Pennsylvania' },
  { id: '40', code: 'RI', codeDescription: 'Rhode Island' },
  { id: '41', code: 'SC', codeDescription: 'South Carolina' },
  { id: '42', code: 'SD', codeDescription: 'South Dakota' },
  { id: '43', code: 'TN', codeDescription: 'Tennessee' },
  { id: '44', code: 'TX', codeDescription: 'Texas' },
  { id: '45', code: 'UT', codeDescription: 'Utah' },
  { id: '46', code: 'VA', codeDescription: 'Virginia' },
  { id: '47', code: 'VT', codeDescription: 'Vermont' },
  { id: '48', code: 'WA', codeDescription: 'Washington' },
  { id: '49', code: 'WI', codeDescription: 'Wisconsin' },
  { id: '50', code: 'WV', codeDescription: 'West Virginia' },
  { id: '51', code: 'WY', codeDescription: 'Wyoming' },
];

export const entryTypes = [
  { id: '1', code: 'TRD', codeDescription: 'TRD' },
  { id: '2', code: 'ACATC', codeDescription: 'ACATC' },
  { id: '3', code: 'ACATS', codeDescription: 'ACATS' },
  { id: '4', code: 'CSD', codeDescription: 'CSD' },
  { id: '5', code: 'DIV', codeDescription: 'DIV' },
  { id: '6', code: 'JNLC', codeDescription: 'JNLC' },
  { id: '7', code: 'JNLS', codeDescription: 'JNLS' },
  { id: '8', code: 'CORR', codeDescription: 'CORR' },
  { id: '9', code: 'INT', codeDescription: 'INT' },
  { id: '10', code: 'PTC', codeDescription: 'PTC' },
  { id: '11', code: 'PTR', codeDescription: 'PTR' },
  { id: '12', code: 'REORG', codeDescription: 'REORG' },
  { id: '13', code: 'SPIN', codeDescription: 'SPIN' },
  { id: '14', code: 'SPLIT', codeDescription: 'SPLIT' },
  { id: '15', code: 'DIVNRA', codeDescription: 'DIVNRA' },
  { id: '16', code: 'MA', codeDescription: 'MA' },
  { id: '17', code: 'CSD', codeDescription: 'CSD' },
  { id: '18', code: 'CSW', codeDescription: 'CSW' },
  { id: '19', code: 'FEE', codeDescription: 'FEE' },
];

export const vendors = [
  { id: '1', code: 'EGNYTE Inc', codeDescription: 'EGNYTE Inc' },
  { id: '2', code: 'Dunkin Donuts', codeDescription: 'Dunkin Donuts' },
  { id: '3', code: 'Michele Singh', codeDescription: 'Michele Singh' },
  {
    id: '4',
    code: 'Electronic Transaction Clearing',
    codeDescription: 'Electronic Transaction Clearing',
  },
  { id: '5', code: 'Jimmy Johns', codeDescription: 'Jimmy Johns' },
  { id: '6', code: 'UBER', codeDescription: 'UBER' },
  { id: '7', code: 'United Airlines', codeDescription: 'United Airlines' },
  { id: '8', code: 'BWI Creative Food', codeDescription: 'BWI Creative Food' },
  {
    id: '9',
    code: 'Financial Training Services Inc.',
    codeDescription: 'Financial Training Services Inc.',
  },
  { id: '10', code: 'UPS', codeDescription: 'UPS' },
  { id: '11', code: 'HELLOSIGN', codeDescription: 'HELLOSIGN' },
  { id: '12', code: 'FINRA CRD', codeDescription: 'FINRA CRD' },
  {
    id: '13',
    code: 'Knopman Marks Financial',
    codeDescription: 'Knopman Marks Financial',
  },
  {
    id: '14',
    code: 'KAPLAN-JYMONK-DEARBORN',
    codeDescription: 'KAPLAN-JYMONK-DEARBORN',
  },
  {
    id: '15',
    code: 'ETC Clearing Corp.',
    codeDescription: 'ETC Clearing Corp.',
  },
  { id: '16', code: 'Hilton Hotels', codeDescription: 'Hilton Hotels' },
  { id: '17', code: 'NSCP', codeDescription: 'NSCP' },
  { id: '17', code: 'Biometrics', codeDescription: 'Biometrics' },
  { id: '17', code: 'USPS', codeDescription: 'USPS' },
  {
    id: '17',
    code: 'Greyline Solutions LLC',
    codeDescription: 'Greyline Solutions LLC',
  },
  {
    id: '17',
    code: 'First Republic Bank',
    codeDescription: 'First Republic Bank',
  },
  { id: '17', code: 'Zendesk, Inc.', codeDescription: 'Zendesk, Inc.' },
  {
    id: '17',
    code: 'CFB Board of Standards, Inc.',
    codeDescription: 'CFB Board of Standards, Inc.',
  },
  {
    id: '17',
    code: 'Alvarez & Associates, Inc.',
    codeDescription: 'Alvarez & Associates, Inc.',
  },
  { id: '17', code: 'SVB Bank', codeDescription: 'SVB Bank' },
  {
    id: '17',
    code: 'Southwest Airlines',
    codeDescription: 'Southwest Airlines',
  },
  {
    id: '17',
    code: 'Wolverine Securities',
    codeDescription: 'Wolverine Securities',
  },
  { id: '17', code: 'Alpaca DB', codeDescription: 'Alpaca DB' },
  {
    id: '17',
    code: 'Johnson Rivers Lyons, LLC',
    codeDescription: 'Johnson Rivers Lyons, LLC',
  },
  { id: '17', code: 'Global Relay', codeDescription: 'Global Relay' },
  {
    id: '17',
    code: 'The NASDAQ Stock Market, LLC.',
    codeDescription: 'The NASDAQ Stock Market, LLC.',
  },
  {
    id: '17',
    code: 'Trulioo Information Services',
    codeDescription: 'Trulioo Information Services',
  },
  {
    id: '17',
    code: 'Lowenstein Sandler LLP',
    codeDescription: 'Lowenstein Sandler LLP',
  },
  { id: '17', code: 'CSC', codeDescription: 'CSC' },
  { id: '17', code: 'NRS', codeDescription: 'NRS' },
  {
    id: '17',
    code: 'APEX Clearing Corporation',
    codeDescription: 'APEX Clearing Corporation',
  },
  { id: '17', code: 'PLAID Inc.', codeDescription: 'PLAID Inc.' },
  {
    id: '17',
    code: 'ETC Misc. Fee Account 1212062017',
    codeDescription: 'ETC Misc. Fee Account 1212062017',
  },
];

export const callStatusReasons = [
  {
    id: '1',
    code: 'ACH/Wire initiated',
    codeDescription: 'ACH/Wire initiated',
  },
  { id: '2', code: 'ACH/Wire recieved', codeDescription: 'ACH/Wire recieved' },
  { id: '3', code: 'Call error', codeDescription: 'Call error' },
  { id: '4', code: 'Liquidated', codeDescription: 'Liquidated' },
  { id: '5', code: 'Auto-satisfy', codeDescription: 'Auto-satisfy' },
];

export const legalEntities = [
  { id: '1', code: 'BTRST', codeDescription: 'Business Trust', note: 'TIN No' },
  { id: '2', code: 'CORP', codeDescription: 'Corporation', note: 'TIN No' },
  {
    id: '3',
    code: 'CUST',
    codeDescription: 'Custodial (UGMA/UTMA)',
    note: 'SSN',
  },
  {
    id: '4',
    code: 'GENP',
    codeDescription: 'General Partnership',
    note: 'TIN No',
  },
  {
    id: '5',
    code: 'ICLUB',
    codeDescription: 'Investment Club',
    note: 'TIN No',
  },
  { id: '6', code: 'INDIV', codeDescription: 'Individual', note: 'SSN' },
  { id: '7', code: 'IRA4K', codeDescription: '401(k)', note: 'SSN' },
  {
    id: '8',
    code: 'IRAQP',
    codeDescription: 'Qualified Retirement Plan',
    note: 'SSN',
  },
  { id: '9', code: 'IRARH', codeDescription: 'Roth IRA', note: 'SSN' },
  { id: '10', code: 'IRARO', codeDescription: 'Rollover IRA', note: 'SSN' },
  { id: '11', code: 'IRAS', codeDescription: 'SEP IRA', note: 'SSN' },
  { id: '12', code: 'IRAT', codeDescription: 'Traditional IRA', note: 'SSN' },
  {
    id: '13',
    code: 'JCP',
    codeDescription: 'Joint - Community Property',
    note: 'SSN',
  },
  {
    id: '14',
    code: 'JROS',
    codeDescription: 'Joint - W/Rights of Survivorship',
    note: 'SSN',
  },
  {
    id: '15',
    code: 'JTE',
    codeDescription: 'Joint - Tenants by Entirety',
    note: 'SSN',
  },
  {
    id: '16',
    code: 'JTIC',
    codeDescription: 'Joint - Tenants in common',
    note: 'SSN',
  },
  {
    id: '17',
    code: 'LLC',
    codeDescription: 'Limited Liability Co.',
    note: 'TIN No',
  },
  {
    id: '18',
    code: 'LTDP',
    codeDescription: 'Limited Partnership',
    note: 'TIN No',
  },
  {
    id: '19',
    code: 'SOLEP',
    codeDescription: 'Sole Proprietorship',
    note: 'SSN',
  },
  { id: '20', code: 'TRST', codeDescription: 'Trust', note: 'SSN' },
  {
    id: '21',
    code: 'UNINC',
    codeDescription: 'UnIncorporated Organization',
    note: 'TIN No',
  },
];
