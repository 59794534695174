/*eslint eqeqeq: "off"*/
import {
  Administrator,
  AdministratorServiceClient,
  ListAdministratorRequest,
  ReadAdministratorRequest,
  DeleteAdministratorRequest,
} from '../proto/usrpb/administrator_grpc_web_pb';
import { notifyError } from 'components/Notification/Notification';
import { auth } from '../lib/auth/Auth';

const service = new AdministratorServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

const requestParameter = (param) => {
  let req = new Administrator();
  req.setEmail(param.email);
  req.setName(param.name);
  req.setPassword(param.password);
  req.setNewPassword(param.newPassword);
  req.setOldPassword(param.confirmPassword);
  req.setUsrId(param.usrId);

  console.log(param);

  return req;
};

export async function createAdministrator(param) {
  return new Promise((resolve, reject) => {
    const req = requestParameter(param);

    service.createAdministrator(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateAdministrator(param) {
  return new Promise((resolve, reject) => {
    const req = requestParameter(param);

    service.updateAdministrator(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function readAdministrator(param) {
  return new Promise((resolve, reject) => {
    const req = new ReadAdministratorRequest();
    req.setUsrId(param.usrId);

    service.readAdministrator(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteAdministrator(param) {
  return new Promise((resolve, reject) => {
    const req = new DeleteAdministratorRequest();
    req.setUsrId(param.usrId);

    service.deleteAdministrator(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listAdministrator(param) {
  return new Promise((resolve, reject) => {
    const req = new ListAdministratorRequest();
    req.setEmail(param.email);
    req.setName(param.name);

    service.listAdministrator(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
