/*ReactJS*/
import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';
// import SymbolSelect from '../../../components/AutoComplete/Symbol';

import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
} from '@material-ui/core';

import { dateProtoObjectToString } from '../../../services/ConvertService';

export default function TrnsModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({});
  const [isEdit, setIsEdit] = React.useState(false);

  useEffect(() => {
    if (isOpen) {
      setModalData({
        ...value,
        tradeDate: dateProtoObjectToString(value.tradeDate),
        settleDate: dateProtoObjectToString(value.settleDate),
        systemDate: dateProtoObjectToString(value.systemDate),
      });
      if (value.trnsId) {
        setIsEdit(true);
      }
    }
  }, [isOpen, value]);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalFull}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
            {isEdit ? 'Edit' : 'Add New'} Transactions
          </Typography>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="correspondent"
                    fullWidth
                    label="Correspondent"
                    value={modalData.correspondent}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 4 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="office"
                    fullWidth
                    label="Office"
                    value={modalData.office}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 3 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="accountNo"
                    fullWidth
                    label="Account No"
                    value={modalData.accountNo}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 12 }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="year"
                    label="Year"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.year}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="tradeDate"
                    fullWidth
                    label="Trade Date"
                    type="date"
                    value={modalData.tradeDate}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="settleDate"
                    fullWidth
                    label="Settle Date"
                    type="date"
                    value={modalData.settleDate}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="systemDate"
                    fullWidth
                    label="System Date"
                    type="date"
                    value={modalData.systemDate}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>

                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="cusip"
                    fullWidth
                    label="Cusip"
                    inputProps={{
                      maxlength: 12,
                    }}
                    value={modalData.cusip}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>

              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="entryType"
                    fullWidth
                    label="Entry Type"
                    inputProps={{
                      maxlength: 6,
                    }}
                    value={modalData.entryType}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>

                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="description"
                    fullWidth
                    label="Description"
                    inputProps={{
                      maxlength: 150,
                    }}
                    value={modalData.description}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="netAmount"
                    fullWidth
                    label="Net Amount"
                    type="number"
                    value={modalData.netAmount}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="grossAmount"
                    fullWidth
                    label="Gross Amount"
                    type="number"
                    value={modalData.grossAmount}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="qty"
                    fullWidth
                    label="Qty"
                    type="number"
                    value={modalData.qty}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="symbol"
                    fullWidth
                    label="Symbol"
                    inputProps={{
                      maxlength: 30,
                    }}
                    value={modalData.symbol}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>

                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="symbolDescription"
                    fullWidth
                    label="Symbol Description"
                    inputProps={{
                      maxlength: 300,
                    }}
                    value={modalData.symbolDescription}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="symbolId"
                    fullWidth
                    label="Symbol ID"
                    type="number"
                    value={modalData.symbolId}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="externalId"
                    fullWidth
                    label="External ID"
                    inputProps={{
                      maxlength: 50,
                    }}
                    value={modalData.externalId}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>

                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="irsForm"
                    fullWidth
                    label="IRS Form"
                    inputProps={{
                      maxlength: 20,
                    }}
                    value={modalData.irsForm}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="accountId"
                    fullWidth
                    label="Account ID"
                    value={modalData.accountId}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div
                  className={classes.grdCell1}
                  style={{ marginRight: 30 }}
                ></div>
              </div>
              <div className={classes.modalFooter}>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div className={classes.grdCellNone}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => {
                      handleClose(modalData, isEdit);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
