/**
 * @fileoverview gRPC-Web generated client stub for taxfilingpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var proto_commonpb_file_pb = require('../../proto/commonpb/file_pb.js')
const proto = {};
proto.taxfilingpb = require('./tin_validation_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.TinValidationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.TinValidationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.TinValidationDownloadRequest,
 *   !proto.commonpb.File>}
 */
const methodDescriptor_TinValidationService_Download = new grpc.web.MethodDescriptor(
  '/taxfilingpb.TinValidationService/Download',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.TinValidationDownloadRequest,
  proto_commonpb_file_pb.File,
  /**
   * @param {!proto.taxfilingpb.TinValidationDownloadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_commonpb_file_pb.File.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.TinValidationDownloadRequest,
 *   !proto.commonpb.File>}
 */
const methodInfo_TinValidationService_Download = new grpc.web.AbstractClientBase.MethodInfo(
  proto_commonpb_file_pb.File,
  /**
   * @param {!proto.taxfilingpb.TinValidationDownloadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_commonpb_file_pb.File.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.TinValidationDownloadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.File)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.File>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.TinValidationServiceClient.prototype.download =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.TinValidationService/Download',
      request,
      metadata || {},
      methodDescriptor_TinValidationService_Download,
      callback);
};


/**
 * @param {!proto.taxfilingpb.TinValidationDownloadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.File>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.TinValidationServicePromiseClient.prototype.download =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.TinValidationService/Download',
      request,
      metadata || {},
      methodDescriptor_TinValidationService_Download);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.TinValidationUploadRequest,
 *   !proto.taxfilingpb.TinValidationUploadResponse>}
 */
const methodDescriptor_TinValidationService_Upload = new grpc.web.MethodDescriptor(
  '/taxfilingpb.TinValidationService/Upload',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.TinValidationUploadRequest,
  proto.taxfilingpb.TinValidationUploadResponse,
  /**
   * @param {!proto.taxfilingpb.TinValidationUploadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.TinValidationUploadResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.TinValidationUploadRequest,
 *   !proto.taxfilingpb.TinValidationUploadResponse>}
 */
const methodInfo_TinValidationService_Upload = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.TinValidationUploadResponse,
  /**
   * @param {!proto.taxfilingpb.TinValidationUploadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.TinValidationUploadResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.TinValidationUploadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.TinValidationUploadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.TinValidationUploadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.TinValidationServiceClient.prototype.upload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.TinValidationService/Upload',
      request,
      metadata || {},
      methodDescriptor_TinValidationService_Upload,
      callback);
};


/**
 * @param {!proto.taxfilingpb.TinValidationUploadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.TinValidationUploadResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.TinValidationServicePromiseClient.prototype.upload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.TinValidationService/Upload',
      request,
      metadata || {},
      methodDescriptor_TinValidationService_Upload);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.TinValidationProcessRequest,
 *   !proto.taxfilingpb.TinValidationProcessResponse>}
 */
const methodDescriptor_TinValidationService_Process = new grpc.web.MethodDescriptor(
  '/taxfilingpb.TinValidationService/Process',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.TinValidationProcessRequest,
  proto.taxfilingpb.TinValidationProcessResponse,
  /**
   * @param {!proto.taxfilingpb.TinValidationProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.TinValidationProcessResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.TinValidationProcessRequest,
 *   !proto.taxfilingpb.TinValidationProcessResponse>}
 */
const methodInfo_TinValidationService_Process = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.TinValidationProcessResponse,
  /**
   * @param {!proto.taxfilingpb.TinValidationProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.TinValidationProcessResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.TinValidationProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.TinValidationProcessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.TinValidationProcessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.TinValidationServiceClient.prototype.process =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.TinValidationService/Process',
      request,
      metadata || {},
      methodDescriptor_TinValidationService_Process,
      callback);
};


/**
 * @param {!proto.taxfilingpb.TinValidationProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.TinValidationProcessResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.TinValidationServicePromiseClient.prototype.process =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.TinValidationService/Process',
      request,
      metadata || {},
      methodDescriptor_TinValidationService_Process);
};


module.exports = proto.taxfilingpb;

