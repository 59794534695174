// source: proto/processpb/process.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_type_date_pb = require('../../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.processpb.ListProcess', null, global);
goog.exportSymbol('proto.processpb.ListProcessRequest', null, global);
goog.exportSymbol('proto.processpb.ListProcessResponse', null, global);
goog.exportSymbol('proto.processpb.ProcessDivnraRequest', null, global);
goog.exportSymbol('proto.processpb.ProcessRequest', null, global);
goog.exportSymbol('proto.processpb.ProcessResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.processpb.ListProcessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.processpb.ListProcessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.processpb.ListProcessRequest.displayName =
    'proto.processpb.ListProcessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.processpb.ListProcess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.processpb.ListProcess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.processpb.ListProcess.displayName = 'proto.processpb.ListProcess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.processpb.ListProcessResponse = function(opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.processpb.ListProcessResponse.repeatedFields_,
    null
  );
};
goog.inherits(proto.processpb.ListProcessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.processpb.ListProcessResponse.displayName =
    'proto.processpb.ListProcessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.processpb.ProcessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.processpb.ProcessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.processpb.ProcessRequest.displayName = 'proto.processpb.ProcessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.processpb.ProcessDivnraRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.processpb.ProcessDivnraRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.processpb.ProcessDivnraRequest.displayName =
    'proto.processpb.ProcessDivnraRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.processpb.ProcessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.processpb.ProcessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.processpb.ProcessResponse.displayName =
    'proto.processpb.ProcessResponse';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.processpb.ListProcessRequest.prototype.toObject = function(
    opt_includeInstance
  ) {
    return proto.processpb.ListProcessRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.processpb.ListProcessRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.processpb.ListProcessRequest.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        year: jspb.Message.getFieldWithDefault(msg, 1, ''),
        correspondent: jspb.Message.getFieldWithDefault(msg, 2, ''),
        office: jspb.Message.getFieldWithDefault(msg, 3, ''),
        accountNo: jspb.Message.getFieldWithDefault(msg, 4, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.processpb.ListProcessRequest}
 */
proto.processpb.ListProcessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.processpb.ListProcessRequest();
  return proto.processpb.ListProcessRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.processpb.ListProcessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.processpb.ListProcessRequest}
 */
proto.processpb.ListProcessRequest.deserializeBinaryFromReader = function(
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setYear(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCorrespondent(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setOffice(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountNo(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.processpb.ListProcessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.processpb.ListProcessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.processpb.ListProcessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.processpb.ListProcessRequest.serializeBinaryToWriter = function(
  message,
  writer
) {
  var f = undefined;
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getOffice();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
};

/**
 * optional string year = 1;
 * @return {string}
 */
proto.processpb.ListProcessRequest.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.processpb.ListProcessRequest} returns this
 */
proto.processpb.ListProcessRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.processpb.ListProcessRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.processpb.ListProcessRequest} returns this
 */
proto.processpb.ListProcessRequest.prototype.setCorrespondent = function(
  value
) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string office = 3;
 * @return {string}
 */
proto.processpb.ListProcessRequest.prototype.getOffice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.processpb.ListProcessRequest} returns this
 */
proto.processpb.ListProcessRequest.prototype.setOffice = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.processpb.ListProcessRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.processpb.ListProcessRequest} returns this
 */
proto.processpb.ListProcessRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.processpb.ListProcess.prototype.toObject = function(
    opt_includeInstance
  ) {
    return proto.processpb.ListProcess.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.processpb.ListProcess} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.processpb.ListProcess.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        year: jspb.Message.getFieldWithDefault(msg, 2, ''),
        correspondent: jspb.Message.getFieldWithDefault(msg, 3, ''),
        office: jspb.Message.getFieldWithDefault(msg, 4, ''),
        accountNo: jspb.Message.getFieldWithDefault(msg, 5, ''),
        form1042dinvra: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
        form1099b: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
        form1099div: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
        form1099int: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
        form1099misc: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.processpb.ListProcess}
 */
proto.processpb.ListProcess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.processpb.ListProcess();
  return proto.processpb.ListProcess.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.processpb.ListProcess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.processpb.ListProcess}
 */
proto.processpb.ListProcess.deserializeBinaryFromReader = function(
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setAccountId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setYear(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setCorrespondent(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setOffice(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountNo(value);
        break;
      case 6:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setForm1042dinvra(value);
        break;
      case 7:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setForm1099b(value);
        break;
      case 8:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setForm1099div(value);
        break;
      case 9:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setForm1099int(value);
        break;
      case 10:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setForm1099misc(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.processpb.ListProcess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.processpb.ListProcess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.processpb.ListProcess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.processpb.ListProcess.serializeBinaryToWriter = function(
  message,
  writer
) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(1, f);
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getOffice();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getForm1042dinvra();
  if (f) {
    writer.writeBool(6, f);
  }
  f = message.getForm1099b();
  if (f) {
    writer.writeBool(7, f);
  }
  f = message.getForm1099div();
  if (f) {
    writer.writeBool(8, f);
  }
  f = message.getForm1099int();
  if (f) {
    writer.writeBool(9, f);
  }
  f = message.getForm1099misc();
  if (f) {
    writer.writeBool(10, f);
  }
};

/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.processpb.ListProcess.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.processpb.ListProcess} returns this
 */
proto.processpb.ListProcess.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string year = 2;
 * @return {string}
 */
proto.processpb.ListProcess.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.processpb.ListProcess} returns this
 */
proto.processpb.ListProcess.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string correspondent = 3;
 * @return {string}
 */
proto.processpb.ListProcess.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.processpb.ListProcess} returns this
 */
proto.processpb.ListProcess.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string office = 4;
 * @return {string}
 */
proto.processpb.ListProcess.prototype.getOffice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.processpb.ListProcess} returns this
 */
proto.processpb.ListProcess.prototype.setOffice = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string account_no = 5;
 * @return {string}
 */
proto.processpb.ListProcess.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.processpb.ListProcess} returns this
 */
proto.processpb.ListProcess.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional bool form1042dinvra = 6;
 * @return {boolean}
 */
proto.processpb.ListProcess.prototype.getForm1042dinvra = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    6,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.processpb.ListProcess} returns this
 */
proto.processpb.ListProcess.prototype.setForm1042dinvra = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};

/**
 * optional bool form1099b = 7;
 * @return {boolean}
 */
proto.processpb.ListProcess.prototype.getForm1099b = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    7,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.processpb.ListProcess} returns this
 */
proto.processpb.ListProcess.prototype.setForm1099b = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};

/**
 * optional bool form1099div = 8;
 * @return {boolean}
 */
proto.processpb.ListProcess.prototype.getForm1099div = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    8,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.processpb.ListProcess} returns this
 */
proto.processpb.ListProcess.prototype.setForm1099div = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};

/**
 * optional bool form1099int = 9;
 * @return {boolean}
 */
proto.processpb.ListProcess.prototype.getForm1099int = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    9,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.processpb.ListProcess} returns this
 */
proto.processpb.ListProcess.prototype.setForm1099int = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};

/**
 * optional bool form1099misc = 10;
 * @return {boolean}
 */
proto.processpb.ListProcess.prototype.getForm1099misc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    10,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.processpb.ListProcess} returns this
 */
proto.processpb.ListProcess.prototype.setForm1099misc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.processpb.ListProcessResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.processpb.ListProcessResponse.prototype.toObject = function(
    opt_includeInstance
  ) {
    return proto.processpb.ListProcessResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.processpb.ListProcessResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.processpb.ListProcessResponse.toObject = function(
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        listProcessList: jspb.Message.toObjectList(
          msg.getListProcessList(),
          proto.processpb.ListProcess.toObject,
          includeInstance
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.processpb.ListProcessResponse}
 */
proto.processpb.ListProcessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.processpb.ListProcessResponse();
  return proto.processpb.ListProcessResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.processpb.ListProcessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.processpb.ListProcessResponse}
 */
proto.processpb.ListProcessResponse.deserializeBinaryFromReader = function(
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.processpb.ListProcess();
        reader.readMessage(
          value,
          proto.processpb.ListProcess.deserializeBinaryFromReader
        );
        msg.addListProcess(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.processpb.ListProcessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.processpb.ListProcessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.processpb.ListProcessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.processpb.ListProcessResponse.serializeBinaryToWriter = function(
  message,
  writer
) {
  var f = undefined;
  f = message.getListProcessList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.processpb.ListProcess.serializeBinaryToWriter
    );
  }
};

/**
 * repeated ListProcess list_process = 1;
 * @return {!Array<!proto.processpb.ListProcess>}
 */
proto.processpb.ListProcessResponse.prototype.getListProcessList = function() {
  return /** @type{!Array<!proto.processpb.ListProcess>} */ (jspb.Message.getRepeatedWrapperField(
    this,
    proto.processpb.ListProcess,
    1
  ));
};

/**
 * @param {!Array<!proto.processpb.ListProcess>} value
 * @return {!proto.processpb.ListProcessResponse} returns this
 */
proto.processpb.ListProcessResponse.prototype.setListProcessList = function(
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.processpb.ListProcess=} opt_value
 * @param {number=} opt_index
 * @return {!proto.processpb.ListProcess}
 */
proto.processpb.ListProcessResponse.prototype.addListProcess = function(
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.processpb.ListProcess,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.processpb.ListProcessResponse} returns this
 */
proto.processpb.ListProcessResponse.prototype.clearListProcessList = function() {
  return this.setListProcessList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.processpb.ProcessRequest.prototype.toObject = function(
    opt_includeInstance
  ) {
    return proto.processpb.ProcessRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.processpb.ProcessRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.processpb.ProcessRequest.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        year: jspb.Message.getFieldWithDefault(msg, 2, ''),
        type: jspb.Message.getFieldWithDefault(msg, 3, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.processpb.ProcessRequest}
 */
proto.processpb.ProcessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.processpb.ProcessRequest();
  return proto.processpb.ProcessRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.processpb.ProcessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.processpb.ProcessRequest}
 */
proto.processpb.ProcessRequest.deserializeBinaryFromReader = function(
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setAccountId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setYear(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.processpb.ProcessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.processpb.ProcessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.processpb.ProcessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.processpb.ProcessRequest.serializeBinaryToWriter = function(
  message,
  writer
) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(1, f);
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.processpb.ProcessRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.processpb.ProcessRequest} returns this
 */
proto.processpb.ProcessRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string year = 2;
 * @return {string}
 */
proto.processpb.ProcessRequest.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.processpb.ProcessRequest} returns this
 */
proto.processpb.ProcessRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string type = 3;
 * @return {string}
 */
proto.processpb.ProcessRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.processpb.ProcessRequest} returns this
 */
proto.processpb.ProcessRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.processpb.ProcessDivnraRequest.prototype.toObject = function(
    opt_includeInstance
  ) {
    return proto.processpb.ProcessDivnraRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.processpb.ProcessDivnraRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.processpb.ProcessDivnraRequest.toObject = function(
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        fromDate:
          (f = msg.getFromDate()) &&
          google_type_date_pb.Date.toObject(includeInstance, f),
        toDate:
          (f = msg.getToDate()) &&
          google_type_date_pb.Date.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.processpb.ProcessDivnraRequest}
 */
proto.processpb.ProcessDivnraRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.processpb.ProcessDivnraRequest();
  return proto.processpb.ProcessDivnraRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.processpb.ProcessDivnraRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.processpb.ProcessDivnraRequest}
 */
proto.processpb.ProcessDivnraRequest.deserializeBinaryFromReader = function(
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setAccountId(value);
        break;
      case 2:
        var value = new google_type_date_pb.Date();
        reader.readMessage(
          value,
          google_type_date_pb.Date.deserializeBinaryFromReader
        );
        msg.setFromDate(value);
        break;
      case 3:
        var value = new google_type_date_pb.Date();
        reader.readMessage(
          value,
          google_type_date_pb.Date.deserializeBinaryFromReader
        );
        msg.setToDate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.processpb.ProcessDivnraRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.processpb.ProcessDivnraRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.processpb.ProcessDivnraRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.processpb.ProcessDivnraRequest.serializeBinaryToWriter = function(
  message,
  writer
) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(1, f);
  }
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(2, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(3, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
};

/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.processpb.ProcessDivnraRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.processpb.ProcessDivnraRequest} returns this
 */
proto.processpb.ProcessDivnraRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional google.type.Date from_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.processpb.ProcessDivnraRequest.prototype.getFromDate = function() {
  return /** @type{?proto.google.type.Date} */ (jspb.Message.getWrapperField(
    this,
    google_type_date_pb.Date,
    2
  ));
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.processpb.ProcessDivnraRequest} returns this
 */
proto.processpb.ProcessDivnraRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.processpb.ProcessDivnraRequest} returns this
 */
proto.processpb.ProcessDivnraRequest.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.processpb.ProcessDivnraRequest.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional google.type.Date to_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.processpb.ProcessDivnraRequest.prototype.getToDate = function() {
  return /** @type{?proto.google.type.Date} */ (jspb.Message.getWrapperField(
    this,
    google_type_date_pb.Date,
    3
  ));
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.processpb.ProcessDivnraRequest} returns this
 */
proto.processpb.ProcessDivnraRequest.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.processpb.ProcessDivnraRequest} returns this
 */
proto.processpb.ProcessDivnraRequest.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.processpb.ProcessDivnraRequest.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 3) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.processpb.ProcessResponse.prototype.toObject = function(
    opt_includeInstance
  ) {
    return proto.processpb.ProcessResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.processpb.ProcessResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.processpb.ProcessResponse.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.processpb.ProcessResponse}
 */
proto.processpb.ProcessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.processpb.ProcessResponse();
  return proto.processpb.ProcessResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.processpb.ProcessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.processpb.ProcessResponse}
 */
proto.processpb.ProcessResponse.deserializeBinaryFromReader = function(
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setSuccess(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.processpb.ProcessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.processpb.ProcessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.processpb.ProcessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.processpb.ProcessResponse.serializeBinaryToWriter = function(
  message,
  writer
) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(1, f);
  }
};

/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.processpb.ProcessResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    1,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.processpb.ProcessResponse} returns this
 */
proto.processpb.ProcessResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};

goog.object.extend(exports, proto.processpb);
