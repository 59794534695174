const validateEmail = (email) => {
  if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9-]*$/i.test(email)) {
    return true;
  }
  return false;
};

const validatePassword = (password) => {
  const regex = {
    hasNumber: /\d/,
    hasWhiteSpace: /\s/,
    hasSpecialChars: /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/,
    hasLower: /[a-z]/,
    hasUpper: /[A-Z]/,
  };

  const valid = {
    err: false,
    msg: '',
  };

  if (!password) {
    return {
      err: true,
      msg: 'Required field.',
    };
  }
  if (!regex.hasLower.test(password)) {
    return {
      err: true,
      msg: 'At least one lowercase letter [a-z].',
    };
  }
  if (!regex.hasUpper.test(password)) {
    return {
      err: true,
      msg: 'At least one uppercase letter [A-Z].',
    };
  }
  if (!regex.hasSpecialChars.test(password)) {
    return {
      err: true,
      msg: 'Allowed special characters: !@#$%-_.',
    };
  }
  if (!regex.hasNumber.test(password)) {
    return {
      err: true,
      msg: 'At least one number.',
    };
  }
  if (password.length < 8) {
    return {
      err: true,
      msg: 'Minimum of 8 characters.',
    };
  }
  if (regex.hasWhiteSpace.test(password)) {
    return {
      err: true,
      msg: 'Cannot contain white space.',
    };
  }

  return valid;
};

export { validateEmail, validatePassword };
