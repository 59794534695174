/*ReactJS*/
import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';

/*Material-ui*/
import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
} from '@material-ui/core';

/*Service*/
import {
  dateProtoObjectToString,
} from '../../../services/ConvertService';

export default function BoyCostUploadEditModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (isOpen) {
      setModalData({ ...value, acquiredDate:dateProtoObjectToString(value.acquiredDate) });
     
    }
  }, [isOpen, value]);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalFull}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
             Edit Beginning of the Year
          </Typography>
          <Box mt={5}>
          <form noValidate autoComplete="off">
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="year"
                    required
                    label="Year"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.year}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="correspondent"
                    required
                    label="Correspondent"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.correspondent}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="office"
                    required
                    label="Office"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.office}
                    onChange={handleChange}
                  />
                </div>


              </div>
             <div className={classes.grdRow}>
             <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="accountNo"
                    required
                    label="Account No"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.accountNo}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                        <TextField
                         name="acquiredDate"
                         required
                         fullWidth
                         label="Acquired Date"
                         type="date"
                       value ={modalData.acquiredDate}
                         onChange={handleChange}
                         InputLabelProps={{ shrink: true }}
                     />
                  </div>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                     <TextField
                         name="symbol"
                         required
                         label="symbol"
                         fullWidth
                         InputLabelProps={{ shrink: true }}
                         value={modalData.symbol}
                         onChange={handleChange}
                         />
                  </div>
              
            </div>
                <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="qty"
                    required
                    label="Qty"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.qty}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="value"
                    required
                    label="Value"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.value}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="price"
                    required
                    label="Price"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.price}
                    onChange={handleChange}
                  />
                </div>
                </div>
                
              <div className={classes.modalFooter}>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div className={classes.grdCellNone}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => {
                      handleClose(modalData);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
