import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import PasswordRequirement from '../Misc/PasswordRequirement';
import { ReactComponent as Visibility } from '../../images/eye.svg';
import { ReactComponent as VisibilityOff } from '../../images/eye-slash.svg';


const PasswordField = (props) => {
  const useStyles = makeStyles(() => ({
    textFieldWrapper: {
      display: 'flex',
      position: 'relative',
    },
    passwordRequirementWrapper: {
      position: 'absolute',
      top: props.position === 'bottom' ? 60 : -225,
      left: 0,
      zIndex: 2,
      backgroundColor: '#181b1e',
      border: '1px solid #131518',
      borderRadius: 5,
      padding: 20,
      width: '100%',
      boxSizing: 'border-box',
      '& > div:first-child': {
        margin: 0,
        '& > label': {
          marginBottom: 10,
          display: 'block',
        },
        '& > div:nth-child(2)': {
          flexDirection: 'column',
          '& > div': {
            margin: 0,
            '& > ul': {
              marginBottom: 0,
              marginTop: 0,
            },
          },
        },
      },
    },
  }));

  const classes = useStyles();

  const [isFocus, setFocus] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const endIcon = () => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={(e) => {
            setShowPassword(!showPassword);
          }}
          onMouseDown={handleMouseDownPassword}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.textFieldWrapper}>
      {props.showPasswordRequirement && isFocus ? (
        <div className={classes.passwordRequirementWrapper}>
          <PasswordRequirement password={props.value} />
        </div>
      ) : null}
      <TextField
        style={{
          marginTop: props.mt ? 20 : 0,
          marginBottom: props.mb ? props.mb : 0,
        }}
        fullWidth={true}
        autoFocus={props.autoFocus}
        autoComplete="new-password"
        error={props.error}
        required={props.required}
        disabled={props.disabled}
        id={props.id}
        name={props.name}
        label={props.label}
        placeholder={props.placeholder}
        helperText={props.error ? props.msg : ''}
        type={showPassword ? 'text' : 'password'}
        value={props.value || ''}
        onChange={props.onChange}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: [
            'password',
            'confirmPassword',
            'confirmNewPassword',
            'passwordConfirm',
            'newPassword',
            'lastPassword',
            'oldPassword',
          ].includes(props.name)
            ? endIcon()
            : null,
        }}
        inputProps={{
          readOnly: props.readOnly ? true : false,
          maxLength: props.max,
          autoComplete: 'new-password',
        }}
      />
    </div>
  );
};
export default PasswordField;
