/*ReactJS*/
import React, { useState } from 'react';
import Form1099DivDetailsModal from './Form1099DivDetailsModal';
/*Toast Notification*/
import { notifyInfo } from 'components/Notification/Notification';
import {
  listForm1099Div,
  downloadDiv,
  generateDiv,
} from '../../../services/Form1099DivService';
/*Material UI Components*/
import { Box, TextField, IconButton, Tooltip } from '@material-ui/core';
/*Material UI Icons*/
import { Visibility as ViewIcon } from '@material-ui/icons';

import Table, { columnType } from 'components/Table/Table';

/*Styles*/
import useStyles from '../../../styles';

/*Custom components*/
import SearchButton from '../../../components/Button/Search';
import SelectCorrespondent from '../../../components/AutoComplete/SelectCorrespondent';
import SelectAccountNo from '../../../components/AutoComplete/SelectAccountNo';
import QueryParam from '../../../services/QueryParamService';
/*Moment JS*/

import DownloadProgress from 'components/Progress/DownloadProgress';

export default function Form1099BTable({ params }) {
  const classes = useStyles();
  const options = {
    customToolbarSelect: (selectedRows, a, b) => {
      return (
        <div
          className={classes.grdRow}
          style={{ margin: 0, padding: 0, marginRight: 20 }}
        >
          <div className={classes.grdCell1}>
            <Tooltip title="Download PDF" arrow>
              <div>
                <DownloadProgress
                  selected={selectedRows.data}
                  fileType="pdf"
                  rows={rows}
                  download={downloadDiv}
                  generate={generateDiv}
                  useSystemUpdateIcon={false}
                  downloadType="multiple"
                />
              </div>
            </Tooltip>
          </div>
          <div className={classes.grdCell1}>
            <Tooltip title="Download CSV" arrow>
              <div>
                <DownloadProgress
                  selected={selectedRows.data}
                  fileType="csv"
                  rows={rows}
                  download={downloadDiv}
                  generate={generateDiv}
                  useSystemUpdateIcon={true}
                  downloadType="multiple"
                />
              </div>
            </Tooltip>
          </div>
        </div>
      );
    },
  };

  const columns = [
    {
      name: '',
      type: columnType.buttons,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div
              align={'left'}
              className={classes.grdRow}
              style={{ margin: 0, padding: 0 }}
            >
              <Tooltip title="Download PDF" arrow>
                <div>
                  <DownloadProgress
                    selected={rows[dataIndex]}
                    fileType="pdf"
                    rows={rows}
                    download={downloadDiv}
                    generate={generateDiv}
                    useSystemUpdateIcon={false}
                  />
                </div>
              </Tooltip>
              <Tooltip title="Download CSV" arrow>
                <div>
                  <DownloadProgress
                    selected={rows[dataIndex]}
                    fileType="csv"
                    rows={rows}
                    download={downloadDiv}
                    generate={generateDiv}
                    useSystemUpdateIcon={true}
                  />
                </div>
              </Tooltip>
              <div align={'left'} className={classes.grdCellNone}>
                <IconButton
                  aria-label="view"
                  onClick={() => {
                    handleView(rows[dataIndex]);
                  }}
                >
                  <ViewIcon />
                </IconButton>
              </div>
            </div>
          );
        },
        setCellProps: () => ({
          style: { padding: '4px 16px' },
        }),
      },
    },
    {
      name: 'year',
      label: 'Year',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'office',
      label: 'Branch',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'account',
      label: 'Account Name',
    },
    {
      name: 'taxCountry',
      label: 'Tax Country',
    },
    {
      name: 'div',
      label: 'Dividend',
      type: columnType.amount,
      addFooter: true,
    },
    {
      name: 'taxAmount',
      label: 'Tax Amount',
      type: columnType.amount,
      addFooter: true,
      options: {
        display: false,
      },
    },
    {
      name: 'companyName',
      label: 'Company Name',
      options: {
        display: false,
      },
    },
    {
      name: 'address',
      label: 'Address',
      options: {
        display: false,
      },
    },
    {
      name: 'city',
      label: 'City',
      options: {
        display: false,
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        display: false,
      },
    },
    {
      name: 'zip',
      label: 'Zip',
      options: {
        display: false,
      },
    },
    {
      name: 'country',
      label: 'Country',
      options: {
        display: false,
      },
    },
    {
      name: 'legalEntity',
      label: 'Legal Entity',
      options: {
        display: false,
      },
    },
    {
      name: 'governmentIdType',
      label: 'Government ID Type',
      options: {
        display: false,
      },
    },
  ];

  const [rowData, setRowData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [rows, setRows] = useState([]);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      year: '',
      correspondent: '',
      office: '',
      accountNo: '',
    })
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({
      ...searchData,
      [input.name]: input.value,
    });
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      QueryParam.set(searchData);
      const data = await listForm1099Div(searchData);
      setRows(data.form1099DivList);
      notifyInfo(data.form1099DivList.length + ' search results.');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleView = async (dtRow) => {
    const req = {};
    req.year = searchData.year.length > 0 ? searchData.year : '';
    req.correspondent = dtRow.correspondent;
    req.office = dtRow.office;
    req.accountNo = dtRow.accountNo;
    req.usrId = 0;
    setRowData(req);
    setOpen(true);
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="year"
                  label="Year"
                  type="number"
                  inputProps={{
                    min: 2000,
                    max: 3000,
                  }}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={searchData.year}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectCorrespondent
                  required={false}
                  name="correspondent"
                  label="Correspondent"
                  value={searchData.correspondent}
                  onChange={handleChange}
                ></SelectCorrespondent>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="office"
                  fullWidth
                  label="Branch"
                  value={searchData.office}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 3 }}
                />
              </div>

              <div className={classes.grdCell1}>
                <SelectAccountNo
                  required={false}
                  name="accountNo"
                  label="Account No"
                  value={searchData.accountNo}
                  onChange={handleChange}
                ></SelectAccountNo>
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={handleSearch}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        <Table
          title={'Form 1099 DIV'}
          data={rows}
          columns={columns}
          options={options}
          additionalCell={true}
        />
      </Box>
      {open && (
        <Form1099DivDetailsModal
          onClose={handleClose}
          open={open}
          value={rowData}
        ></Form1099DivDetailsModal>
      )}
    </div>
  );
}
