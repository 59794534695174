/*ReactJS*/
import React, { useState } from 'react';
import AccountModal from './AccountModal';
import AccountUploadModal from './AccountUploadModal';
import CountriesSelect from '../../../components/AutoComplete/Countries';
import TaxCountriesSelect from '../../../components/AutoComplete/TaxCountries';
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';
import {
  createAccount,
  updateAccount,
  deleteAccount,
  listAccount,
} from '../../../services/AccountService';
import {
  Button,
  Box,
  TextField,
  IconButton,
  InputLabel,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Create as EditIcon,
  Delete as DeleteIcon,
  AssignmentReturn as ImportIcon,
} from '@material-ui/icons';
import Table, { columnType } from 'components/Table/Table';
import useStyles from '../../../styles';
import SearchButton from '../../../components/Button/Search';
import SelectCorrespondent from '../../../components/AutoComplete/SelectCorrespondent';
import SelectAccountNo from '../../../components/AutoComplete/SelectAccountNo';
import SelectAccountName from '../../../components/AutoComplete/SelectAccountName';
import QueryParam from '../../../services/QueryParamService';
import { useConfirm } from 'material-ui-confirm';
import SelectSystemCode from 'components/AutoComplete/SelectSystemCode';

export default function AccountTable() {
  const year = new Date().getFullYear();
  const prevYear = year - 1;

  const classes = useStyles();
  const confirm = useConfirm();
  const options = {
    isRowSelectable: (dataIndex) => isDeleteEnalbed(dataIndex),
    onRowsDelete: (selectedItems) => {
      handleDelete(selectedItems.data);
      return false;
    },
  };

  const columns = [
    {
      name: '',
      type: columnType.buttons,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div
              align={'left'}
              className={classes.grdRow}
              style={{ margin: 0, padding: 0 }}
            >
              <div className={classes.grdCellNone}>
                <IconButton
                  aria-label="delete"
                  //disabled={!isDeleteEnabled(dataIndex)}
                  onClick={() => {
                    handleDelete([
                      {
                        dataIndex: dataIndex,
                      },
                    ]);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              <div align={'left'} className={classes.grdCellNone}>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleOpen(rows[dataIndex])}
                >
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'year',
      label: 'Year',
    },
    {
      name: 'externalId',
      label: 'External ID',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'office',
      label: 'Branch',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'country',
      label: 'Country',
    },
    {
      name: 'w8w9',
      label: 'W8/W9',
    },
    {
      name: 'taxCountry',
      label: 'Tax Country',
    },
    {
      name: 'legalEntity',
      label: 'Legal Entity',
    },
    {
      name: 'governmentIdType',
      label: 'Government ID Type',
    },
    {
      name: 'fullName',
      label: 'Full Name',
      options: {
        display: false,
      },
    },
    {
      name: 'address1',
      label: 'Address 1',
      options: {
        display: false,
      },
    },
    {
      name: 'address2',
      label: 'Address 2',
      options: {
        display: false,
      },
    },
    {
      name: 'address3',
      label: 'Address 3',
      options: {
        display: false,
      },
    },
    {
      name: 'zip',
      label: 'Zip',
      options: {
        display: false,
      },
    },
    {
      name: 'city',
      label: 'City',
      options: {
        display: false,
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        display: false,
      },
    },
    {
      name: 'governmentId',
      label: 'Government ID',
      options: {
        display: false,
      },
    },
    {
      name: 'companyName',
      label: 'Company Name',
      options: {
        display: false,
      },
    },
    {
      name: 'calcType',
      label: '1099b Calc Type',
      options: {
        display: false,
      },
    },
  ];

  const [rowData, setRowData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [rows, setRows] = useState([]);
  const [openImport, setOpenImport] = React.useState(false);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      year: '',
      correspondent: '',
      office: '',
      accountNo: '',
      accountName: '',
      externalId: '',
      w8W9: '',
      legalEntity: '',
      governmentIdType: '',
      governmentId: '',
      zip: '',
      city: '',
      state: '',
      country: '',
      taxCountry: '',
    })
  );

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({
      ...searchData,
      [input.name]: input.value,
    });
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      QueryParam.set(searchData);
      const data = await listAccount(searchData);

      setRows(data.accountsList);
      notifyInfo(data.accountsList.length + ' search results.');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (rowsToDelete) => {
    let messageKey = '';
    if (rowsToDelete.length === 1) {
      messageKey = rows[rowsToDelete[0].dataIndex].correspondent;
    } else {
      messageKey = rowsToDelete.length + ' items';
    }

    let idsToDelete = [];
    confirm({
      description:
        "You are about to delete '" +
        messageKey +
        "'. Please confirm your action.",
      confirmationText: 'Yes, delete',
    }).then(async () => {
      const rowsCopy = [...rows];
      try {
        for (const r of rowsToDelete) {
          let index = r.dataIndex;
          await deleteAccount(rows[index].accountId);
          idsToDelete.push(rows[index].accountId);
        }
      } catch (error) {
        console.error(error);
      } finally {
        const filtered = rowsCopy.filter(
          (r) => !idsToDelete.includes(r.accountId)
        );
        setRows(filtered);
        notifySuccess(messageKey + ' Account has been deleted');
      }
    });
  };

  const handleOpen = (data) => {
    if (!data) {
      //ADD default values
      data = {
        correspondent: '',
        office: '',
        accountNo: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        state: '',
        country: '',
        taxCountry: '',
        governmentId: '',
        legalEntity: '',
        year: prevYear.toString(),
        externalId: '',
        w8W9: '',
        accountName: '',
        companyName: '',
        fullName: '',
        governmentIdType: '',
        zip: '',
        calcType: '',
      };
    }
    setRowData(data);
    setOpen(true);
  };

  const handleClose = async (data, isEdit) => {
    if (!data) {
      setOpen(false);
      return;
    }

    let valid = true;

    if (!data.externalId) {
      notifyError('External ID is required.');
      valid = false;
    }

    if (!data.correspondent) {
      notifyError('Correspondent is required.');
      valid = false;
    }

    if (!data.office) {
      notifyError('Office is required.');
      valid = false;
    }

    if (!data.accountNo) {
      notifyError('Account No is required.');
      valid = false;
    }

    if (!data.fullName) {
      notifyError('Full Name is required.');
      valid = false;
    }

    if (!data.companyName) {
      notifyError('Company Name is required.');
      valid = false;
    }

    if (!data.w8w9) {
      notifyError('W8/W9 is required.');
      valid = false;
    }

    if (!data.legalEntity) {
      notifyError('Legal Entity is required.');
      valid = false;
    }

    if (!data.address1) {
      notifyError('Address is required.');
      valid = false;
    }

    if (!data.state) {
      notifyError('State is required.');
      valid = false;
    }

    if (!data.city) {
      notifyError('City is required.');
      valid = false;
    }

    if (!data.zip) {
      notifyError('Zip is required.');
      valid = false;
    }

    if (!data.country) {
      notifyError('Country is required.');
      valid = false;
    }

    if (!data.taxCountry) {
      notifyError('Tax Country is required.');
      valid = false;
    }

    if (!valid) {
      return;
    }

    try {
      if (isEdit) {
        //EDIT
        const resp = await updateAccount(data);
        const rowsCopy = [...rows];
        const index = rows.indexOf(rowData);
        rowsCopy[index] = resp.account;
        setRows(rowsCopy);
        notifySuccess('Account has been updated.');
      } else {
        //ADD
        const resp = await createAccount(data);
        setRows([resp.account, ...rows]);
        notifySuccess('New Account has been added.');
      }

      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUploadClose = async () => {
    setOpenImport(false);
  };

  const isDeleteEnalbed = (dataIndex) => {
    return rows[dataIndex].status !== 'disabled';
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="year"
                  label="Year"
                  type="number"
                  inputProps={{
                    min: 2000,
                    max: 3000,
                  }}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={searchData.year}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectCorrespondent
                  required={false}
                  name="correspondent"
                  label="Correspondent"
                  value={searchData.correspondent}
                  onChange={handleChange}
                ></SelectCorrespondent>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="office"
                  fullWidth
                  label="Branch"
                  inputProps={{
                    maxLength: 3,
                  }}
                  value={searchData.office}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectAccountNo
                  required={false}
                  name="accountNo"
                  label="Account No"
                  value={searchData.accountNo}
                  onChange={handleChange}
                ></SelectAccountNo>
              </div>
              <div className={classes.grdCell1}>
                <SelectAccountName
                  required={false}
                  name="accountName"
                  label="Account Name"
                  value={searchData.accountName}
                  onChange={handleChange}
                ></SelectAccountName>
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="externalId"
                  fullWidth
                  label="External ID"
                  inputProps={{
                    maxLength: 50,
                  }}
                  value={searchData.externalId}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="w8W9"
                  fullWidth
                  label="W8/W9"
                  value={searchData.w8W9}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel id="legalEntity" shrink>
                  Legal Entity
                </InputLabel>
                <SelectSystemCode
                  type="Legal Entity"
                  labelid="legalEntity"
                  fullWidth
                  required={false}
                  name="legalEntity"
                  value={searchData.legalEntity}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="governmentIdType"
                  fullWidth
                  label="Government ID Type"
                  value={searchData.governmentIdType}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  name="governmentId"
                  fullWidth
                  label="Government ID"
                  value={searchData.governmentId}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="zip"
                  fullWidth
                  label="Zip"
                  value={searchData.zip}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="city"
                  fullWidth
                  label="City"
                  value={searchData.city}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="state"
                  fullWidth
                  label="State"
                  value={searchData.state}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel id="country" shrink>
                  Country
                </InputLabel>
                <SelectSystemCode
                  type="Country"
                  labelid="country"
                  fullWidth
                  required={false}
                  name="country"
                  value={searchData.country}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.grdCell1}>
                <InputLabel id="taxCountry" shrink>
                  Tax Country
                </InputLabel>
                <SelectSystemCode
                  type="Tax Country"
                  labelid="taxCountry"
                  fullWidth
                  required={false}
                  name="taxCountry"
                  value={searchData.taxCountry}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen();
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={handleSearch}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          <div className={classes.grdCellNone} style={{ marginLeft: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<ImportIcon />}
              onClick={() => {
                setOpenImport(true);
              }}
            >
              Import
            </Button>
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        <Table
          title={'Account'}
          data={rows}
          columns={columns}
          options={options}
          additionalCell={true}
        />
      </Box>
      {open && (
        <AccountModal
          onClose={handleClose}
          open={open}
          value={rowData}
        ></AccountModal>
      )}
      {openImport && (
        <AccountUploadModal
          onClose={handleUploadClose}
          open={openImport}
          value={rowData}
        ></AccountUploadModal>
      )}
    </div>
  );
}
