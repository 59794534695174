/*eslint eqeqeq: "off"*/
/*ReactJS*/
import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';

/*components*/
import BoyCostUploadEditModal from './BoyCostUploadEditModal';
// import * as XLSX from "xlsx";
import {
  notifySuccess,
  notifyInfo,
} from 'components/Notification/Notification';
import Table, { columnType } from 'components/Table/Table';

/*Service*/

import {
  dateProtoObjectToString,
  dateStringToDateSTD,
} from '../../../services/ConvertService';
import {
  createBoyCost,
  isValidData,
  convertDataToBoyCostModel,
  importBoyCost,
} from '../../../services/BoyCostService';
import { readExcelData } from '../../../services/ExcelService';

/*Material UI*/
import { useConfirm } from 'material-ui-confirm';
import {
  Create as EditIcon,
  Delete as DeleteIcon,
  AttachFile,
} from '@material-ui/icons';
import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  IconButton,
} from '@material-ui/core';

export default function BoyCostUploadModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const confirm = useConfirm();
  const options = {
    selectableRows: 'none',
  };

  const classes = useStyles();
 

  const [excelValidationMessage, setExcelValidationMessage] = React.useState(
    ''
  );
  const [rowData, setRowData] = React.useState({});
  const [editRecord, setEditRecord] = React.useState(false);
  const [fileName, setFileName] = React.useState('');
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (isOpen) {
     
      if (value.trnsId) {

      }
    }
  }, [isOpen, value]);

  const readExcel = (file) => {
    if (!file) {
      return;
    }
    setRows([]);
    setExcelValidationMessage('');
    setFileName(file.name);
    readExcelData(file).then((d) => {
      let rowsCopy = [];
      let allMessage = '';
      let isExcelDataValid = true;
      d.map((item) => {
        let validateString = isValidData(item);
        if (validateString == '') {
          rowsCopy.push(convertDataToBoyCostModel(item));
        } else {
          isExcelDataValid = false;
          let message =
            '|row:' + (item.__rowNum__ + 1) + ': ' + validateString + '\n';
          allMessage += message;
        }
        return null;
      });
      setExcelValidationMessage(allMessage);
      if (isExcelDataValid == false) {
        notifyInfo('error parsing excel data');
      } else {
        notifySuccess('successfully load(s) ' + d.length);
       console.log(rowsCopy);
       setRows(rowsCopy);
      }
    });
   
  };

  const handUpload = async () => {
    const records = rows.length;
    if (records > 0) {
      await Promise.all(rows.map(saveBoyCost));
      notifySuccess(records + ' Record(s) successfully uploaded.');
      const rowsCopy = [...rows];
      rowsCopy.splice(0, rowsCopy.length - 1);
      setRows([]);
    }
  };

  const saveBoyCost = async (data) => {
    data.acquiredDate = dateProtoObjectToString(data.acquiredDate);
   await importBoyCost(data);
    data.tradDate = dateStringToDateSTD(data.acquiredDate);
  };

  const handleDelete = (rowsToDelete) => {
    let messageKey = '';
    if (rowsToDelete.length === 1) {
      messageKey = rows[rowsToDelete[0].dataIndex].correspondent;
    } else {
      messageKey = rowsToDelete.length + ' items';
    }

    confirm({
      description:
        "You are about to delete '" +
        messageKey +
        "'. Please confirm your action.",
      confirmationText: 'Yes',
    }).then(async () => {
      const rowsCopy = [...rows];
      try {
        for (const r of rowsToDelete) {
          let index = r.dataIndex;
          rowsCopy.splice(index, 1);
        }

        setRows(rowsCopy);
        notifySuccess(messageKey + 'Beginning of the Year has been deleted');
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleOpen = (data) => {
    setRowData(data);
    setEditRecord(true);
  };

  const handleEditClose = async (data) => {
    try {
      const rowsCopy = [...rows];
      const index = rows.indexOf(rowData);
      data.acquiredDate = dateStringToDateSTD(data.acquiredDate);
      rowsCopy[index] = data;
      setRows(rowsCopy);
      notifySuccess('Record has been updated.');
    } catch (error) {
      console.error(error);
    }
    setEditRecord(false);
  };

  /*=========================================================================================
  Table modifications
  ===========================================================================================*/
  const columns = [
    {
      name: '',
      type: columnType.buttons,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div
              align={'left'}
              className={classes.grdRow}
              style={{ margin: 0, padding: 0 }}
            >
              <div className={classes.grdCellNone}>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    handleDelete([{ dataIndex: dataIndex }]);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              <div align={'left'} className={classes.grdCellNone}>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleOpen(rows[dataIndex])}
                >
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'year',
      label: 'Year',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'office',
      label: 'Office',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'acquiredDate',
      label: 'Acquired Date',
      type: columnType.date,
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'qty',
      label: 'Qty',
      type: columnType.quantity,
    },
    {
      name: 'value',
      label: 'Value',
      type: columnType.amount,
    },
    {
      name: 'price',
      label: 'Price',
      type: columnType.amount,
    },
  ];

  return (
    <div className={classes.root}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={isOpen}>
          <div className={classes.fadeModalFull}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              Upload Beginning of the Year
            </Typography>
            <Box mt={5}>
              <div className={classes.root}>
                <Box component="div" mt={5}>
                  <div className={classes.actionContainer}>
                    <div
                      className={classes.grdCellNone}
                      style={{ marginRight: 10 }}
                    >
                      <input
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain"
                        className={classes.input}
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          readExcel(file);
                        }}
                      />
                      <label htmlFor="raised-button-file">
                        <Button
                          component="span"
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<AttachFile />}
                        >
                          Choose File
                        </Button>
                      </label>
                    </div>
                  </div>
                  {excelValidationMessage != '' && (
                    <Box component="div" mt={2}>
                      <Typography variant="h6" align="left" gutterBottom={true}>
                        Error parsing {fileName}
                      </Typography>
                      <span color="error">{excelValidationMessage}</span>
                    </Box>
                  )}
                </Box>
                <Box component="div" mt={2}>
                  <Table
                    title={'Beginning of the Year'}
                    data={rows}
                    columns={columns}
                    options={options}
                  />
                </Box>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={() => {
                        handUpload();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </Fade>
      </Modal>
      {editRecord && (
        <BoyCostUploadEditModal
          onClose={handleEditClose}
          open={editRecord}
          value={rowData}
        ></BoyCostUploadEditModal>
      )}
    </div>
  );
}
