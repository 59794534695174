import React, { useEffect } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import {
  Close as CloseIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@material-ui/icons';

// styles
import useStyles from './styles';
import useStyles2 from '../../styles';

/*Service*/
import {
  UserGuide,
  UserGuideServiceClient,
} from '../../proto/admpb/userguide_grpc_web_pb';

import {
  Typography,
  Button,
  Modal,
  Fade,
  Backdrop,
  Box,
} from '@material-ui/core';

/*Toast Notification*/
import { notifyError } from 'components/Notification/Notification';

// components
import Header from '../Header';
import Sidebar from '../Sidebar';

// pages
import Dashboard from 'pages/dashboard';
// import Typography from 'pages/typography';
import Notifications from 'pages/notifications';
import Maps from 'pages/maps';
import Tables from 'pages/tables';
import Icons from 'pages/icons';
import Charts from 'pages/charts';

//new pages
import Home from 'pages/Home/Home';
import Administrator from 'pages/Administrator/Administrator';
import Account from 'pages/Account/Account';
import UserGuidePage from 'pages/UserGuide/UserGuide';
import BoyCost from 'pages/BoyCost/BoyCost';
import Trns from 'pages/Trns/Trns';
import Form1042Divnra from 'pages/Form1042Divnra/Form1042Divnra';
import Form1099B from 'pages/Form1099B/Form1099B';
import Form1099Int from 'pages/Form1099Int/Form1099Int';
import Form1099Misc from 'pages/Form1099Misc/Form1099Misc';
import Form1099Div from 'pages/Form1099Div/Form1099Div';
import TaxTreaty from 'pages/TaxTreaty/TaxTreaty';
import TinValidation from 'pages/TinValidation/TinValidation';
import Form1099DivReclass from 'pages/Form1099DivReclass/Form1099DivReclass';
import Process from 'pages/Process/Process';
import Profile from 'pages/Profile/Profile';
// context
import { useLayoutState } from 'context/LayoutContext';
import { ToastContainer } from 'react-toastify';

import {
  logout,
  validateSession,
  getCurrentUser,
} from '../../services/AuthService';

import { auth } from '../../lib/auth/Auth';

/*=========================================================================================
Postgres connection
===========================================================================================*/
const userGuide = new UserGuideServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);
/*=========================================================================================
End of Postgres connection
===========================================================================================*/

function Layout(props) {
  var classes = useStyles();
  var classes2 = useStyles2();

  // global
  var layoutState = useLayoutState();

  const { UserId, UserName } = getCurrentUser();
  const accessToken = localStorage.getItem('access_token');

  const validateSessionParams = {
    usrId: UserId,
    email: UserName,
    accessToken: accessToken,
  };

  const MINUTE_MS = 10000;

  //user guide
  const currentPage = props.location.pathname.split('/')[3];
  const [openModal, setOpenModal] = React.useState(false);
  const [pageName, setPageName] = React.useState('');
  const [selectedUserGuide, setSelectedUserGuide] = React.useState('');
  const [pagePath, setPagePath] = React.useState('');

  const handleValidateSession = async () => {
    try {
      await validateSession(validateSessionParams);
    } catch (error) {
      if (error.message === 'App not found') {
        logout();
        window.location.replace(window.env.COMMON_LOGIN_URL + '/logout');
      }
    }
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (window.env.ACTIVATE_SINGLE_SIGNIN) {
        handleValidateSession();
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setSelectedUserGuide('');

    const transformCapitalize = (words) => {
      var separateWord = words.toLowerCase().split(' ');
      for (var i = 0; i < separateWord.length; i++) {
        separateWord[i] =
          separateWord[i].charAt(0).toUpperCase() +
          separateWord[i].substring(1);
      }
      return separateWord
        .join(' ')
        .replace(/Reclass/g, 'Div Reclass')
        .replace(/Boy Cost/gi, 'Beginning of the Year')
        .replace(/trns/gi, 'TRNS');
    };

    setPageName(
      transformCapitalize(
        props.location.pathname
          .split('/')
          .pop()
          .replace(/-/g, ' ')
      )
    );
    setPagePath(props.location.pathname.split('/').pop());
  }, [setPageName, props]);

  const handleOpen = () => {
    setTimeout(function() {
      let list = new UserGuide();
      list.setPagePath(pagePath);
      userGuide.listUserGuide(list, {}, (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err.message);
          return;
        }
        let data = res.toObject().userGuidesList;
        setSelectedUserGuide(
          data.length > 0
            ? data[0].content
            : 'Sorry, no user guide is created for this page.'
        );
      });
    }, 500);

    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div className={classes.root}>
      <Header user={props.user} history={props.history} />
      <Sidebar />
      <div
        className={classnames(classes.content, {
          [classes.contentShift]: layoutState.isSidebarOpened,
        })}
      >
        <div className={classes.fakeToolbar} />
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={true}
            pauseOnFocusLoss
            pauseOnHover
            className={classes.toastsContainer}
            closeButton={
              <CloseIcon className={classes.notificationCloseButton} />
            }
            toastClassName={classes.notification}
            progressClassName={classes.notificationProgress}
          />
          {currentPage !== 'user-guide' ? (
            <Button className={classes.userGuideButton} onClick={handleOpen}>
              <span className={classes.pulse}></span>
              <InfoOutlinedIcon style={{ fontSize: 22 }} />
            </Button>
          ) : null}
          <Switch>
            <Route path="/app/home" component={Home} />
            <Route
              path="/app/system-profile/user-guide/:search?"
              render={(props) => (
                <UserGuidePage
                  truepath="/app/system-profile/user-guide"
                  {...props}
                />
              )}
            />
            <Route
              path="/app/user-management/administrator/:search?"
              render={(props) => (
                <Administrator
                  truepath="/app/user-management/administrator"
                  {...props}
                />
              )}
            />

            <Route
              path="/app/account-setup/account/:search?"
              render={(props) => (
                <Account truepath="/app/account-setup/account" {...props} />
              )}
            />

            <Route
              path="/app/account-setup/tin-validation/:search?"
              render={(props) => (
                <TinValidation
                  truepath="/app/account-setup/tin-validation"
                  {...props}
                />
              )}
            />

            <Route
              path="/app/transaction/boy-cost/:search?"
              render={(props) => (
                <BoyCost truepath="/app/transaction/boy-cost" {...props} />
              )}
            />

            <Route
              path="/app/tax-filing/form-1042-divnra/:search?"
              render={(props) => (
                <Form1042Divnra
                  truepath="/app/tax-filing/form-1042-divnra"
                  {...props}
                />
              )}
            />

            <Route
              path="/app/tax-filing/form-1099-b/:search?"
              render={(props) => (
                <Form1099B truepath="/app/tax-filing/form-1099-b" {...props} />
              )}
            />

            <Route
              path="/app/tax-filing/form-1099-int/:search?"
              render={(props) => (
                <Form1099Int
                  truepath="/app/tax-filing/form-1099-int"
                  {...props}
                />
              )}
            />

            <Route
              path="/app/tax-filing/form-1099-div/:search?"
              render={(props) => (
                <Form1099Div
                  truepath="/app/tax-filing/form-1099-div"
                  {...props}
                />
              )}
            />

            <Route
              path="/app/tax-filing/form-1099-reclass/:search?"
              render={(props) => (
                <Form1099DivReclass
                  truepath="/app/tax-filing/form-1099-reclass"
                  {...props}
                />
              )}
            />

            <Route
              path="/app/tax-filing/form-1099-misc/:search?"
              render={(props) => (
                <Form1099Misc
                  truepath="/app/tax-filing/form-1099-misc"
                  {...props}
                />
              )}
            />

            <Route
              path="/app/account-setup/tax-treaty/:search?"
              render={(props) => (
                <TaxTreaty
                  truepath="/app/account-setup/tax-treaty"
                  {...props}
                />
              )}
            />

            <Route
              path="/app/transaction/trns/:search?"
              render={(props) => (
                <Trns truepath="/app/transaction/trns" {...props} />
              )}
            />

            <Route
              path="/app/transaction/process/:search?"
              render={(props) => (
                <Process truepath="/app/transaction/process" {...props} />
              )}
            />

            <Route
              path="/app/system-profile/profile/:search?"
              render={(props) => (
                <Profile truepath="/app/system-profile/profile" {...props} />
              )}
            />

            <Route path="/app/dashboard" component={Dashboard} />
            {/* <Route path="/app/typography" component={Typography} /> */}
            <Route path="/app/tables" component={Tables} />
            <Route path="/app/notifications" component={Notifications} />
            <Route
              exact
              path="/app/ui"
              render={() => <Redirect to="/app/ui/icons" />}
            />
            <Route path="/app/ui/maps" component={Maps} />
            <Route path="/app/ui/icons" component={Icons} />
            <Route path="/app/ui/charts" component={Charts} />
          </Switch>
        </div>
      </div>
      <Modal
        className={classes2.modalBackdrop}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes2.fadeModalFull}>
            <Typography
              id="transition-modal-title"
              className={classes2.textBold}
              variant="h4"
              gutterBottom
              style={{ textTransform: 'capitalize' }}
            >
              User Guide: {pageName}
            </Typography>
            <Box id="transition-modal-description" mt={5}>
              <div
                className={classes2.userGuidePreview}
                dangerouslySetInnerHTML={{ __html: selectedUserGuide }}
              />
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default withRouter(Layout);
