/*ReactJS*/
import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';
import CountriesSelect from '../../../components/AutoComplete/Countries';
import TaxCountriesSelect from '../../../components/AutoComplete/TaxCountries';
import CalcTypeSelect from '../../../components/AutoComplete/CalcType';
import InputMask from 'react-input-mask';

import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  InputLabel,
} from '@material-ui/core';

import { listSystemCode } from '../../../services/SystemCodeService';
import SelectSystemCode from 'components/AutoComplete/SelectSystemCode';

export default function AccountModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({});
  const [isEdit, setIsEdit] = React.useState(false);
  const [maskValue, setMaskValue] = React.useState('999999999');
  const [maskCharValue, setMaskCharValue] = React.useState('_');
  const taxIdMaskRef = React.createRef();

  useEffect(() => {
    if (isOpen) {
      setModalData({ ...value });
      if (value.accountId) {
        setIsEdit(true);
      }

      findNote2(value.legalEntity);
    }
  }, [isOpen, value]);

  const findNote2 = async (entity) => {
    var systemCodeSearchData = {
      type: 'Legal Entity',
      code: '',
      description: '',
      note: '',
    };

    const { systemCodeList } = await listSystemCode(systemCodeSearchData);

    for (var i = 0; i < systemCodeList.length; i++) {
      if (systemCodeList[i].description == entity) {
        handleTaxIdMask(systemCodeList[i].note2);
      }
    }
  };

  const handleTaxIdMask = (type) => {
    if (type === 'SSN') {
      setMaskValue('999-99-9999');
    } else if (type === 'TIN No') {
      setMaskValue('99-9999999');
    } else if (type === 'Driver License') {
      setMaskValue('*************');
    } else if (['Passport', 'International TIN'].includes(type)) {
      setMaskValue('******************************');
    }
  };

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setModalData({
      ...modalData,
      [input.name]: input.value,
    });

    // if legal entity value is changed, change the mask(format) of government ID
    if (input.name === 'legalEntity') {
      handleTaxIdMask(input.note2);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalFull}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
            {isEdit ? 'Edit' : 'Add New'} Account
          </Typography>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="year"
                    label="Year"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.year}
                    inputProps={{
                      min: 2000,
                      max: 3000,
                    }}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="externalId"
                    fullWidth
                    label="External ID"
                    value={modalData.externalId}
                    inputProps={{
                      maxLength: 50,
                    }}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="correspondent"
                    required
                    label="Correspondent"
                    inputProps={{
                      maxLength: 4,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.correspondent}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="office"
                    required
                    label="Branch"
                    inputProps={{
                      maxLength: 3,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.office}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1}>
                  <TextField
                    name="accountNo"
                    required
                    label="Account No"
                    inputProps={{
                      maxLength: 12,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.accountNo}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="fullName"
                    required
                    label="Full Name"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.fullName}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="companyName"
                    required
                    label="Company Name"
                    inputProps={{
                      maxLength: 300,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.companyName}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="w8w9"
                    required
                    label="W8/W9"
                    inputProps={{
                      maxLength: 20,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.w8w9}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <InputLabel id="legalEntity" shrink>
                    Legal Entity *
                  </InputLabel>
                  <SelectSystemCode
                    type="Legal Entity"
                    labelid="legalEntity"
                    fullWidth
                    required={false}
                    name="legalEntity"
                    value={modalData.legalEntity}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <InputMask
                    mask={maskValue}
                    maskChar={maskCharValue}
                    value={modalData.governmentId}
                    ref={taxIdMaskRef}
                    beforeMaskedValueChange={(newState, oldState) => {
                      if (newState.value !== oldState.value) {
                        handleChange({
                          currentTarget: {
                            name: 'governmentId',
                            value: newState.value,
                          },
                        });
                      }
                      if (
                        !['', ' ', '_', undefined].includes(newState.value[0])
                      ) {
                        setMaskCharValue('');
                      } else {
                        setMaskCharValue('_');
                      }
                      return {
                        value: newState.value,
                        selection: newState.selection,
                      };
                    }}
                  >
                    {() => (
                      <TextField
                        fullWidth={true}
                        id="id"
                        name="governmentId"
                        label="Government ID"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          maxLength: 20,
                        }}
                      />
                    )}
                  </InputMask>
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="address1"
                    required
                    inputProps={{
                      maxLength: 150,
                    }}
                    label="Address 1"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.address1}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="address2"
                    label="Address 2"
                    inputProps={{
                      maxLength: 150,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.address2}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="address3"
                    label="Address 3"
                    inputProps={{
                      maxLength: 150,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.address3}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="city"
                    required
                    label="City"
                    inputProps={{
                      maxLength: 35,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.city}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1}>
                  <TextField
                    required
                    name="state"
                    label="State"
                    inputProps={{
                      maxLength: 2,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.state}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="zip"
                    label="Zip"
                    inputProps={{
                      maxLength: 30,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.zip}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <InputLabel id="country" shrink>
                    Country *
                  </InputLabel>
                  <SelectSystemCode
                    type="Country"
                    labelid="country"
                    fullWidth
                    required={false}
                    name="country"
                    value={modalData.country}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <InputLabel id="taxCountry" shrink>
                    Tax Country *
                  </InputLabel>
                  <SelectSystemCode
                    type="Tax Country"
                    labelid="taxCountry"
                    fullWidth
                    required={false}
                    name="taxCountry"
                    value={modalData.taxCountry}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <InputLabel shrink>1099B Calc Type</InputLabel>
                  <SelectSystemCode
                    type="1099B Calc Type"
                    labelid="calcType"
                    fullWidth
                    required={false}
                    name="calcType"
                    value={modalData.calcType}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1}></div>
              </div>
              <div className={classes.modalFooter}>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div className={classes.grdCellNone}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => {
                      handleClose(modalData, isEdit);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
