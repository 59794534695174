/**
 * @fileoverview gRPC-Web generated client stub for taxfilingpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../../google/type/date_pb.js')

var proto_commonpb_file_pb = require('../../proto/commonpb/file_pb.js')
const proto = {};
proto.taxfilingpb = require('./form1042_divnra_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.Form1042DivnraServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.Form1042DivnraServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1042DivnraRequest,
 *   !proto.taxfilingpb.ListForm1042DivnraResponse>}
 */
const methodDescriptor_Form1042DivnraService_ListForm1042Divnra = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1042DivnraService/ListForm1042Divnra',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1042DivnraRequest,
  proto.taxfilingpb.ListForm1042DivnraResponse,
  /**
   * @param {!proto.taxfilingpb.Form1042DivnraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListForm1042DivnraResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1042DivnraRequest,
 *   !proto.taxfilingpb.ListForm1042DivnraResponse>}
 */
const methodInfo_Form1042DivnraService_ListForm1042Divnra = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.ListForm1042DivnraResponse,
  /**
   * @param {!proto.taxfilingpb.Form1042DivnraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListForm1042DivnraResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1042DivnraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.ListForm1042DivnraResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.ListForm1042DivnraResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1042DivnraServiceClient.prototype.listForm1042Divnra =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1042DivnraService/ListForm1042Divnra',
      request,
      metadata || {},
      methodDescriptor_Form1042DivnraService_ListForm1042Divnra,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1042DivnraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.ListForm1042DivnraResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1042DivnraServicePromiseClient.prototype.listForm1042Divnra =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1042DivnraService/ListForm1042Divnra',
      request,
      metadata || {},
      methodDescriptor_Form1042DivnraService_ListForm1042Divnra);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1042DivnraRequest,
 *   !proto.taxfilingpb.ListForm1042DivnraDetailsResponse>}
 */
const methodDescriptor_Form1042DivnraService_ListForm1042DivnraDetails = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1042DivnraService/ListForm1042DivnraDetails',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1042DivnraRequest,
  proto.taxfilingpb.ListForm1042DivnraDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.Form1042DivnraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListForm1042DivnraDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1042DivnraRequest,
 *   !proto.taxfilingpb.ListForm1042DivnraDetailsResponse>}
 */
const methodInfo_Form1042DivnraService_ListForm1042DivnraDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.ListForm1042DivnraDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.Form1042DivnraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListForm1042DivnraDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1042DivnraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.ListForm1042DivnraDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.ListForm1042DivnraDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1042DivnraServiceClient.prototype.listForm1042DivnraDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1042DivnraService/ListForm1042DivnraDetails',
      request,
      metadata || {},
      methodDescriptor_Form1042DivnraService_ListForm1042DivnraDetails,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1042DivnraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.ListForm1042DivnraDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1042DivnraServicePromiseClient.prototype.listForm1042DivnraDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1042DivnraService/ListForm1042DivnraDetails',
      request,
      metadata || {},
      methodDescriptor_Form1042DivnraService_ListForm1042DivnraDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1042DivnraProcessRequest,
 *   !proto.taxfilingpb.Form1042DivnraProcessResponse>}
 */
const methodDescriptor_Form1042DivnraService_Form1042DivnraProcess = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1042DivnraService/Form1042DivnraProcess',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1042DivnraProcessRequest,
  proto.taxfilingpb.Form1042DivnraProcessResponse,
  /**
   * @param {!proto.taxfilingpb.Form1042DivnraProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.Form1042DivnraProcessResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1042DivnraProcessRequest,
 *   !proto.taxfilingpb.Form1042DivnraProcessResponse>}
 */
const methodInfo_Form1042DivnraService_Form1042DivnraProcess = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.Form1042DivnraProcessResponse,
  /**
   * @param {!proto.taxfilingpb.Form1042DivnraProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.Form1042DivnraProcessResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1042DivnraProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.Form1042DivnraProcessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.Form1042DivnraProcessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1042DivnraServiceClient.prototype.form1042DivnraProcess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1042DivnraService/Form1042DivnraProcess',
      request,
      metadata || {},
      methodDescriptor_Form1042DivnraService_Form1042DivnraProcess,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1042DivnraProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.Form1042DivnraProcessResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1042DivnraServicePromiseClient.prototype.form1042DivnraProcess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1042DivnraService/Form1042DivnraProcess',
      request,
      metadata || {},
      methodDescriptor_Form1042DivnraService_Form1042DivnraProcess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.DownLoadForm1042DivnraRequest,
 *   !proto.commonpb.File>}
 */
const methodDescriptor_Form1042DivnraService_DownloadDivnra = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1042DivnraService/DownloadDivnra',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.DownLoadForm1042DivnraRequest,
  proto_commonpb_file_pb.File,
  /**
   * @param {!proto.taxfilingpb.DownLoadForm1042DivnraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_commonpb_file_pb.File.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.DownLoadForm1042DivnraRequest,
 *   !proto.commonpb.File>}
 */
const methodInfo_Form1042DivnraService_DownloadDivnra = new grpc.web.AbstractClientBase.MethodInfo(
  proto_commonpb_file_pb.File,
  /**
   * @param {!proto.taxfilingpb.DownLoadForm1042DivnraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_commonpb_file_pb.File.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.DownLoadForm1042DivnraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.File)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.File>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1042DivnraServiceClient.prototype.downloadDivnra =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1042DivnraService/DownloadDivnra',
      request,
      metadata || {},
      methodDescriptor_Form1042DivnraService_DownloadDivnra,
      callback);
};


/**
 * @param {!proto.taxfilingpb.DownLoadForm1042DivnraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.File>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1042DivnraServicePromiseClient.prototype.downloadDivnra =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1042DivnraService/DownloadDivnra',
      request,
      metadata || {},
      methodDescriptor_Form1042DivnraService_DownloadDivnra);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.DownLoadForm1042DivnraRequest,
 *   !proto.taxfilingpb.GenerateForm1042DivnraResponse>}
 */
const methodDescriptor_Form1042DivnraService_GenerateDivnra = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1042DivnraService/GenerateDivnra',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.DownLoadForm1042DivnraRequest,
  proto.taxfilingpb.GenerateForm1042DivnraResponse,
  /**
   * @param {!proto.taxfilingpb.DownLoadForm1042DivnraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.GenerateForm1042DivnraResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.DownLoadForm1042DivnraRequest,
 *   !proto.taxfilingpb.GenerateForm1042DivnraResponse>}
 */
const methodInfo_Form1042DivnraService_GenerateDivnra = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.GenerateForm1042DivnraResponse,
  /**
   * @param {!proto.taxfilingpb.DownLoadForm1042DivnraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.GenerateForm1042DivnraResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.DownLoadForm1042DivnraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.GenerateForm1042DivnraResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.GenerateForm1042DivnraResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1042DivnraServiceClient.prototype.generateDivnra =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1042DivnraService/GenerateDivnra',
      request,
      metadata || {},
      methodDescriptor_Form1042DivnraService_GenerateDivnra,
      callback);
};


/**
 * @param {!proto.taxfilingpb.DownLoadForm1042DivnraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.GenerateForm1042DivnraResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1042DivnraServicePromiseClient.prototype.generateDivnra =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1042DivnraService/GenerateDivnra',
      request,
      metadata || {},
      methodDescriptor_Form1042DivnraService_GenerateDivnra);
};


module.exports = proto.taxfilingpb;

