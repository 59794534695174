import { useEffect } from 'react';
import authSvc from '../../services/AuthService';

export default function Logout() {
  useEffect(() => {
    authSvc.logout();

    if (window.env.ACTIVATE_SINGLE_SIGNIN) {
      window.location.replace(window.env.COMMON_LOGIN_URL + '/logout');
    } else {
      window.location = '/';
    }
  }, []);

  return null;
}
